import { render, staticRenderFns } from "./RestoreUnsavedNote.vue?vue&type=template&id=09904342&scoped=true"
import script from "./RestoreUnsavedNote.vue?vue&type=script&lang=js"
export * from "./RestoreUnsavedNote.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09904342",
  null
  
)

export default component.exports