import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getExamenesByQuery(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getExamenesByQuery,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function createExamen(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createExamen,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getExamenesList(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getExamenesList,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function sendMailExamen(data){
	return new Promise((resolve, reject) => {
    request({
      url: `${endpoints.sendMailExamen}/${data.examen_id}`,
      method: 'get',
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}