<template>
    <div>
   <CModal
      title="Iniciar Video Llamada"
      color="primary"
      :show.sync="modalVideoCall"
      class="modal-sm"
      style="z-index: 9999999999999 !important; position: absolute"
      :closeOnBackdrop="false"
    >
    <div slot="header" class="w-100" style="display:flex; justify-content:space-between; align-items:center;">
        <h5>Iniciar Video Llamada</h5>
        <CButton
             @click="close()"
        >
        X
        </CButton>
      </div>
    {{user_is_in_attention ? 'El usuario seleccionado ya está en una videollamada.' : '¿Desea iniciar Video Llamada?'}}
        <div slot="footer" class="w-100" v-if="!user_is_in_attention">
        <CButton color="secondary" square size="sm" @click="close">
          Cancelar
        </CButton>
        <CButton
          class="ml-2"
          color="success"
          square
          size="sm"
          @click="videocall()"
        >
        Aceptar
        </CButton>
      </div>
      <div slot="footer" class="w-100" v-else>
        <CButton v-if="attention_id" color="secondary" square size="sm" @click="endVideocall">Cerrar Atención en Curso</CButton>
      </div>
    </CModal>
    </div>
</template>

<script>
import { endAtencion } from '../../api/atenciones'

export default {
    props: ['user_id','modalVideoCall', 'close', 'user_is_in_attention', 'attention_id'],
    data () {
        return {
        }
    },
    methods: {
        videocall() {
            this.$store.commit("setVideoCallData", {
                status: true,
                alertID: null,
                geoUserAlert: null,
                fullView: true,
                deviceID: null,
                calledUserID: this.user_id,
                socketUrl: null,
                socketRoom: null,
                videocallID: null,
                hideVideo: false,
                silent: true
            })
            this.close()
        },
        endVideocall() {
          endAtencion({atencion_id: this.attention_id}).then(res => {
            this.close()
          })
        }
    },
}
</script>

<style lang="scss" scoped>

</style>