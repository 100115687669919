//const BASE = 'http://192.168.1.173:8888/sosclick/sosclick/public/api';
//const BASE = 'http://186.64.122.120/sosclick/public/api'
//const BASE = 'http://190.98.210.42/SOSCLICKNPH/public/api'
const BASE = 'https://190.98.210.42/apisos/public/api'

const prevEndpoints = 
    {
      REGISTRAR_CAMARA:'/asociar_camara',
      DATOS_UPDATE_CAMARA:'/datos_camara',
      LOGIN:'/auth/login',
      REGISTER:'/auth/signup',
      USUARIO_INFORMACION:'/details',
      LISTADO_USUARIOS:'/listado_de_usuarios',
      ENVIAR_INVITACION:'/enviar_invitacion_new',
      MIS_INVITADOS:'/mi_lista_invitacion',
      ELIMINAR_INVITADO:'/eliminar_invitado_new',
      UPDATE_TOKEN:'/token',
      ALERTA_GRUPO:'/enviar_alerta_new',
      REVISAR_MI_GRUPO:'/revisar_mi_grupo',
      SALIR_DE_RED:'/salir_de_grupo',
      LLAMADA_INICIO:'/notificar_llamada_grupo',
      ALERTA_WEB:'/alerta_web_general',
      OBTENER_ID:'/obtener_id'
    };

const endpoints = {
  login: '/auth/loginAdmin',
  user: 'admin/user/adminUserDetails',
  createFirebaseAuthToken: 'admin/user/createFirebaseAuthToken',
  userDetails: 'admin/user/details',
  users: 'admin/user/usersList',
  adminUsers: 'admin/user/usersAdminList',
  searchUsers: 'admin/user/searchUsers',
  superAdminUsers: 'admin/user/usersSuperAdminList',
  userDelete: 'admin/user/delete',
  updateUser: 'admin/user/updateInfoById',
  updateAdminUser: 'admin/user/updateAdminById',
  getAlerts: 'admin/alerts/alertsList',
  getAlert: 'admin/alerts/alertData',
  setAlertInfo: 'admin/alerts/setAlertInfo',
  alertasActivas: 'admin/alerts/alertasActivas',
  closeAlert: 'admin/alerts/closeAlert',
  getCuadrantes: 'admin/cuadrantes/cuadrantesList',
  getCuadrante: 'admin/cuadrantes/getCuadrante',
  getPaises: 'admin/cuadrantes/getPaises',
  getComunas: 'admin/cuadrantes/getComunas',
  getRegiones: 'admin/cuadrantes/getRegiones',
  getRegionesByPais: 'admin/cuadrantes/getRegionesByPais',
  updateCuadrante: 'admin/cuadrantes/updateCuadrante',
  deleteCuadrante: 'admin/cuadrantes/deleteCuadrante',
  sendAndSavePublicidad: 'admin/publicidad/sendAndSave',
  publicidadList: 'admin/publicidad/publicidadList',
  publicidadById: 'admin/publicidad/publicidadById',
  cameraList: 'admin/cameras/list',
  newToken: 'admin/calls/newToken',
  sendSMS: 'admin/calls/sendSMS',
  getButtonData: 'admin/buttons/getButtonData',
  startMonitoreo: 'admin/user/startMonitoreoNotification',
  sendInactiveDeviceNotification: 'admin/user/sendInactiveDeviceNotification',
  deleteDispositivo: 'admin/dispositivos/deleteDispositivo',
  sendDailyCheckNotification: 'admin/user/dailyCheckNotification',
  sendNotificationMedicionIduam: 'admin/mediciones/startMedicion',
  getUserMediciones: 'admin/mediciones/getUserMediciones',
  createHolter: 'admin/holter/createHolter',
  startHolter: 'admin/holter/startHolter',
  stopHolter: 'admin/holter/stopHolter',
  getHolterData: 'admin/holter/holterData',
  deleteHolter: 'admin/holter/deleteHolter',
  startAtencion: 'admin/atenciones/startAtencion',
  endAtencion: 'admin/atenciones/endAtencion',
  getUsersAttentions: 'admin/atenciones/getUsersAttentions',
  getAttentions: 'admin/atenciones/getAttentions',
  getAtencionDetail: 'admin/atenciones/getAtencionDetail',
  checkUserInAttention: 'admin/atenciones/checkUserInAttention',
  getFarmacosByQuery: 'admin/recetas/getFarmacosByQuery',
  saveReceta: 'admin/recetas/saveReceta',
  sendRecetaByMail: 'app/recetas/sendRecetaByMail',
  sendMailExamen: 'admin/examenes/sendMailExamen',
  getExamenesByQuery: 'admin/examenes/getExamenesByQuery',
  createExamen: 'admin/examenes/createExamen',
  sendCustomNotification: 'admin/user/sendCustomNotificationAdmin',
  searchTrackingByDate: 'admin/tracking/searchTrackingByDate',
  activateTracking: 'admin/tracking/toogleTrackingNotification',
  createNote: 'admin/notes/createNote',
  getNotes: 'admin/notes/getNotes',
  uploadVideoRecord: 'admin/atenciones/uploadVideoRecord',
  createVideoCallRoom: '/admin/videocalls/createRoom',
  initVideoCallWithDevice: '/admin/videocalls/callFromAdminToDevice',
  getCompanyPreregister: '/admin/user/getCompanyPreregister',
  getUsersGeo: '/admin/user/getUsersGeo',
  getAttendingAppointments: '/app/appointments/getAttendingAppointments',
  getAppointmentInfo: '/app/appointments/getAppointmentInfo',
  createSpecialtySchedule: '/app/schedules/createSpecialtySchedule',
  getSmartbandsGeo: '/admin/smartbands/getSmartbandsGeo',
  getUserFeedbackGeo: '/admin/feedback/getUserFeedbackGeo',
  getOperationalGroups: '/admin/opgroups/getOperationalGroups',
  getUsersOperationalGroup: '/admin/opgroups/getUsersOperationalGroup',
  addOperationalGroup: '/admin/opgroups/addOperationalGroup',
  deleteOperationalGroup: '/admin/opgroups/deleteOperationalGroup',
  updateOperationalGroup: '/admin/opgroups/updateOperationalGroup',
  addUserToOperationalGroup: '/admin/opgroups/addUserToOperationalGroup',
  removeUserFromOperationalGroup: '/admin/opgroups/removeUserFromOperationalGroup',
  getSponsorInfo: '/admin/sponsor/getSponsorInfo',
  updateSponsorInfo: '/admin/sponsor/updateSponsorInfo',
  createSponsor: '/admin/sponsor/createSponsor',
  getAdminSponsors: '/admin/sponsor/getAdminSponsors',
  setUserSponsor: '/admin/sponsor/setUserSponsor',
  removeAffiliation: '/admin/sponsor/removeAffiliation',
  getSponsorNews: '/admin/news/getSponsorNews',
  deleteNews: '/admin/news/deleteNews',
  createNews: '/admin/news/createNews',
  updateNews: '/admin/news/updateNews',
  getLocalNewsConfigOptions: '/admin/companies/getLocalNewsConfigOptions',
  changeNewsConfigOptions: '/admin/companies/changeNewsConfigOptions',
  changeNewsConfigExternalOptions: '/admin/companies/changeNewsConfigExternalOptions',
  getAdvertisementActions: '/admin/advertisement/getAdvertisementActions',
  createAdvertisement: '/admin/advertisement/createAdvertisement',
  getAdvertisementTypes: '/admin/advertisement/getAdvertisementTypes',
  getCompanyAdvertisement: '/admin/advertisement/getSponsorAdvertisement',
  getAdvertisementDetail: '/admin/advertisement/getAdvertisementDetail',
  createAdvertisementConfig: '/admin/advertisement/createAdvertisementConfig',
  updateAdvertisement: '/admin/advertisement/updateAdvertisement',
  updateAdvertisementConfig: '/admin/advertisement/updateAdvertisementConfig',
  deleteAdvertisementConfig: '/admin/advertisement/deleteAdvertisementConfig',
  deleteAdvertisement: '/admin/advertisement/deleteAdvertisement',
  createAdvertisementStepConfig: '/admin/advertisement/createAdvertisementStepConfig',
  deleteAdvertisementStepConfig: '/admin/advertisement/deleteAdvertisementStepConfig',
  updateAdvertisementStepConfig: '/admin/advertisement/updateAdvertisementStepConfig',
  sendAdvertisementNotification: '/admin/advertisement/sendAdvertisementNotification',
  sendAdvertisementNotificationToUsers: '/admin/advertisement/sendAdvertisementNotificationToUsers',
  getAdvertisementActions: '/admin/advertisement/getAdvertisementActions',
  getLastUserMedicalRecord: '/admin/medical_records/getLastUserMedicalRecord',
  getClinicalEvents: '/admin/medical_records/getUserHistoricalClinicalEvents',
  createMedicalRecord: '/admin/medical_records/createMedicalRecord',
  createUserGuardian: '/admin/medical_records/createUserGuardian',
  getDiagnosis: '/admin/medical_records/getDiagnosis',
  createUserClinicalEvent: '/admin/medical_records/createUserClinicalEvent',
  createUserClinicalEvent: '/admin/medical_records/createUserClinicalEvent',
  getRecetasList: '/app/recetas/getRecetasList',
  getExamenesList: '/admin/examenes/getExamenesList',
  getPaisesActivos: '/auth/getPaisesActivos',
  getRegionesActivas: '/auth/getRegionesActivas',
  getComunasActivas: '/auth/getComunasActivas',
  updateUserInfoById: '/admin/user/updateUserInfoById',
  createUser: '/admin/user/createUser',
  getRoles: '/admin/roles/getRoles',
  AlertTest: '/admin/alerts/CallSendAlertToPanel',
}

export {endpoints};