<template>
  <div class="border-primary mt-2 px-0 rounded">
<!--     <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster> -->
    <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
      <div style="display:flex; align-items:center;">
        <p class="mb-0 ">Antecedentes</p>
        <CButton v-c-tooltip="{content: 'Ver Ficha', placement: 'top'}" @click="verFicha"><font-awesome-icon class="mr-2" icon="notes-medical" color="#FFFF" style="font-size:16px; color: #FFF#;"/></CButton>
      </div>
      <CButton
        color="primary"
        size="sm"
        class="ml-2 my-1"
        @click="toggleIduamCard(4)"
      >
        {{showAntecedentesBlock ? 'Ocultar':'Mostrar'}} Antecedentes
      </CButton>
    </div>
    <div v-show="showAntecedentesBlock">
    <div style="overflow-y: scroll; max-height: 350px; padding: 8px;">
        <CRow>
            <CCol col="12" style="width: min-content;">
        <CRow class="mt-4">
            <CCol col="12">
                <label class="label">Antecedentes Mórbidos</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.medical_history" height="150" placeholder="Antecedentes Mórbidos"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Antecedentes Familiares</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.family_medical_history" placeholder="Antecedentes Familiares"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Antecedentes Personales</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.personal_medical_history" placeholder="Antecedentes Personales"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Hospitalizaciones</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.surgical_history_hospitalizations" placeholder="Hospitalizaciones"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Antecedentes Psiquiátricos</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.psychiatric_history" placeholder="Antecedentes Psiquiátricos"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Alergias</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.allergies" placeholder="Alergias"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Inmunizaciones</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.immunizations" placeholder="Inmunizaciones"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Medicamentos de uso habitual</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.regular_medications" placeholder="Medicamentos de uso habitual"></CTextarea>
            </CCol>
            <CCol col="12">
                <label class="label">Información personal</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.personal_information" placeholder="Estado civil , ocupación, red de apoyo, creencia"></CTextarea>
            </CCol>
        </CRow>
            </CCol>
        </CRow>

        <h5>Representante Legal</h5>
        <CRow>
            <CCol col="12">
                <label class="label">Nombre</label>
                <CInput :disabled="disabled" v-model="last_legal_guardian.legal_guardian_name" placeholder="Nombre"></CInput>
            </CCol>
            <CCol col="12">
                <label class="label">Rut</label>
                <CInput :disabled="disabled" v-model="last_legal_guardian.legal_guardian_rut" placeholder="Rut"></CInput>
            </CCol>
        </CRow>
        <div v-if="!disabled" class="d-flex" style="flex-direction: column; gap: 4px;">
        <CButton style="width: 150px;"  @click="createMedicalRecord(); createUserGuardian();" color="primary">
            <CSpinner v-if="loading" color="white" size="sm" />
            <p v-else class="mb-0">Guardar</p>
        </CButton>
            <CAlert style="margin-bottom: 0" v-if="alertSuccess" color="success">Registro guardado correctamente</CAlert>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import { createMedicalRecord, getLastUserMedicalRecord, createUserGuardian } from '../../../api/atenciones'

export default {
  name: 'Antecedentes',
  props: ['atencionID', 'userID', 'showAntecedentesBlock', 'toggleIduamCard', 'goPicInPicCallback'],
  data(){
    return {
        toasts: [],
        last_medical_record: {
        medical_history: '',
        personal_medical_history: '',
        family_medical_history: '',
        surgical_history_hospitalizations: '',
        psychiatric_history: '',
        allergies: '',
        regular_medications: '',
        immunizations: '',
        personal_information: ''
    },
    last_legal_guardian: {
        legal_guardian_name: '',
        legal_guardian_rut: ''
    },
    loading: false,
    alertSuccess: false
    }
  },
/*   computed: {
    disableCreate() {
        if (!this.last_medical_record.medical_history &&  !this.last_medical_record.personal_medical_history && !this.last_medical_record.family_medical_history &&
            !this.last_medical_record.surgical_history_hospitalizations && !this.last_medical_record.psychiatric_history && !this.last_medical_record.allergies && 
            !this.last_medical_record.regular_medications && !this.last_medical_record.immunizations && !this.last_medical_record.personal_information) {
            return true
        } else {
            return false
        }
    }
  }, */
  methods: {
            createMedicalRecord() {
            this.loading = true;
            const data = {...this.last_medical_record}
            data.user_id = this.userID
            createMedicalRecord(data).then(res => {
                this.loading = false;
                this.getLastUserMedicalRecord()
                this.alertSuccess = true;
                setTimeout(() => {
                    this.alertSuccess = false;
                }, 1000);
            }).catch(e => {
                this.loading = false;
                console.log(e);
            })
        },
        getLastUserMedicalRecord() {
            const data = {user_id: this.userID}
            getLastUserMedicalRecord(data).then(res => {
                if (res.last_medical_record) {
                    this.last_medical_record = {
                        medical_history: res?.last_medical_record?.medical_history,
                        personal_medical_history: res?.last_medical_record?.personal_medical_history,
                        family_medical_history: res?.last_medical_record?.family_medical_history,
                        surgical_history_hospitalizations: res?.last_medical_record?.surgical_history_hospitalizations,
                        psychiatric_history: res?.last_medical_record?.psychiatric_history,
                        allergies: res?.last_medical_record?.allergies,
                        regular_medications: res?.last_medical_record?.regular_medications,
                        immunizations: res?.last_medical_record?.immunizations,
                        personal_information: res?.last_medical_record?.personal_information,
                    }
                } 
                if (res.last_legal_guardian) {
                    this.last_legal_guardian = {
                        legal_guardian_name: res?.last_legal_guardian?.name,
                        legal_guardian_rut: res?.last_legal_guardian?.rut
                    }
                }
            })
        },
        createUserGuardian() {
            const data = {user_id: this.userID, legal_guardian_name: this.last_legal_guardian.legal_guardian_name, legal_guardian_rut: this.last_legal_guardian.legal_guardian_rut }
            if (data.legal_guardian_name || data.legal_guardian_rut) {
                createUserGuardian(data).then(res => {
                    console.log(res);
                })
            }
        },
        verFicha() {
            this.goPicInPicCallback()
            this.$router.push('/atenciones-general/' + this.atencionID)
        }
    },
    mounted () {
        this.getLastUserMedicalRecord();
    },
}
</script>