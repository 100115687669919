import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const PreRegisterList = () => import('@/views/pages/PreRegisterList')

const Users = () => import('@/views/users/Users')
const UsersAdmin = () => import('@/views/users/UsersAdmin')
const UsersOperator = () => import('@/views/users/UsersOperator')
const FichaClinica = () => import('@/views/users/FichaClinicaUsuario')
/* const SuperAdminUsers = () => import('@/views/users/SuperAdminUsers') */
const Alerts = () => import('@/views/alerts/Alerts')
const Alert = () => import('@/views/alerts/Alert')
const Cuadrantes = () => import('@/views/cuadrantes/Cuadrantes')
const GruposOperacionales = () => import('@/views/gruposOperacionales/GruposOperacionales')
const Publicidad = () => import('@/views/publicidad/Publicidad')
const PublicidadData = () => import('@/views/publicidad/PublicidadData')
const Citas = () => import('@/views/citas/Citas')
const Horarios = () => import('@/views/horarios/Horarios')
const Atenciones = () => import('@/views/atenciones/Atenciones')
const AtencionesGeneral = () => import('@/views/atencionesGeneral/AtencionesGeneral')
const DetalleAtencion = () => import('@/views/atencionesGeneral/DetalleAtencion')
const Configuration = () => import('@/views/configuration/Configuration')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'forms-preregistro',
          name: 'PreRegisterList',
          component: PreRegisterList
        },
        {
          path: 'citas',
          name: 'Citas',
          component: Citas
        },
        {
          path: 'horarios',
          name: 'Horarios',
          component: Horarios
        },
        {
          path: 'solicitudes-atencion',
          name: 'Atenciones',
          component: Atenciones
        },
        {
          path: 'atenciones-general',
          name: 'AtencionesGeneral',
          component: AtencionesGeneral
        },
        {
          path: 'atenciones-general/:attention_id',
          name: 'DetalleAtencion',
          component: DetalleAtencion
        },
        {
          path: 'configuracion',
          name: 'Configuration',
          component: Configuration
        },
        {
          path: 'users',
          name: 'Users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users,
            },
            {
              path: ':id',
              meta: { label: 'Ficha Paciente'},
              name: 'FichaPaciente',
              component: FichaClinica
            }
          ]
        },
        {
          path: 'operators-users',
          name: 'UsersOperator',
          meta: { label: 'Operators Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'UsersOperator',
              component: UsersOperator,
            }
          ]
        },
        {
          path: 'admin-users',
          name: 'UsersAdmin',
          meta: { label: 'Admin Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'UsersAdmin',
              component: UsersAdmin,
            }
          ]
        },
/*         {
          path: 'superadmin-users',
          name: 'SuperAdminUsers',
          meta: { label: 'Super Admin Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'SuperAdminUsers',
              component: SuperAdminUsers,
            }
          ]
        }, */
        {
          path: 'alertas',
          name: 'Alertas',
          meta: { label: 'Alertas'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Alertas',
              component: Alerts,
            },
            {
              path: ':id',
              meta: { label: 'Alerta'},
              name: 'Alerta',
              component: Alert,
            },
          ]
        },
        {
          path: 'cuadrantes',
          name: 'Cuadrantes',
          meta: { label: 'Cuadrantes'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Cuadrantes',
              component: Cuadrantes,
            },
          ]
        },
        {
          path: 'grupos-operacionales',
          name: 'GruposOperacionales',
          meta: { label: 'Cuadrantes'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'GruposOperacionales',
              component: GruposOperacionales,
            },
          ]
        },
        {
          path: 'publicidad',
          name: 'Publicidad',
          meta: { label: 'Publicidad'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Publicidad',
              component: Publicidad,
            },
            {
              path: ':id',
              meta: { label: 'Datos Publicidad'},
              name: 'Publicidad',
              component: PublicidadData,
            },
          ]
        },
      ]
    },
    {
      path: '/',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

