<template>
	<div class="calluserinfo-container" :class="expanded ? 'expanded':null">
		<div v-if="userInfo" class="inner-container p-2 text-center" v-show="expanded">
      <CImg
      	class="mb-1"
        style="object-fit: cover;width: 100px;height:100px;border-radius: 10px; border: 2px solid #CBCBCB;"
        :src="userInfo.avatarImg"
      />
      <h6>{{userInfo.name}}</h6>
      <p class="caption mb-0"><small>{{userInfo.info.telefono}}</small></p>
      <p class="caption mb-0"><small>{{userInfo.email}}</small></p>
      <p class="caption mb-0"><small>{{userInfo.info.direccion}}</small></p>
		</div>
		<div class="d-flex align-items-center justify-content-between">
	    <div class="px-2 py-1 d-flex align-items-center" style="font-size: 10px;">
	    	<div>Usuario Principal: <b>{{userData.userName}}</b></div>
	    	<div class="recording-button mx-2" v-if="userData && userData.recording"><font-awesome-icon icon="circle"/> Grabando</div>
	    </div>
	    <CButton @click="expanded = !expanded" color="light" size="sm">
				<font-awesome-icon :icon="expanded ? 'chevron-up':'chevron-down'"/>
	    </CButton>
	  </div>
		<div class="d-flex align-items-center justify-content-between" v-if="userData && userData.inBackground">
	    <div class="px-2 py-1 d-flex align-items-center" style="font-size: 10px;">
	    	<div class="mx-2">
	    		<font-awesome-icon icon="circle" color="orange"/> <b>App usuario en Background</b><br/>
	    		<p class="m-0">La aplicación SOSclick del usuario se encuentra en background, esto puede causar que la transmisión del video se vea afectada. Si la app permanece mucho tiempo en este estado se puede llegar perder la conexión.</p>
	    	</div>
	    </div>
		</div>
		<div class="d-flex align-items-center justify-content-between" v-if="userData && userData.recording && recordingMessage">
	    <div class="px-2 py-1 d-flex align-items-center" style="font-size: 10px;">
	    	<div class="mx-2">
	    		<font-awesome-icon icon="circle" color="red"/> <b>Grabación en curso</b><br/>
	    		<p class="m-0">Recuerda avisar al usuario que la videollamada está siendo grabada.</p>
	    	</div>
			<div style="cursor: pointer;" @click="recordingMessage = false">X</div>
	    </div>
		</div>
	</div>
</template>

<script>
	import {getDetails} from '../../api/user'
	export default {
	  name: 'CalledUserInfo',
	  props: ['userData'],
	  data(){
	    return {
	    	userID: null,
	    	userInfo: null,
	    	expanded: false,
			recordingMessage: true
	    }
	  },
	  watch: {
	  	userData(data){
	  		if(data && data.userID && this.userID !== data.userID){
	  			this.userID = data.userID
	  			this.getUserInfo(data.userID)
	  		}
	  	}
	  },
	  mounted(){
  		if(this.userData && this.userData.userID && this.userID !== this.userData.userID){
  			this.userID = this.userData.userID
  			this.getUserInfo(this.userData.userID)
  		}
	  },
	  methods: {
	  	async getUserInfo(userID){
	  		const user = await getDetails(userID)
	  		if(user.code === 200){
	  			this.userInfo = user.user
	  		}
	  	}
	  }
	}
</script>