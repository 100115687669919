<template>
	<CRow class="p-3 w-100 justify-content-center">
		<CCol class="text-center" col="3" xl="3">
			<p class="titulo-factor-medicion-remota">Temperatura</p>
			<p class="valor-factor-medicion-remota">
				<span v-if="medicion && medicion.temperature">
					{{medicion.temperature}}
				</span>
				<span v-else>
					<CSpinner
						v-if="medicionChecking"
						style="width:20px;height:20px;"
						color="white"
					/>
					<span v-else>--</span>
				</span>
				<span class="exp-text"> ºC</span>
			</p>
		</CCol>
		<CCol class="text-center" col="3" xl="3">
			<p class="titulo-factor-medicion-remota">Saturación Oxígeno</p>
			<p class="valor-factor-medicion-remota">
				<span v-if="medicion && medicion.oxigen">
					{{medicion.oxigen}}
				</span>
				<span v-else>
					<CSpinner
						v-if="medicionChecking"
						style="width:20px;height:20px;"
						color="white"
					/>
					<span v-else>--</span>
				</span>
				<span class="exp-text"> %</span>
			</p>
		</CCol>
		<CCol class="text-center" col="3" xl="3">
			<p class="titulo-factor-medicion-remota">Presión Arterial</p>
			<p class="valor-factor-medicion-remota">
				<span v-if="medicion && medicion.presion">
					{{medicion.presion.sistolic + '/' + medicion.presion.diastolic}}
				</span>
				<span v-else>
					<CSpinner
						v-if="medicionChecking"
						style="width:20px;height:20px;"
						color="white"
					/>
					<span v-else>--</span>
				</span>
				<span class="exp-text"> mmHg</span>
			</p>
		</CCol>
		<CCol class="text-center" col="3" xl="3">
			<p class="titulo-factor-medicion-remota">Ritmo Cardíaco</p>
			<p class="valor-factor-medicion-remota">
				<span v-if="medicion && medicion.heart">
					{{medicion.heart}}
				</span>
				<span v-else>
					<CSpinner
						v-if="medicionChecking"
						style="width:20px;height:20px;"
						color="white"
					/>
					<span v-else>--</span>
				</span>
				<span class="exp-text"> /min</span>
			</p>
		</CCol>
		<CCol class="text-center" col="12" xl="12">
			<p class="titulo-factor-medicion-remota ecg">Electrocardiograma</p>
			<p class="valor-factor-medicion-remota-ecg">
				<CChartLine
					style="height:300px"
					:datasets="[
						{
							data: medicion.ecg,
							borderColor: '#0064ed',
							pointRadius: 0,
							fill: false,
							label: 'mV'
						}
					]"
					:options="{ maintainAspectRatio: false }"
				/>
			</p>
		</CCol>
	</CRow>
</template>

<script>

export default {
  name: 'RemoteE600',
	props: ['medicion', 'medicionChecking'],
  data(){
    return {
      deviceStatus: 9
    }
  },
  watch:{
  },
  methods: {
  }
}
</script>
