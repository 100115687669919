import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userData: null,
    monitorPanelShow: true,
    showVideo: true,
    showBotones: false,
    showTracking: true,
    alarmSound: localStorage.alarmSound && JSON.parse(localStorage.alarmSound) ? true : false,
    toCallData: null,
    showUserModalSearch: false,
    reportData: null,
    grillaMonitoreo: false,
    monitoreoGrillaCounter: 0,
    soloTrackingUser: null,
    contentInfoUser: null,
    trackingInBigMap: null,
    mapCenter: {center: { lat: -33.4373063, lng: -70.634297 }, zoom:  12},
    usersInMonitoreo: [],
    primaryDB: null,
    monitoreoDB: null,
    trackingDB: null,
    uploadsInProgress: [],
    videoCallData: {
      status: false,
      alertID: null,
      geoUserAlert: null,
      fullView: true,
      deviceID: null,
      calledUserID: null,
      socketUrl: null,
      socketRoom: null,
      videocallID: null,
      hideVideo: false,
      silent: true,
    },
    testsInProgress: [],
    dashboardMap: 'general',
    enabledLimitMarkers: null,
    limitMarkers: 200,

    audioInputDevice: 'default',
    audioOutputDevice: 'default',
    modalAudioSettings: false,
    unsavedNote: false,
    openModalUnsavedNotes: false
  },
  mutations: {
    setOpenModalUnsavedNotes (state, openModalUnsavedNotes) {
      state.openModalUnsavedNotes = openModalUnsavedNotes
    },
    setUnsavedNote (state, unsavedNote) {
      state.unsavedNote = unsavedNote
    },
    setEnabledLimitMarkers (state, enabledLimitMarkers) {
      state.enabledLimitMarkers = enabledLimitMarkers
    },
    setLimitMarkers (state, limitMarkers) {
      state.limitMarkers = limitMarkers
    },
    setUserData (state, user) {
      state.userData = user
    },
    setPanelShow (state, value) {
      state.monitorPanelShow = value
    },
    setShowVideo (state, value) {
      state.showVideo = value
    },
    setShowBotones (state, value) {
      state.showBotones = value
    },
    setShowTracking (state, value) {
      state.showTracking = value
    },
    setAlarmSound (state, value) {
      localStorage.alarmSound = value;
      state.alarmSound = value
    },
    setCallData (state,value) {
      state.toCallData = value
    },
    setShowUserModalSearch(state,value){
      state.showUserModalSearch = value
    },
    setReportECGData(state,value){
      state.reportData = value
    },
    toogleGrilla(state,value){
      state.grillaMonitoreo = value
    },
    setMonitoreoGrillaCounter(state,value){
      state.monitoreoGrillaCounter = value
    },
    setSoloTrackingUser (state,value) {
      state.soloTrackingUser = value
    },
    setTrackingInBigMap (state,value) {
      state.trackingInBigMap = value
    },
    setMapCenter (state,value) {
      state.mapCenter = value
    },
    setContentInfoUser (state,value) {
      state.contentInfoUser = value
    },
    setUsersInMonitoreo (state,value) {
      state.usersInMonitoreo = value
    },
    setPrimaryDB (state,value) {
      state.primaryDB = value
    },
    setMonitoreoDB (state,value) {
      state.monitoreoDB = value
    },
    setTrackingDB (state,value) {
      state.trackingDB = value
    },
    setUploadsInProgress (state, value) {
      state.uploadsInProgress = value
    },
    setVideoCallData (state, value) {
      state.videoCallData = value
    },
    setTestsInProgress (state, value) {
      state.testsInProgress = value
    },
    setAudioInputDevice (state, value) {
      state.audioInputDevice = value
    },
    setAudioOutputDevice (state, value) {
      state.audioOutputDevice = value
    },
    setModalAudioSettings (state, value) {
      state.modalAudioSettings = value
    },
    setDashboardMap (state, value) {
      state.dashboardMap = value
    },
  },
  getters: {
    toCallData: state => {
      return state.toCallData
    },
    userData: state => {
      return state.userData
    },    
    soloTrackingUser: state => {
      return state.soloTrackingUser
    },
    trackingInBigMap: state => {
      return state.trackingInBigMap
    },
    reportData: state => {
      return state.reportData
    }
  }
})

export default store