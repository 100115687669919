<template>
  <vue-draggable-resizable :resizable="false" :x="0" :y="200" class-name="reportecg-dragable" :z="9999999999" :parent="false" v-show="showReport">
    <div class="d-flex align-items-center" v-if="reportData">
      <div>
        <p class="title-report mb-0 font-weight-bold text-center">Reporte Medición</p>
        <div class="my-2">
          <p class="my-1 font-weight-bold text-center">{{reportData.result}}</p>
          <p class="text-center mb-0">{{reportData.heart_rate}}</p>
          <p class="text-center">{{reportData.atrial_fibrillation}}</p>
          <!-- <img :src="reportData.img" width="100%"/> -->
					<p class="mb-0 text-center" style="font-size: 12px;">Este reporte es generado por una plataforma de Inteligencia Artificial con los datos obtenidos de la medición del usuario.</p>
					<p class="mb-0 text-center font-weight-bold" style="font-size: 12px;">Esta información es referencial.</p>
					<p class="mb-0 text-center font-weight-bold" style="font-size: 12px;">Iduam no puede detectar signos de infarto.</p>

					<div class="mt-2" style="max-height: 400px;overflow-y: scroll;">
						<p class="mb-0 text-center font-weight-bold" style="font-size: 12px;">Electrocardiograma completo</p>
						<CChartLine
							v-for="(ecgChunk, index) in ecgData"
							:key="index"
							style="height:200px"
							:datasets="[
								{
									data: ecgChunk,
									borderColor: '#0064ed',
									pointRadius: 0,
									fill: false,
									label: 'mV'
								}
							]"
							:options="chartOptions"
						/>
					</div>

          <CButton
            color='primary'
            size="sm"
            class="m-1 w-100"
            @click="closeReporte()"
          >
            Cerrar Reporte
          </CButton>
        </div>
      </div>
    </div>
    <p class="mb-0 text-center" style="font-size: 10px;">Puedes mover este cuadro donde quieras y seguir trabajando en la plataforma</p>
  </vue-draggable-resizable>
</template>

<script>
import { mapGetters } from "vuex"
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: 'ReportECGModal',
  components: {VueDraggableResizable},
  computed: {
    ...mapGetters(["reportData"]),
		ecgData () {
			if(this.reportData && this.reportData.fullEcgData){
				let datos = [...this.reportData.fullEcgData]
				let graph = []
				const chunkSize = 500;
				for (let i = 0; i < datos.length; i += chunkSize) {
					const chunk = datos.slice(i, i + chunkSize)
					graph.push(chunk)
				}
				console.log('graph', graph)
				return graph
			}
			return []
		},
  },
  data(){
    return {
      showReport: false,
      imgZoom: true,
			chartOptions: {
				maintainAspectRatio: false,
				scales: {
					yAxes: [{
						ticks: {
							display: false
						}
					}]
				},
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
						label: function(tooltipItem) {
							return tooltipItem.yLabel;
						}
					}
				}
			}
    }
  },
  watch:{
    reportData: function(data){
      if(data){
        this.showReport = true
      }
      else{
        this.showReport = false
      }
    }
  },
  methods: {    
    closeReporte(){
      this.showReport = false
      this.$store.commit('setReportECGData', null)
    }
  }
}
</script>
