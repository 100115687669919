<template>
  <div class="wrapper monitor-wrapper">
    <CToaster :autohide="3000">
      <template v-for="(toast,index) in toasts">
        <CToast
          :key="index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Monitoreo"
      color="primary"
      :show.sync="showModal"
      class="modal-monitoreo"
      :closeOnBackdrop="false"
      v-if="!showPIP"
    >
      <template #header>
        <h5 class="mb-0 font-weight-bold">
          <CIcon name="cilMobile" class="icons-svg" style="width: 1.5rem!important;height: 1.5rem!important;"/> Video Llamada
        </h5>
      </template>
      <CRow v-if="monitoreo.monitoreoActivo">
        <CCol col="12" xl="12">
          <CRow :gutters="false">
            <CCol col="6" xl="6">
              <div style="height: 60vh;" class="d-flex w-100 flex-column h-100 px-0">
                <div class="p-0" style="border-right: 1px solid #CBCBCB;">
                  <div class="row" :class="{'full-monitor': fullMonitor}" id="monitor">
                    <div v-show="loadingMonitoreo || restartingMonitoreo"
                        style="text-align: center;height: 50vh;flex: 1;align-items: center;justify-content: center;display: flex;flex-direction: column;">
                      <CSpinner
                        style="width:4rem;height:4rem;"
                        color="primary"
                        grow
                      />
                      Levantando Video ...
                    </div>
                    <div id="monitor-remote" v-show="!loadingMonitoreo">
                      <div id="no-remote-video" v-show="remoteCloseMonitoreo && !restartingMonitoreo">
                        <p>Se ha perdido la conexión con el usuario, si deseas intentar retomar la comunicación por favor haz click en el botón de abajo</p>
                        <CButton
                          color="primary"
                          class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0"
                          variant="outline"
                          style="max-width: 250px;margin: auto;"
                          @click="restartMonitoreo()"
                        >
                          <CIcon name="retake" size="xl" class="icons-svg primary" style="width: 1.5rem!important;height: 1.5rem!important;"/> Reiniciar
                        </CButton>
                      </div>
                      <div v-show="!remoteCloseMonitoreo">
                        <CButton
                          color="light"
                          size="sm"
                          class="fullmonitor-btn"
                          @click="toggleFullMonitor()"
                        >
                          <font-awesome-icon icon="expand" style="font-size: 20px" />
                        </CButton>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="p-2 d-flex justify-content-between" style="border-top: 1px solid #CBCBCB;border-bottom: 1px solid #CBCBCB;">
                      <CButton
                        color="primary"
                        class="custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                        :class="userVideo ? '':'text-white'"
                        :variant="userVideo ? 'outline':''"
                        style="flex: 1;font-size: 10px;"
                        @click="showVideo(monitoreo.monitoreoActivo.deviceID)"
                      >
                        <font-awesome-icon :icon="userVideo ? 'video-slash':'video'" style="font-size:16px;"/> {{userVideo ? 'Ocultar Video':'Mostrar Video'}}
                      </CButton>
                      <CButton
                        color="primary"
                        class="custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                        :class="userLuz ? 'text-white':''"
                        :variant="userLuz ? '':'outline'"
                        style="flex: 1;font-size: 10px;"
                        @click="toogleFlash(monitoreo.monitoreoActivo.deviceID)"
                      >
                        <font-awesome-icon icon="lightbulb" style="font-size:16px;"/> Luz
                      </CButton>
                      <CButton
                        color="primary"
                        class="custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                        :class="userAlarm ? 'text-white':''"
                        :variant="userAlarm ? '':'outline'"
                        style="flex: 1;font-size: 10px;"
                        @click="toogleAlarm(monitoreo.monitoreoActivo.deviceID)"
                      >
                        <font-awesome-icon icon="exclamation-triangle" style="font-size:16px;"/> Alarma
                      </CButton>
                      <CButton
                        color="primary"
                        class="custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                        variant="outline"
                        style="flex: 1;font-size: 10px;"
                        @click="changeCamera(monitoreo.monitoreoActivo.deviceID)"
                      >
                        <font-awesome-icon icon="camera" style="font-size:16px;"/> Cambiar Cámara
                      </CButton>
                      <CButton
                        color="primary"
                        class="custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                        variant="outline"
                        style="flex: 1;font-size: 10px;"
                        @click="goPicInPic()"
                      >
                        <font-awesome-icon icon="desktop" style="font-size:16px;"/> PIP
                      </CButton>
                      <CButton
                        v-c-tooltip="recording ? 'Detener Grabación':'Grabar Video del Usuario'"
                        color="primary"
                        class="custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                        :variant="recording ? '':'outline'"
                        style="flex: 1;font-size: 10px;"
                        @click="recording ? stopRecording():startRecording()"
                      >
                        <font-awesome-icon
                          :icon="recording ? 'stop':'circle'"
                          style="font-size:16px;"/> {{recording ? 'Detener':'Grabar'}}
                      </CButton>
                    </div>
                    <CRow class="p-3">
                      <CCol class="d-flex flex-column" col="3" xl="3">
                        <p class="w-100 text-center mb-0" style="font-size: 10px;">¿No ves el video correctamente?</p>
                        <p class="w-100 text-center mb-0" style="font-size: 10px;">Intenta reiniciar el monitoreo.</p>
                      </CCol>
                      <CCol class="d-flex" col="4" xl="4">
                        <CButton
                          color="primary"
                          class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                          variant="outline"
                          @click="restartMonitoreo()"
                        >
                          <CIcon name="retake" size="xl" class="icons-svg primary" style="width: 1.5rem!important;height: 1.5rem!important;"/> Reiniciar
                        </CButton>
                      </CCol>
                      <CCol class="d-flex" col="5" xl="5">
                        <div class="w-100">
                          <CButton
                            color="primary"
                            size="sm"
                            class="w-100 custom-button-info-content d-flex justify-content-around align-items-center py-0 mx-1"
                            variant="outline"
                            @click="stopMonitoreo(true, true, monitoreo.monitoreoActivo.id_usuario)">
                            <CIcon name="cilCheckAlt" size="xl" class="icons-svg primary"/>
                            Terminar Monitoreo
                          </CButton>
                          <div
                            class="d-flex align-items-center mt-2"
                            v-if="alerta">
                            <CSwitch
                              class="mr-2"
                              size="sm"
                              color="primary"
                              shape = "pill"
                              :checked.sync="closeAlertCheck"
                              @update:checked="checkCloseAlert"
                            />
                            <p class="mb-0" style="font-size: 10px;">Cerrar alerta asociada</p>
                          </div>
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                </div>
              </div>
            </CCol>
            <CCol col="6" xl="6">
              <div class="px-0">
                <CContainer fluid>
                  <CRow class="px-2 py-3" v-if="monitorActivoUser" style="border-bottom: 1px solid #CBCBCB;">
                    <CCol col="12" xl="12" class="d-flex flex-row align-items-stretch">
                      <CImg
                        class="mb-1"
                        style="object-fit: cover;width: 70px;height:70px;border-radius: 10px; border: 2px solid #CBCBCB;"
                        v-if="monitorActivoUser.info.avatar"
                        :src="monitorActivoUser.info.avatar"
                      />
                      <div class="icon-alerta-user" v-else><font-awesome-icon icon="user"/></div>
                      <div class="ml-3 d-flex flex-column">
                        <h6 style="font-size: 14px;" class="text-primary mb-0">{{monitorActivoUser.name}}</h6>
                        <h6 style="font-size: 12px;font-weight: 400;color: #666666;" class="mb-0">{{monitorActivoUser.email}}</h6>
                        <h6 style="font-size: 12px;font-weight: 400;color: #666666;">{{monitorActivoUser.info ? monitorActivoUser.info.telefono:null}}</h6>
                      </div>
                    </CCol>
                  </CRow>
                </CContainer>
                <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top m-3">
                  <p class="mb-0 ">Notas</p>
                  <CButton
                    color="primary"
                    size="sm"
                    class="ml-2 my-1"
                    @click="() => {this.showNotasForm = !this.showNotasForm}"
                  >
                    {{showNotasForm ? 'Ocultar':'Mostrar'}} Formulario Notas
                  </CButton>
                </div>
                <CContainer v-if="monitorActivoUser" v-show="showNotasForm" class="bottom-container small-buttons-custom" fluid>
                  <NewNoteForm :type="1" :userID="monitorActivoUser.id" :deviceID="monitoreo.monitoreoActivo.deviceID" :atencionID="atencionID"/>
                </CContainer>
                <CContainer>
                  <!-- Componente Iduam Mediciones, recetas, licencias, etc -->
                  <IduamConsulta
                    :monitorActivoUser="monitorActivoUser"
                    :medicion="medicion"
                    :medicionSmartband="medicionSmartband"
                    :medicionChecking="medicionChecking"
                    :startMedicion="startMedicion"
                    :stopMedicion="stopMedicion"
                    :atencionID="atencionID"
                  />
                </CContainer>
                <CRow class="p-3" v-if="showUserInfo">
                  <CCol class="d-flex" col="12" xl="12" v-if="monitorActivoUser">
                    <div class="mr-4">
                      <img
                        v-if="monitorActivoUser.info.avatar"
                        :src="monitorActivoUser.info.avatar"
                        class="c-mb-2 avatar-alerta-user"
                      />
                      <div class="icon-alerta-user" v-else><font-awesome-icon icon="user"/></div>
                    </div>
                    <div>
                      <p class="mb-0 text-primary font-weight-bold">{{monitorActivoUser.name}}</p>
                      <p class="mb-0 text-primary d-flex align-items-center">
                        <CImg
                          class="mr-2"
                          height="12"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 22@2x.png')"
                        />
                        {{monitorActivoUser.email}}
                      </p>
                      <p class="mb-0 text-primary d-flex align-items-center">
                        <CImg
                          class="mr-2"
                          height="12"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"
                        />{{monitorActivoUser.info.telefono}}
                      </p>
                      <p class="mb-0 text-primary d-flex align-items-center">
                        <CImg
                          class="mr-2"
                          height="12"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 21@2x.png')"
                        />{{monitorActivoUser.info.direccion}}
                      </p>
                    </div>
                  </CCol>
                </CRow>
                <CRow v-if="monitorActivoUser && showUserInfo" class="px-3 monitores-container-in-modal">
                  <div
                    v-if="monitorActivoUser.dispositivos.length === 0"
                    class="d-flex justify-content-center align-items-center w-100">
                    <CSpinner
                      style="width:20px;height:20px;"
                      color="primary"
                    />
                  </div>
                  <div
                    v-for="monitor in monitorActivoUser.dispositivos"
                    class="col-sm-12 border-bottom d-flex justify-content-between align-items-center py-3 primary-text">
                    <div class="d-flex justify-content-start align-items-center">
                      <div style="position: relative;width: 40px;text-align: center">
                        <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20"
                          :src="monitor.is_monitor ? require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png') : require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
                        <div class="active-badge-indicator-monitor"></div>
                      </div>
                      <div>
                        <p class="mb-0 text-primary">Nombre del Dispositivo</p>
                        <p class="mb-0 text-primary">{{monitor.device_name}}</p>
                      </div>
                    </div>
                    <div>
                      <img
                        v-if="monitoreo.monitoreoActivo && monitoreo.monitoreoActivo.alerta && monitoreo.monitoreoActivo.alerta.deviceID === monitor.device_id"
                        style="margin-right: 10px;"
                        class="primary-color-image-overlay" height="20"
                        :src="require('../../assets/imgicons/ICONOS SOSRecurso 12@2x.png')"/>
                      <CButton
                        color="primary"
                        size="sm"
                        class="ml-2 my-1"
                        @click="setMonitorear(monitor)"
                      >
                        <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                      </CButton>
                    </div>
                  </div>
                </CRow>
                <CRow class="px-3" v-if="showUserInfo && monitorActivoUser && monitorActivoUser.prioridad && monitorActivoUser.prioridad.length > 0">
                  <CCol col="12" xl="12">
                    <div class="d-flex justify-content-between align-items-center my-3 p-2 bg-primary text-white rounded-top">
                      <p class="mb-0">Prioridades asignadas al usuario</p>
                    </div>
                  </CCol>
                  <div v-for="(prioridad, index) in monitorActivoUser.prioridad">
                    <CCol col="12" xl="12">
                      <p class="mb-0">
                        <CBadge color="primary" class="mr-2">{{index + 1}}</CBadge>
                        <b class="mr-3">{{prioridad.description}}</b>
                      </p>
                      <p class="ml-4" style="font-size: 12px;">Inicio: {{prioridad.start}} - Fin: {{prioridad.end}}</p>
                    </CCol>
                  </div>
                </CRow>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CRow v-else>
        <CCol col="12" xl="12">
          <div
              style="text-align: center;min-height: 50vh;flex: 1;align-items: center;justify-content: center;display: flex;flex-direction: column;">
            <CSpinner
              style="width:4rem;height:4rem;"
              color="primary"
              grow
            />
            Cargando información para monitoreo
          </div>
        </CCol>
      </CRow>
      <template #footer>
        ...
      </template>
    </CModal>
    <div v-if="monitores" class="panel-lateral" :class="$store.state.monitorPanelShow ? 'opened':'closed'" :style="'height: ' + containerHeight + 'px; margin-top: ' + headerHeight + 'px;'">
      <div class="inner-panel" v-show="$store.state.monitorPanelShow">
        <CTabs variant="pills" class="custom-monitoreo-tabs">
          <CTab title="Monitores" active>
            <template #title>
              <img height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png')"/>
              Monitores
            </template>
            <CInput
              v-show="monitoresAll.length > 0"
              class="mx-2 mt-2 mb-0"
              placeholder="Buscar por usuario"
              @input="searchMonitores"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
            <div v-if="monitores.length > 0" class="monitor-container" v-for="(monitor, index) in monitores">
              <div class="mb-2 name-user-monitoreo" v-show="index === 0 || (index > 0 && monitores[index - 1].id_usuario !== monitor.id_usuario)">
                <div class="icon-monitoreo-user"><font-awesome-icon icon="user"/></div> {{monitor.nombre}}
              </div>
              <div class="row px-1">
                <div class="col-sm-12 d-flex justify-content-start align-items-center primary-text">
                  <div style="position: relative;">
                    <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png')"/>
                    <div class="active-badge-indicator-monitor"></div>
                  </div>
                  <div>
                    <p class="mb-0">Nombre del Dispositivo</p>
                    <p class="mb-0">{{monitor.deviceData ? monitor.deviceData.device_name : 'Sin Datos'}}</p>
                  </div>
                </div>
                <div class="col-sm-12 d-flex justify-content-center align-items-center" style="color: grey">
                  <div class="mt-3 buttons-container-monitoreo" v-if="monitor.userActivo">
                    <CButton
                      color="success"
                      size="sm"
                      class="mr-2 my-1"
                      style="width: 30px; height: 30px;background-color: #42AB0F;"
                      @click="resetMonitor(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="sync" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="mx-2 my-1"
                      style="width: 30px; height: 30px;background-color: #095FC1;"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      class="mx-2 my-1"
                      style="width: 30px; height: 30px;background-color: #DB131A;"
                      @click="toogleAlarm(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="volume-up" />
                    </CButton>
                    <CButton
                      :disabled="monitor.remoteReady"
                      color="primary"
                      size="sm"
                      class="ml-2 my-1"
                      style="flex: 1;"
                      @click="initMonitoreo(monitor)"
                    >
                      <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                    </CButton>
                  </div>
                  <div v-else>
                    <CButton
                      color="secondary"
                      size="sm"
                      class="my-1"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="monitores.length <= 0" class="text-center my-5">
              No hay monitores activos
            </div>
          </CTab>
          <CTab title="Personales">
            <template #title>
              <img height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
              Personales
            </template>
            <CInput
              v-show="personalesAll.length > 0"
              class="mx-2 mt-2 mb-0"
              placeholder="Buscar por usuario"
              @input="searchPersonales"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
            <div v-if="personales.length > 0" class="monitor-container px-3" v-for="(monitor, index) in personales">
              <div class="mb-2 name-user-monitoreo" v-show="index === 0 || (index > 0 && personales[index - 1].id_usuario !== monitor.id_usuario)">
                <div class="icon-monitoreo-user"><font-awesome-icon icon="user"/></div> {{monitor.nombre}}
              </div>
              <div class="row px-1">
                <div class="col-sm-12 d-flex justify-content-start align-items-center primary-text">
                  <div style="position: relative;">
                    <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
                    <div class="active-badge-indicator-monitor"></div>
                  </div>
                  <div>
                    <p class="mb-0">Nombre del Dispositivo</p>
                    <p class="mb-0">{{monitor.deviceData ? monitor.deviceData.device_name : 'Sin Datos'}}</p>
                  </div>
                </div>
                <div class="col-sm-12 d-flex justify-content-center align-items-center" style="color: grey">
                  <div class="mt-3 buttons-container-monitoreo" v-if="monitor.userActivo">
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #42AB0F;"
                      @click="resetMonitor(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="sync" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #095FC1;"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #DB131A;"
                      @click="toogleAlarm(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="volume-up" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #8a93a2;"
                      @click="setMapPosition(monitor)"
                    >
                      <font-awesome-icon icon="search-location" />
                    </CButton>
                    <CButton
                      :disabled="monitor.remoteReady"
                      color="primary"
                      size="sm"
                      class="ml-2 my-1"
                      style="flex: 1;"
                      @click="initMonitoreo(monitor)"
                    >
                      <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                    </CButton>
                  </div>
                  <div v-else>
                    <CButton
                      color="secondary"
                      size="sm"
                      class="my-1"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="personales.length <= 0" class="text-center my-5">
              No hay dispositivos activos o en espera
            </div>
          </CTab>
        </CTabs>
      </div>
      <button type="button" class="btn btn-pill btn-primary custom-btn"
        @click="commitPanelShow()">
        <font-awesome-icon :icon="$store.state.monitorPanelShow ? 'chevron-right':'chevron-left'"/>
      </button>
    </div>
    <vue-draggable-resizable :resizable="false" :draggable="true" :x="0" :y="150" class-name="pip-monitoreo" :z="9999999999" :parent="false" v-show="showPIP">
      <div class="d-flex flex-row align-items-stretch py-3 px-4">
        <CButtonClose style="font-size: 28px;position: absolute;top: 10px;right: 10px;font-weight: 400;z-index: 9999;" @click="stopMonitoreo(true, true, monitoreo.monitoreoActivo.id_usuario)"/>
        <CImg
          class="mb-1"
          style="object-fit: cover;width: 60px;height:60px;border-radius: 10px; border: 2px solid #CBCBCB;"
          v-if="monitorActivoUser && monitorActivoUser.info &&  monitorActivoUser.info.avatar"
          :src="monitorActivoUser.info.avatar"
        />
        <div
          style="
            object-fit: cover;
            width: 60px;
            height:60px;
            border-radius: 10px;
            border: 2px solid #CBCBCB;
            font-size: 25px;
            color: #d0282e;
            background: #f2f2f2;"
          class="d-flex justify-content-center align-items-center"
          v-else><font-awesome-icon icon="user"/></div>
        <div class="ml-3 d-flex flex-column" v-if="monitoreo && monitorActivoUser">
          <h6 style="font-size: 14px;" class="text-primary">{{monitorActivoUser.name}}</h6>
          <h6
            style="font-size: 12px;font-weight: 400;color: #666666;text-transform: capitalize;"
            v-if="monitoreo.monitoreoActivo && monitoreo.monitoreoActivo.deviceData">
            Dispositivo {{monitoreo.monitoreoActivo.deviceData.device_brand}} {{monitoreo.monitoreoActivo.deviceData.device_name}}
          </h6>
        </div>
      </div>
      <div class="monitor-remote-pip-container">
        <div v-show="loadingMonitoreo || restartingMonitoreo"
            style="text-align: center;height: 400px;flex: 1;align-items: center;justify-content: center;display: flex;flex-direction: column;">
          <CSpinner
            style="width:4rem;height:4rem;"
            color="primary"
            grow
          />
          Levantando Video ...
        </div>
        <div id="monitor-remote-pip" v-show="!loadingMonitoreo">
          <div id="no-remote-video" v-show="remoteCloseMonitoreo && !restartingMonitoreo">
            <p>Se ha perdido la conexión con el usuario, si deseas intentar retomar la comunicación por favor haz click en el botón de abajo</p>
          </div>
        </div>
      </div>
      <div class="d-flex dlex-row justify-content-center align-items-center py-3 px-4">
        <CButton
          v-c-tooltip="'Reiniciar Monitoreo'"
          color='primary'
          variant="outline"
          class="custom-button-info-content d-flex justify-content-around align-items-center py-3 mx-1 square-custom"
          size="sm"
          @click="restartMonitoreo()"
        >
          <CIcon name="retake" size="xl" class="icons-svg primary" style="width: 1.5rem!important;height: 1.5rem!important;"/>
        </CButton>
        <CButton
          color="primary"
          size="sm"
          class="custom-button-info-content d-flex justify-content-around align-items-center py-3 flex-grow-1 mx-1"
          variant="outline"
          @click="stopMonitoreo(true, true, monitoreo.monitoreoActivo.id_usuario)">
          <CIcon name="cilCheckAlt" size="xl" class="icons-svg primary"/>
          Terminar Monitoreo
        </CButton>
      </div>
    </vue-draggable-resizable>
    <MonitoreoGrid />
  </div>

</template>

<script>
import io from 'socket.io-client'
import * as firebase from 'firebase/app'
import ReportECG from './ReportECG'
import 'firebase/database'
import {getDetails, startMonitoreo} from '../../api/user'
import {startAtencion, endAtencion} from '../../api/atenciones'
import {uploadFile} from '../../api/files'
import VueDraggableResizable from 'vue-draggable-resizable'

import MonitoreoGrid from './MonitoreoGrid'
import IduamConsulta from './IduamConsulta'
import NewNoteForm from './Components/NewNoteForm'

import RecordRTC, {invokeSaveAsDialog} from 'recordrtc'

export default {
  name: 'Monitoreo',
  components: {ReportECG, VueDraggableResizable, MonitoreoGrid, NewNoteForm, IduamConsulta},
  props: ['showCloseAlert'],
  data () {
    return {
      showUserInfo: false,
      showNotasForm: false,
      collapse: false,
      headerHeight: 56,
      containerHeight: 700,
      showModal: false,
      showPanel: true,
      monitoreo: {
        socketURL: this.$store.state.userData.info.cuadrante.socket_url,
        localStream: null,
        pcPeers: [],
        monitoreoActivo: false,
        socket: null,
        roomIDActivo: null,
        socketIdActivo: null,
      },
      monitoreoActivo: null,
      monitores: [],
      personales: [],
      monitoresAll: [],
      personalesAll: [],
      testLocal: null,
      monitorActivoUser: null,
      loadingMonitoreo: false,
      toasts: [],
      userVideo: false,
      userLuz: false,
      userAlarm: false,
      fullMonitor: false,
      admin: this.$store.state.userData,
      cuadrante: this.$store.state.userData.info.cuadrante_id,
      alerta: null,
      closeAlertCheck: false,
      remoteCloseMonitoreo: false,
      restartingMonitoreo: false,
      medicion: {},
      medicionChecking: false,
      medicionSmartband: null,
      showReportData: true,
      reportData: null,
      showPIP: false,
      remoteStream: null,
      atencionID: null,
      recorder: null,
      recording: false
    }
  },
  beforeDestroy(){
    this.$events.remove('startMonitoreo')
    this.$events.remove('startMonitoreoFromUserList')
  },
  watch:{
    $route (to, from){
      if(to !== 'Dashboard'){
        this.$store.commit('setPanelShow', false)
      }
    }
  },
  async mounted(){
    const self = this
    if(this.$router.currentRoute.name !== 'Dashboard'){
        this.$store.commit('setPanelShow', false)
    }
    this.$events.listen('startMonitoreoFromUserList', eventData => {
      this.initMonitoreo(eventData)
    })
    this.$events.listen('startMonitoreo', eventData => {
      self.alerta = null
      self.closeAlertCheck = false
      if(eventData.alerta){
        self.alerta = eventData.alerta
        self.closeAlertCheck = true
      }
      if(eventData.showVideo){
        this.userVideo = true
        firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + eventData.deviceID + '/showVideo').set(true)
      }
      this.initMonitoreo(eventData, eventData.showPIP)
    })
    this.headerHeight = document.getElementsByTagName('header')[0].clientHeight
    this.containerHeight = document.getElementsByTagName('main')[0].clientHeight
    this.monitoreo.socket = io(this.monitoreo.socketURL,
        {
          transports: ['websocket'],
          autoConnect: false,
          reconnect: true,
          forceNew: true
        })
    this.monitoreo.socket.on('connect', function(data) {
      self.setMedia(self.monitoreo.monitoreoActivo.deviceID, self.monitoreo.monitoreoActivo)
    });
    this.monitoreo.socket.on('exchange', function(data){
        if (data.from == self.monitoreo.socketIdActivo)
            self.exchange(data);
    });
    this.monitoreo.socket.on('leave', function(socketId){
        if (socketId == self.monitoreo.socketIdActivo)
            self.leave();
    });
    this.usersActivo()
  },
  methods: {
    async startRecording(){
      if(this.remoteStream){
        this.recorder = new RecordRTC(this.remoteStream, { type: 'video', mimeType: 'video/mp4' })
        this.recorder.startRecording()
        this.recording = true
      }
    },
    stopRecording(){
      if(this.recorder){
        this.recorder.stopRecording(async () => {
          this.recording = false
          const blob = this.recorder.getBlob()
          const date = + new Date()

          let formData = new FormData()
          formData.append('id_usuario', this.monitoreoActivo.id_usuario)
          formData.append('id_admin', this.$store.state.userData.id)
          formData.append('video', blob)
          formData.append('atencion_id', this.atencionID)
          formData.append('device_id', this.monitoreoActivo.deviceData.id)
          uploadFile(formData, 'Grabación Videollamada')
        })
      }
    },
    async setStartAtencion(){
/*       const atencion = await startAtencion({user_id: this.monitoreoActivo.id_usuario}) */
      const atencion = await startAtencion({callee_id: this.monitoreoActivo.id_usuario, videocall_id: this.videocallID})
      if(atencion.code === 200){
        this.atencionID = atencion.atencion.id
      }
    },
    startPIP(){
      this.showPIP = true
    },
    toggleUserInfo(){
      this.showUserInfo = !this.showUserInfo
    },
    async checkSmartband(userID){
      const self = this
      await firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).remove()
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).on('child_added',function(data){
        if(data.key === "smartband"){
          self.medicionSmartband = data.val()
        }
        if(data.key === "statusMedicion"){
          self.medicionChecking = data.val()
        }
        if(data.key === "presion" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { presion: data.val() })
        }
        if(data.key === "heart" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { heart: data.val() })
        }
        if(data.key === "oxigen" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { oxigen: data.val() })
        }
        if(data.key === "ecg" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { ecg: data.val() })
        }
        if(data.key === "progress" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { progress: data.val() })
        }
        if(data.key === "report" && data.val()){
          self.$store.commit('setReportECGData', data.val())          
        }
        if(data.key === "loadingReport"){
          self.medicion = Object.assign({}, self.medicion, { loadingReport: data.val() })       
        }
      })    
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).on('child_changed',function(data){
        if(data.key === "smartband"){
          self.medicionSmartband = data.val()
        }
        if(data.key === "statusMedicion"){
          self.medicionChecking = data.val()
        }
        if(data.key === "presion" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { presion: data.val() })
        }
        if(data.key === "heart" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { heart: data.val() })
        }
        if(data.key === "oxigen" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { oxigen: data.val() })
        }
        if(data.key === "ecg" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { ecg: data.val() })
        }
        if(data.key === "progress" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { progress: data.val() })
        }
        if(data.key === "report" && data.val()){
          self.$store.commit('setReportECGData', data.val())
        }
        if(data.key === "loadingReport"){
          self.medicion = Object.assign({}, self.medicion, { loadingReport: data.val() })
        }
      })
    },
    startMedicion(userID){
      const self = this
      this.medicion = {}
      this.medicionChecking = true
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).set({
        requestMedicion: true
      })
    },
    stopMedicion(userID, close = false){
      const self = this
      this.medicion = {}
      this.medicionChecking = false
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).set({
        stopMedicion: close
      })
      if(close){
        setTimeout(function() {
          firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).remove()
        }, 1000);
      }
    },
    setMapPosition(monitor){
      const center = {
        center: {
          lat: monitor.latitud,
          lng: monitor.longitud
        },
        zoom: 18
      }
      this.$store.commit('setMapCenter', center)
    },
    commitPanelShow(){
      this.$store.commit('setPanelShow', !this.$store.state.monitorPanelShow)
    },
    searchPersonales(search){
      this.personales = this.personalesAll.filter(function(item){
        return item.nombre.toLowerCase().includes(search) || item.nombre.includes(search)
      })
    },
    searchMonitores(search){
      this.monitores = this.monitoresAll.filter(function(item){
        return item.nombre.toLowerCase().includes(search) || item.nombre.includes(search)
      })
    },
    checkCloseAlert(close){
      this.closeAlertCheck = close
    },
    toggleFullMonitor(){
      this.fullMonitor = !this.fullMonitor
    },
    setMessage(type = 0, user, message){
      const found = this.monitores.findIndex(function(item,index){
        return item.id_usuario === user
      })
      if(found !== -1){
        const monitor = Object.assign( this.monitores[found], {message: message} )
        this.$set(this.monitores, found, monitor)
        this.monitores.sort()
      }
    },
    message(type = 0, user){
      const found = this.monitores.findIndex(function(item,index){
        return item.id_usuario === user
      })
      if(found !== -1){
        return this.monitores[found].message
      }
      return "Prueba mensaje"
    },
    checkOnline(idUser){
      const self = this
      self.setMessage(0, idUser, "llamada")
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + idUser + '/remoteChecking').set(false)
      setTimeout(function() {
        firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + idUser + '/remoteChecking').once('value',function(data){
            if(data.val() === false){
              setTimeout(function() {
                firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + idUser).remove()
              }, 2000);
            }
        });
      }, 3000);
    },
    usersActivo(){
      const self = this
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_added',function(data){
        let monitor = data.val()
        monitor.key = data.key
        if(monitor.deviceData && monitor.deviceData.is_monitor){
          self.monitores.push(monitor)
          self.monitores.sort((a, b) => a.id_usuario - b.id_usuario)
        }
        else{
          self.personales.push(monitor)
          self.personales.sort((a, b) => a.id_usuario - b.id_usuario)
        }
        self.monitoresAll = self.monitores
        self.personalesAll = self.personales
      })
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_changed',function(data){
        const found = self.monitores.findIndex(function(item, index){
          return item.key === data.key
        })
        if(found !== -1){
          let monitor = data.val()
          monitor.key = data.key
          self.monitores[found] = monitor
          self.monitores.sort((a, b) => a.id_usuario - b.id_usuario)
        }
        else{
          const found = self.personales.findIndex(function(item, index){
            return item.key === data.key
          })
          if(found !== -1){
            let monitor = data.val()
            monitor.key = data.key
            self.personales[found] = monitor
            self.personales.sort((a, b) => a.id_usuario - b.id_usuario)
          }
        }
        self.monitoresAll = self.monitores
        self.personalesAll = self.personales
      })
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_removed',function(data){
        const removed = data.val()
        self.monitores = self.monitores.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        self.personales = self.personales.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        self.monitoresAll = self.monitores
        self.personalesAll = self.personales
      })
    },
    stopMonitoreo(close = false, change = true, userID = null){
      const self = this
      return new Promise(async function(resolve, reject){
        if(userID){
          self.stopMedicion(userID, true)
        }
        if(self.closeAlertCheck){
          self.showCloseAlert(self.alerta)
        }
        if(change){
          self.userVideo = false
          self.userAlarm = false
          self.userLuz = false
          self.alerta = null
          self.closeAlertCheck = false
          self.showPIP = false
        }
        await self.leave(close, change)
        self.medicion = {}
        self.medicionChecking = false
        self.medicionSmartband = null
        if(self.atencionID && close){
          endAtencion({atencion_id: self.atencionID})
        }
        setTimeout(function() {
          resolve(true)
        }, 1000);
      })
    },
    toogleAlarm(monitor){
      const self = this
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/startSound').set(!this.userAlarm ? true:null)
      this.userAlarm = !this.userAlarm
    },
    toogleFlash(monitor){
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/light').set(!this.userLuz ? true:null)
      this.userLuz = !this.userLuz
    },
    changeCamera(monitor){
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/changeCamera').set(true)
    },
    showVideo(monitor){
      this.userVideo = !this.userVideo
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/showVideo').set(this.userVideo)
    },
    resetMonitor(monitor){
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/resetMonitoreo').set(true)
    },
    awakePhone(monitorID){
      const self = this
      return new Promise(async function(resolve,reject){
        await firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).remove()
        await startMonitoreo(monitorID) //notificación para despertar
        let auxSetTimeout = setTimeout(() => {
          firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).off('child_added');
          resolve(false);
        }, 50000);

        firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).on('child_added',async function(data){
          if(data.key == 'userActivo' && data.val() === true){
            clearTimeout(auxSetTimeout);
            firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).off('child_added');
            resolve(true);
          }
        });
      });
    },
    async restartMonitoreo(){
      await this.stopMonitoreo(false,false, this.monitoreo.monitoreoActivo.id_usuario)
      const monitoreo = this.monitoreo.monitoreoActivo
      this.loadingMonitoreo = true
      this.remoteCloseMonitoreo = false
      this.restartingMonitoreo = true
      if(this.$store.state.showVideo){
        this.userVideo = true
        firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitoreo.deviceID + '/showVideo').set(true)
      }
      this.remoteCloseMonitoreo = false
      const awake = await this.awakePhone(monitoreo.deviceID)
      if(awake){
        this.monitoreo.socket.connect()
      }
      else{
        this.toasts.push({
          title: 'Error',
          message: 'No pudimos levantar el monitoreo en el dispositivo seleccionado en el tiempo establecido.'
        })
        this.remoteCloseMonitoreo = true
        this.loadingMonitoreo = false
        this.restartingMonitoreo = false
      }
    },
    async initMonitoreo(monitor, showPIP = false){
      const self = this
      self.loadingMonitoreo = true
      self.showModal = true
      self.showPIP = showPIP
      self.atencionID = null
      self.monitorActivoUser = null
      self.remoteCloseMonitoreo = false
      if(this.$store.state.showVideo){
        this.userVideo = true
        firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor.deviceID + '/showVideo').set(true)
      }
      const found = this.$store.state.usersInMonitoreo.find((item) => {return item.deviceID === monitor.deviceID})
      if(found){
        firebase.database(this.$store.state.monitoreoDB).ref('Iduam')
          .child('MonitoreoGrid/' + this.cuadrante + '/' + monitor.deviceID)
          .child('startingMonitoreo').set(true)
      }
      if(!self.monitoreo.monitoreoActivo){
        self.monitoreo.monitoreoActivo = monitor
        self.monitoreoActivo = monitor
        //await startMonitoreo(monitor.id_usuario)
        const user = await getDetails(monitor.id_usuario)
        self.monitorActivoUser = user.user
        self.checkSmartband(monitor.id_usuario)
        self.monitoreo.socket.connect()
      }
    },
    changeMonitor(monitor){
      const self = this
      //this.loadingMonitoreo = true
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor.device_id).once('value',function(data){
        if(data.val()){
          self.loadingMonitoreo = true
          self.monitoreo.monitoreoActivo = data.val()
          self.changeRoom()
          self.collapse = false
        }
        else{
          self.toasts.push({
            title: 'No encontrado',
            message: 'Este dispositivo no puede ser activado en este momento.'
          })
        }
      })
    },
    setLocalVideo(remote = false){
      if(this.showPIP){
        if(remote){
          var ViewAuxPIP = document.getElementById("monitor-remote-pip")
          var selfViewLocal = document.createElement('video')
          selfViewLocal.id = "monitor-remote-pip-view"
          selfViewLocal.srcObject = this.remoteStream
          selfViewLocal.autoplay = 'autoplay'
          ViewAuxPIP.appendChild(selfViewLocal)
        }
      }
      else{
        if(remote){
          var ViewAuxRemote = document.getElementById("monitor-remote");
          var selfViewPaciente = document.createElement('video');
          selfViewPaciente.id = "remote-view";
          selfViewPaciente.srcObject = this.remoteStream;
          selfViewPaciente.autoplay = 'autoplay';
          selfViewPaciente.onloadedmetadata = function(e){
              self.conectado = true;
          };          
          ViewAuxRemote.appendChild(selfViewPaciente);
          if(!this.atencionID){
            this.setStartAtencion()
          }
        }
        else{
          var ViewAux = document.getElementById("monitor");
          var selfViewLocal = document.createElement('video');
          selfViewLocal.id = "local-view";
          selfViewLocal.srcObject = this.monitoreo.localStream;
          selfViewLocal.autoplay = 'autoplay';
          ViewAux.appendChild(selfViewLocal);
          selfViewLocal.muted = "muted"
        }
      }
    },
    goPicInPic(){
      this.showPIP = true
      this.setLocalVideo(true)
    },
    async setMedia(roomID = 1, monitor){
      const self = this
      if(!self.monitoreo.localStream || (self.monitoreo.localStream && !self.monitoreo.localStream.active) ){
        self.monitoreo.localStream = await self.getUserMedia(true,true);
        this.setLocalVideo()
      }
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).update({
        remoteActivo: true,
        remoteSocketID: self.monitoreo.socket.id
      })

      let validarConexion = new Promise(function(resolve,reject){
          let auxSetTimeout = setTimeout(() => {
            firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
            resolve(false);
          },10000);

          firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID + '/userActivo').once('value',function(data){
              if(data.val() === true){
                clearTimeout(auxSetTimeout);
                firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
                resolve(true);
              }
          });

          firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).on('child_added',function(data){
              if(data.key == 'userActivo' && data.val() === true){
                clearTimeout(auxSetTimeout);
                firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
                resolve(true);
              }
          });
      });
  
      let aux = await validarConexion;

      if(!aux){
          self.leave();
      }

      let setValueSokectID = new Promise(function(resolve,reject){

          let auxSetTimeout = setTimeout(() => {
            firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
            resolve(null);
          },10000);

          firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID + '/socketID').once('value',function(data){
              if(data.val() !== null){
                clearTimeout(auxSetTimeout);
                resolve(data.val());
              }
                  
          });

          firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).on('child_added',function(data){
              if(data.key == 'socketID' && data.val() !== null ){
                firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).off('child_added');
                clearTimeout(auxSetTimeout);
                resolve(data.val());
              }
          });
      });
      
      let socketID = await setValueSokectID;

      if(socketID === null){
          self.leave();
      }
      else{
        self.join(roomID,socketID,roomID);
        self.monitoreo.roomIDActivo = roomID;
        self.monitoreo.socketIdActivo = socketID;
      }
    },
    getUserMedia(video = true, audio = true){
      return new Promise(function(resolve, reject){
        let setTimeoutMedia = setTimeout(function(){
            reject();
        },40000)
        navigator.getUserMedia = navigator.getUserMedia || navigator.mozGetUserMedia || navigator.webkitGetUserMedia || navigator.msGetUserMedia;

        navigator.getUserMedia ({
          video: {
            width: { min: 426, ideal: 480, max: 1280 },
            height: { min: 240, ideal: 360, max: 720 },
            frameRate: { ideal: 10, max: 15 }
          },
          audio
        },
          function(localMediaStream) {
            clearTimeout(setTimeoutMedia);
            resolve(localMediaStream);
        },
          function(err) {
            alert('El navegador no ha permitido el uso de la cámara y/o micrófono. O es posible que el computador no tenga una cámara conectada. Por favor revisa la configuración de tu navegador para luego continuar con el monitoreo.')
            console.log("Ocurrió el siguiente error: " + err);
        });
      });
    },
    join(roomID,socketIDUser,idUser) {
      const self = this
      this.monitoreo.socket.emit('join', roomID, function(socketIds){
        firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + roomID).update({remoteReady: true});
        for (var i in socketIds) {
          var socketID = socketIds[i];
          if(socketIDUser == socketID){
            self.createPC(socketID, true,roomID);
          }
        }
      });
    },
    leave(close = false, change = true) {
      const self = this
      return new Promise(function(resolve, rejec){
        self.stopRecording()
        self.monitoreo.pcPeers.forEach(pc => {
          if (pc){
            pc.close();
          }
        });
        let video = document.getElementById("remote-view");
        video && video.remove();
        let videoPIP = document.getElementById("monitor-remote-pip-view");
        videoPIP && videoPIP.remove();
        if(close){
          self.showModal = false
          self.monitoreo.localStream.getAudioTracks().forEach((track) => {
              track.enabled = false;
          });
          self.monitoreo.localStream.getVideoTracks().forEach((track) => {
              track.enabled = false;
          });
          self.monitoreo.localStream.getTracks().forEach(function(track) {
            track.stop();
          });
        }
        else{
          self.remoteCloseMonitoreo = true
          self.loadingMonitoreo = false
          self.restartingMonitoreo = !change
        }
        self.monitoreo.socket.disconnect()
        const intervalDisconec = setInterval(function () {
            if(self.monitoreo.socket.disconnected || !self.monitoreo.socket.connect){
                clearInterval(intervalDisconec);
                self.monitoreo.roomIDActivo = null
                self.monitoreo.socketIdActivo = null
                if(close){
                  self.monitoreo.monitoreoActivo = false
                  self.remoteStream = null
                  self.showPIP = false
                }
            }
            resolve(true)
        },1000);
      })
    },
    changeRoom() {
      this.monitoreo.pcPeers.forEach(pc => {
        if (pc){
          pc.close();
        }
      });
      let video = document.getElementById("remote-view");
      video && video.remove();
      let videoPIP = document.getElementById("monitor-remote-pip-view");
      videoPIP && videoPIP.remove();
      this.monitoreo.socket.disconnect()
      this.monitoreo.roomIDActivo = null
      this.monitoreo.socketIdActivo = null
      this.remoteStream = null
      this.monitoreo.socket.connect()
    },
    exchange(data) {
        var fromId = data.from;
        var pc;
        const self = this

        if (fromId in  self.monitoreo.pcPeers) {
            pc = self.monitoreo.pcPeers[fromId];
        }
        else {
            pc = self.createPC(fromId, false);
        }

        if (data.sdp) {
          pc.setRemoteDescription(new RTCSessionDescription(data.sdp),() => {
              pc.createAnswer(function(desc) {
                  pc.setLocalDescription(desc,function () {
                      if(pc){
                        self.monitoreo.socket.emit('exchange', {'to': fromId, 'sdp': pc.localDescription });
                      }
                  },(error) => console.log(error,'error 4'));
              },(error) => console.log(error,'error 5'));
          },(error) => console.log(error,'error 6'));
        }
        else {
          data.candidate !== null && data.candidate && pc.addIceCandidate(new RTCIceCandidate(data.candidate));
        }
    },
    createPC(socketId, isOffer, roomID) {
      const self = this
      var configuration = {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]};
      var pc = new RTCPeerConnection(configuration);
      self.monitoreo.pcPeers[socketId] = pc;

      pc.onicecandidate = function (event) {
          if (event.candidate && event !== null && event.candidate !== null) {
              self.monitoreo.socket.emit('exchange', {'to': socketId, 'candidate': event.candidate });
          }
      };

      function createOffer() {
          pc.createOffer(function(desc) {
              pc.setLocalDescription(desc,function () {
                  self.monitoreo.socket.emit('exchange', {'to': socketId, 'sdp': pc.localDescription });
              },(error) => console.log(error,'error 2'));
          },(error) => console.log(error,'error 3'));
      }

      pc.onnegotiationneeded = function () {
          if (isOffer) {
              createOffer();
          }
      }

      pc.oniceconnectionstatechange = function(event) {
          if (event.target.iceConnectionState === 'connected') {
              createDataChannel();
          } else if(event.target.iceConnectionState === 'checking'){
              createOffer();
          }
      };

      pc.onsignalingstatechange = function(event) {
          if(event.target.connectionState === 'disconnected'){
            self.leave();
          }
      };

      pc.onaddstream = function (event) {
          self.remoteStream = event.stream
          self.setLocalVideo(true)
          self.loadingMonitoreo = false
          self.restartingMonitoreo = false
      };

      pc.addStream(self.monitoreo.localStream);

      function createDataChannel() {
          if (pc.textDataChannel) {
              return;
          }
          var dataChannel = pc.createDataChannel("text");
          dataChannel.onerror = function (error) {
          };
          dataChannel.onmessage = function (event) {
          };
          dataChannel.onopen = function () {
          };
          dataChannel.onclose = function () {
          };

          pc.textDataChannel = dataChannel;
      }

      return pc;
    }
  }
}
</script>