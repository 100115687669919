import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getAlerts(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAlerts,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getAlert(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAlert,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function alertasActivas(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.alertasActivas,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function setAlertInfo(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.setAlertInfo,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function closeAlert(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.closeAlert,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}


export function AlertTest(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.AlertTest,
      method: 'post',
      data
    })
		  .then(function (response) {
				console.log(response)
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
