<template>
  <div>
    <CRow>
        <CCol col="12" md="6">
    <CDataTable hover striped :items="notas" :fields="fields" :items-per-page="5">
      <template #actions="data">
        <div>
          <CButton
            v-c-tooltip="'Video llamada'"
            color="success"
            size="sm"
            class="m-1"
            @click="openModalVideoCall(data.item.user_id)"
          >
            <font-awesome-icon icon="phone" />
          </CButton>
          <CButton
            v-c-tooltip="'Ver Nota'"
            color="info"
            size="sm"
            class="m-1"
            @click="setNote(data.item.atencion_id)"
          >
            <font-awesome-icon icon="edit" />
          </CButton>
          <CButton
            v-c-tooltip="'Borrar nota'"
            color="danger"
            size="sm"
            class="m-1"
            @click="deleteUser(data.item.atencion_id)"
          >
            <font-awesome-icon icon="trash-alt" />
          </CButton>
        </div>
      </template>
    </CDataTable>
        </CCol>

    <CCol col="12" md="6">
    <CRow class="py-4 px-3" :gutters="false" v-show="nota !== {}">
      <CCol col="12">
        <CInput placeholder="Título" v-model="nota.title" />
        <CTextarea
          class="notas-tracking-textarea"
          placeholder="Descripción"
          v-model="nota.description"
        />
        <CAlert
          v-for="(toast, index) in toasts"
          :color="toast.color"
          closeButton
          v-show="savingNote"
        >
          {{ toast.message }}
        </CAlert>
      </CCol>
      <CCol col="12">
        <CButton
          :disabled="savingNote"
          color="success"
          size="sm"
          class="w-100 success d-flex justify-content-around align-items-center py-2"
          style="flex: 1"
          @click="saveNote()"
        >
          <CSpinner color="white" size="sm" v-if="savingNote" />
          Guardar Nota
        </CButton>
      </CCol>
    </CRow>
    </CCol>
  </CRow>
       <CModal
       :parent-modal="true"
      title="Iniciar Video Llamada"
      color="primary"
      :show.sync="modalVideoCall"
      class="modal-sm"
      style="z-index: 9999999999999 !important; position: absolute"
    >
    {{user_is_in_attention ? 'El usuario seleccionado ya está en una videollamada.' : '¿Desea iniciar Video Llamada?'}}
        <div slot="footer" class="w-100" v-if="!user_is_in_attention">
        <CButton color="secondary" square size="sm" @click="close">
          Cancelar
        </CButton>
        <CButton
          class="ml-2"
          color="success"
          square
          size="sm"
          @click="videocall()"
        >
        Aceptar
        </CButton>
      </div>
      <div slot="footer" class="w-100" v-else>

      </div>
    </CModal>
  </div>
</template>

<script>
import {createNote} from '../../../api/notes'
import { checkUserInAttention } from "../../../api/atenciones";
import { ModalVideoCall } from '../../atenciones/ModalVideoCall.vue'

export default {
    components: {ModalVideoCall} ,
    data() {
        return {
        modalVideoCall: false,
        notas: [],
        fields: [
            { key: "title", label: "Título" },
            { key: "actions", label: "Acciones" },
        ],
        nota: {},
        toasts: [],
        savingNote: false,
        user_id: null,
        user_is_in_attention: null
        };
    },
  methods: {
    setNote(atencion_id) {
      this.nota = JSON.parse(JSON.stringify(this.notas.find((nota) => nota.atencion_id == atencion_id)))
    },
    saveNote() {
      const self = this;
      this.toasts = [];
      if (!self.nota ||(self.nota && (self.nota.title == "" || self.nota.description == "")) ) {
        self.toasts.push({
          title: "Error",
          message: "Por favor ingresa título y descripción.",
          color: "danger",
        });
        return;
      }
      self.savingNote = true;
      const data = {...self.nota};
      createNote(data)
        .then(function (resp) {
          self.savingNote = false;
          self.nota = {};
          self.toasts.push({
            title: "Nota guardada",
            message: "La nota se ha guardado correctamente.",
            color: "success",
          });

          self.notas = self.notas.filter((obj) => obj.atencion_id !== data.atencion_id);
          localStorage.setItem("notes", JSON.stringify(this.notas));
        })
        .catch(function (error) {
          self.savingNote = false;
          self.toasts.push({
            title: "Error",
            message:
              "Hubo un error al guardar la información, por favor intenta nuevamente.",
            color: "danger",
          });
        });
    },
    deleteUser(atencion_id) {
        this.notas = this.notas.filter((obj) => obj.atencion_id !== atencion_id);
        localStorage.setItem("notes", JSON.stringify(this.notas));
        this.$store.commit('openModalUnsavedNotes', true)
    },
    videocall() {
    this.$store.commit("setVideoCallData", {
        status: true,
        alertID: null,
        geoUserAlert: null,
        fullView: true,
        deviceID: null,
        calledUserID: this.user_id,
        socketUrl: null,
        socketRoom: null,
        videocallID: null,
        hideVideo: false,
        silent: true
    })
    this.modalVideoCall = false
        },
    openModalVideoCall(user_id) {
      checkUserInAttention({ user_id })
        .then((res) => {
          this.modalVideoCall = true;
          this.user_id = user_id;
          this.user_is_in_attention = res.user_is_in_attention;

          if (res.user_is_in_attention) {
            setTimeout(() => {
                this.close();
            }, 2000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    close() {
      this.modalVideoCall = false;
    },
  },
  mounted() {
    const notes = JSON.parse(localStorage.getItem("notes"));
    this.notas = notes;
  },
};
</script>

<style lang="scss" scoped></style>
