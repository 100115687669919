<template>
<div>
  <CModal
    :show="true"
    class="videocall fullscreen-modal no-header"
  >
  	<template slot="header">Videollamada</template>
  	<template slot="footer"></template>
  	<div class="d-flex flex-column h-100">
	  	<!-- Comienza grilla de videos -->

	  	<div class="videocall-externalcontainer d-flex justify-content-center flex-grow-1">
	  		<VideoCallGrid
				:usersInRoom="usersInRoomState"
	  			:localStream="localStream"
	  			:remoteCalledStream="remoteCalledStream"
	  			:socketRoom="socketRoom"
	  			:remoteStreams="remoteStreams" />
				<IduamConsulta
					:monitorActivoUser="{id: calledUserID}"
					:atencionID="atencionID"
					:deviceID="deviceID"
					:calledRemoteConnected="!!remoteCalledStream"
					:goPicInPicCallback="goPicInPicCallback"
				/>
<!-- 				<NewNoteForm 
					:type="1"
					:userID="calledUserID"
					:deviceID="deviceID"
					:atencionID="atencionID" /> -->
	  	</div>
    	<!-- <video v-if="localStream" autoplay="autoplay" :srcObject.prop="localStream"></video> -->

	  	<!-- Comienza footer -->
			<div id="btns-container" ref="btnsContainer" class="w-100 d-flex align-items-center justify-content-center text-center py-2 px-4">
			<div  class="dropdownn">
			  <div class="dropdownn-content" style="z-index:123;">
  				<CButton
	        		color="primary"
	        		class="bottom-btns-videocall-room d-flex justify-content-center align-items-center"
	        		:class="cameraStatus ? '':'text-white'"
	        		:variant="cameraStatus ? 'outline':''"
	        		style="flex: 1;font-size: 12px;"
	        		@click="toggleCameraStatus()"
	      			>
	        			<font-awesome-icon icon='camera' class="mr-2" style="font-size:16px;"/> {{cameraStatus ? 'Descativar Cámara':'Activaar Cámara'}}
	      			</CButton>
  			</div>
	      <CButton
	        color="primary"
	        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2 mx-1"
	        :class="userVideo ? '':'text-white'"
	        :variant="userVideo ? 'outline':''"
	        style="flex: 1;font-size: 12px;"
	        @click="toggleVideo()"
	      >
	        <font-awesome-icon :icon="userVideo ? 'video-slash':'video'" class="mr-2" style="font-size:16px;"/> {{userVideo ? 'Ocultar Video':'Mostrar Video'}}
	      </CButton>
			</div>
	      <CButton
	        color="primary"
	        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2 mx-1"
	        variant="outline"
	        style="flex: 1;font-size: 12px;"
	        @click="reloadConnection()"
	      >
	        <font-awesome-icon icon="info-circle" class="mr-2" style="font-size:16px;"/> Reiniciar conexión
	      </CButton>
	      <CButton
	        color="primary"
	        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2 mx-1"
	        :class="userLuz ? 'text-white':''"
	        :variant="userLuz ? '':'outline'"
	        style="flex: 1;font-size: 12px;"
	        @click="toogleFlash()"
	      >
	        <font-awesome-icon class="mr-2" icon="lightbulb" style="font-size:16px;"/> Luz
	      </CButton>
	      <CButton
	        color="primary"
	        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2 mx-1"
	        :class="userAlarm ? 'text-white':''"
	        :variant="userAlarm ? '':'outline'"
	        style="flex: 1;font-size: 12px;"
	        @click="toogleAlarm()"
	      >
	        <font-awesome-icon class="mr-2" icon="exclamation-triangle" style="font-size:16px;"/> Alarma
	      </CButton>
	      <CButton
	        color="primary"
	        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2 mx-1"
	        variant="outline"
	        style="flex: 1;font-size: 12px;"
	        @click="changeCamera()"
	      >
	        <font-awesome-icon class="mr-2" icon="camera" style="font-size:16px;"/> Cambiar Cámara
	      </CButton>
	      <CButton
	        color="primary"
	        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2 mx-1"
	        variant="outline"
	        style="flex: 1;font-size: 12px;"
	        @click="goPicInPicCallback()"
	      >
	        <font-awesome-icon class="mr-2" icon="desktop" style="font-size:16px;"/> PIP
	      </CButton>
	      <CButton
	        v-c-tooltip="recording ? 'Detener Grabación':'Grabar Video del Usuario'"
	        color="primary"
	        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2 mx-1"
	        :class="recording ? 'text-white':''"
	        :variant="recording ? '':'outline'"
	        style="flex: 1;font-size: 12px;"
	        @click="toggleRecording()"
	      >
	        <font-awesome-icon class="mr-2"
	          :icon="recording ? 'stop':'circle'"
	          style="font-size:16px;"/> {{recording ? 'Detener':'Grabar'}}
	      </CButton>
	      <CButton
		  	v-if="!finishedVideocall"
	        color="primary"
	        size="sm"
	        class="bottom-btns-videocall-room d-flex flex-grow-1 justify-content-center align-items-center py-0 mx-1"
	        variant="outline"
	        @click="stopVideoCall()">
	        <CIcon name="cilCheckAlt" size="xl" class="icons-svg primary"/>
	        Terminar Monitoreo
	      </CButton>
	      <CButton
		  	v-else
	        color="red"
	        size="sm"
	        class="bottom-btns-videocall-room d-flex flex-grow-1 justify-content-center align-items-center py-0 mx-1"
	        variant="outline"
	        @click="exitVideocall()">
	        <CIcon name="cilCheckAlt" size="xl" class="icons-svg primary"/>
	        Salir
	      </CButton>
			</div>
		</div>
  </CModal>

  		<CModal
		title="Nota sin guardar"
      	color="primary"
      	:show.sync="openModalWarningNote"
      	class="modal-sm"
      	style="z-index: 9999999999999 !important; position: absolute;">
		<p>Hay una Nota sin guardar.</p>
		<div slot="footer" class="w-100">
        <CButton color="secondary" square size="sm" @click="openModalWarningNote = false">
          Cancelar
        </CButton>
        <CButton
          class="ml-2"
          color="success"
          square
          size="sm"
          @click="endVideoCallCallback()"
        >
        Salir de la Videollamada
        </CButton>
      </div>
  		</CModal>
  </div>
</template>

<script>
	import VideoCallGrid from './VideoCallGrid'
	import NewNoteForm from './NewNoteForm'
	import IduamConsulta from '@/views/widgets/IduamConsulta'

	export default {
	  name: 'FullView',
	  components: {VideoCallGrid, NewNoteForm, IduamConsulta},
	  props: {
	  	calledUserID: {
	  		type: Number,
      	required: true
	  	},
	  	 deviceID: {
       	required: false
	  	},
	  	atencionID: {
			type: String,
	  		type: Number
	  	},
	  	localStream: {
      	required: true
	  	},
	  	remoteCalledStream: {
      	required: true
	  	},
	  	remoteStreams: {
	  		type: Array,
      	required: true
	  	},
	  	socket: {
	  		// Se pasa el socket del admin para enviar mensaje al socket
	  		type: Object,
      	required: true
	  	},
	  	endVideoCallCallback: {
	  		type: Function,
	  		required: true
	  	},
	  	goPicInPicCallback: {
	  		type: Function,
	  		required: true
	  	},
	  	usersInRoom: {
	  		type: Object,
	  		required: true
	  	},
	  	socketRoom: {
	  		type: String,
	  		required: true
	  	},
		destroyConnectionWithRemote: {
			type: Function
		}
	  },
	  data(){
	    return {
	    	userVideo: true,
	    	userLuz: false,
	    	userAlarm: false,
	    	recording: false,
	    	usersInRoomState: {},
			finishedVideocall: false,
			openModalWarningNote: false,
			cameraStatus: true
	    }
	  },
	  watch: {
	  	usersInRoom(data){
	  		this.usersInRoomState = data
	  		if( this.remoteCalledStream && data[this.remoteCalledStream.socketID] ){
	  			this.recording = data[this.remoteCalledStream.socketID].recording
	  			this.userVideo = !data[this.remoteCalledStream.socketID].inBackground
	  		}
	  	}
	  },
	  mounted(){
	  	console.log("FullView Mounted")
	  },
	  methods: {
	  	stopVideoCall(){
			this.finishedVideocall = true
			this.destroyConnectionWithRemote(this.remoteCalledStream.socketID)
			this.socket.close()
		
	  	},
		exitVideocall() {
			if (!this.$store.state.unsavedNote) {
				this.endVideoCallCallback()
			} else {
				this.openModalWarningNote = true;
			}
		},
	  	toggleVideo(){
	  		this.userVideo = !this.userVideo
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-ToggleVideo',
	  			to: this.remoteCalledStream.socketID,
	  			status: this.userVideo
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	toggleCameraStatus(){
	  		this.cameraStatus = !this.cameraStatus
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-toggleCameraStatus',
	  			to: this.remoteCalledStream.socketID,
	  			status: this.cameraStatus
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	toogleFlash(){
	  		this.userLuz = !this.userLuz
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-ToggleLight',
	  			to: this.remoteCalledStream.socketID,
	  			status: this.userLuz
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	changeCamera(){
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-ToggleCamera',
	  			to: this.remoteCalledStream.socketID,
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	toogleAlarm(){
	  		this.userAlarm = !this.userAlarm
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-ToggleAlarm',
	  			to: this.remoteCalledStream.socketID,
	  			status: this.userAlarm
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	changeUserAudio(userID, status = false){
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-changeUserAudio',
	  			to: userID,
	  			status
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	reloadConnection(){
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: 'admin-ReloadConnection',
	  			to: this.remoteCalledStream.socketID,
	  			status
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	toggleRecording(){
	  		const message = {
	  			id: 'admin-actionMessage',
	  			action: this.recording ? 'admin-stopRecord':'admin-startRecord',
	  			to: this.remoteCalledStream.socketID,
	  			status
	  		}
	  		this.sendSocketMessage(message)
	  	},
	  	sendSocketMessage(message){
	  		this.socket.emit('message', message)
	  	}
	  }
	}
</script>

<style>
	.dropdownn {
  position: relative;
  display: inline-block;
  z-index: 123123;
}

.dropdownn-content {
  display: none;
  position: absolute;
  bottom: 35px;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdownn:hover .dropdownn-content {
  display: block;
}
</style>