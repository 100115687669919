<template>
	<div style="background: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    height: 100%;
    overflow: hidden;"
    :style="'height: ' + containerHeight + 'px; margin-top: ' + headerHeight + 'px;'"
    v-show="$store.state.grillaMonitoreo">
    <CRow id="remoteViewContainer">
      <CCol col="12" xl="12">
        <h4 class="text-center mb-0">Monitoreo Remoto</h4>
        <h6 class="text-center text-muted mb-0">Se muestran los usuarios que han activado el monitoreo remoto.</h6>
        <h6 class="text-center text-muted"><small>Al iniciar videoconferencia con un usuario se desactivará de esta grilla. Al finalizar volverá a aparecer en tu listado.</small></h6>
      </CCol>
      <CCol col="3" sm="4" xl="3" v-for="(remote,index) in remotos" :key="index">
        <div class="text-center col-video-monitoreo-container m-2">
          <CDropdown
            v-c-tooltip="'Enviar a otro administrador'"
            color="primary"
            class="m-1"
            style="position: absolute; top: 0; right: 0;"
          >
            <template #toggler-content>
              <font-awesome-icon icon="random" style="font-size: 15px" />
            </template>
            <div v-if="adminConnected.length > 0">
              <CDropdownItem v-for="(admin, index) in adminConnected" :key="index" @click="changeAdmin(admin, remote.socketID)">{{admin.adminName}}</CDropdownItem>
            </div>
            <div v-else class="text-muted text-center"><small>No hay usuarios</small></div>
          </CDropdown>
        	<video :ref="remote.socketID" class="monitoreo-grid-element" autoplay="autoplay" :srcObject.prop="remote.url" :muted="remote.muted"></video>
          <CCol class="text-center col-video-controls-monitoreo-container" col="12" xl="12">    
            <CRow>
              <CCol class="text-center text-white d-flex align-items-center" col="6" xl="6">
                <div>
                  <p class="mb-0">{{setUserName(remote.socketID)}}</p>
                </div>
              </CCol>
              <CCol class="text-center d-flex justify-content-end" col="6" xl="6">
                <CButton
                  v-c-tooltip="'Iniciar videollamada'"
                  color="success"
                  size="sm"
                  class="m-1"
                  style="background-color: #42AB0F;"
                  @click="startVideoConferencia(remote.socketID)"
                >
                  <img class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/>
                </CButton>
                <CButton
                  v-c-tooltip="'Cambiar camara'"
                  color="primary"
                  size="sm"
                  class="m-1"
                  style="background-color: #42AB0F;"
                  @click="changeCamera(remote.socketID)"
                >
                  <font-awesome-icon icon="camera" style="font-size: 15px" />
                </CButton>  
                <CButton
                  v-c-tooltip="remote.muted ? 'Activar Sonido':'Desactivar Sonido'"
                  color="primary"
                  size="sm"
                  class="m-1"
                  :style="remote.muted ? 'background-color:#ff5722':'background-color:#42AB0F'"
                  @click="toogleMuted(remote.socketID)"
                >
                  <font-awesome-icon :icon="remote.muted ? 'volume-up':'volume-mute'" style="font-size: 15px" />
                </CButton>   
              </CCol>
            </CRow>
          </CCol>
        </div>
      </CCol>
    </CRow>
	</div>
</template>

<script>
import io from 'socket.io-client'
import * as firebase from 'firebase/app'
import 'firebase/database'

export default {
	name: 'MonitoreoGrid',
	data () {
		return{
      headerHeight: 56,
      containerHeight: 700,
			socket: null,
      monitoreo: {
        socketURL: this.$store.state.userData.info.cuadrante.socket_url,
        localStream: null,
        monitoreoActivo: false,
        socket: null,
        roomIDActivo: null,
        socketIdActivo: null
      },
      usersInMonitoreo: [],
      pcPeers: [],
      remotos: [],
      admin: this.$store.state.userData,
      cuadrante: this.$store.state.userData.info.cuadrante_id,
      adminRoom: 'admin' + this.$store.state.userData.id,
      adminSocketID: null,
      adminChangedListener: null,
      adminAddedListener: null,
      adminConnected: []
    }
	},
	mounted(){
		console.log('socket: ')
    this.listenForAdmins()
    this.headerHeight = document.getElementsByTagName('header')[0].clientHeight
    this.containerHeight = document.getElementsByTagName('main')[0].clientHeight
		const self = this
		console.log('socket: ', this.monitoreo.socketURL)
    this.socket = io(this.monitoreo.socketURL,
      {
        transports: ['websocket'],
        autoConnect: false,
        reconnect: true,
        forceNew: true
      })
    this.socket.on('connect', function(data) {
      self.adminSocketID = self.socket.id
      self.firebaseListeners()
      self.join(self.adminRoom)
    })
		this.socket.on('exchange', function(data){
		  self.exchange(data)
		})
		this.socket.on('leave', function(socketId){
		  self.leave(socketId)
		})
    this.socket.connect()
	},
  methods: {
    listenForAdmins(){
      const self = this
      const path = '/Admins/' + this.cuadrante
      firebase.database(this.$store.state.monitoreoDB).ref('Iduam').child(path).child(this.$store.state.userData.id).set({
        adminName: self.$store.state.userData.name,
        adminRoom: self.adminRoom
      })
      firebase.database(this.$store.state.monitoreoDB).ref('Iduam').child(path).child(this.$store.state.userData.id).onDisconnect().remove()
      const db = firebase.database(this.$store.state.monitoreoDB).ref('Iduam').child(path)
      db.on('child_added',async data => {
        const admin = data.val()
        if(admin.adminRoom !== self.adminRoom){
          self.adminConnected.push(data.val())
        }
      })
      db.on('child_removed',async data => {
        const admin = data.val()
        const index = self.adminConnected.findIndex((item) => {return item.adminRoom === admin.adminRoom})
        if(index !== -1){
          self.adminConnected.splice(index, 1)
        }
      })
    },
    startVideoConferencia(socketID){
      const found = this.$store.state.usersInMonitoreo.find((item) => {return item.socketID === socketID})

      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + found.deviceID).once('value')
        .then((datos) => {
          const monitoreoData = datos.val()
          if(monitoreoData){
            const path = 'MonitoreoGrid/' + this.cuadrante + '/' + found.deviceID
            firebase.database(this.$store.state.monitoreoDB).ref('Iduam').child(path).child('startingMonitoreo').set(true)
						this.$store.commit("setVideoCallData", {
							status: true,
							alertID: null,
							geoUserAlert: null,
							fullView: true,
							deviceID: monitoreoData.deviceID,
							calledUserID: monitoreoData.id_usuario,
							socketUrl: null,
							socketRoom: null,
							videocallID: null,
							hideVideo: false,
							silent: true
						})
          }
        })
    },
    changeCamera(socketID){
      const self = this
      const found = this.$store.state.usersInMonitoreo.find((item) => {return item.socketID === socketID})
      const path = 'MonitoreoGrid/' + self.cuadrante + '/' + found.deviceID
      firebase.database(this.$store.state.monitoreoDB).ref('Iduam').child(path).child('changeCamera').set(true)
    },
    changeAdmin(admin, socketID){
      const self = this
      const found = this.$store.state.usersInMonitoreo.find((item) => {return item.socketID === socketID})
      const path = 'MonitoreoGrid/' + self.cuadrante + '/' + found.deviceID
      firebase.database(this.$store.state.monitoreoDB).ref('Iduam').child(path).child('adminSocketInfo').set({roomID: admin.adminRoom})
    },
    toogleMuted(socketID){
      const self = this
      const video = this.$refs[socketID][0]
      video.muted = !video.muted
      const foundIndex = this.remotos.findIndex((item) => {return item.socketID === socketID})
      if(foundIndex !== -1){
        this.remotos[foundIndex].muted = video.muted
      }
    },
    mutedIcon(socketID){
      const found = this.$store.state.usersInMonitoreo.find((item) => {return item.socketID === socketID})
      if(found && !found.muted){
        return 'volume-up'
      }
      return 'volume-mute'
    },
    firebaseListeners(){
      const path = 'MonitoreoGrid/' + this.cuadrante
      const db = firebase.database(this.$store.state.monitoreoDB).ref('Iduam').child(path)
      this.adminChangedListener = db.on('child_changed',async data => {
        const user = data.val()
        if( !user.toPanel ){ return null }

        // Modificación temporal para efectos de prueba, se verifica que si el id del usuario es el de un superadmin (1, 2 o 3)
        // Si el usuario de la app es superadmin sólo lo ve ese mismo superadmin en el panel
        // A no ser que el superadmin le pase el usuario desde la opción en el panel
        // if(
        //   (user.userID === 1 || user.userID === 2 || user.userID === 3) &&
        //   user.userID !== this.$store.state.userData.id){
        //   return null
        // }

        if(!user.adminSocketInfo ||
          (user.adminSocketInfo &&
          (user.adminSocketInfo.adminSocketID !== this.adminSocketID) &&
          (user.adminSocketInfo.roomID === this.adminRoom)
        )){
          db.child(data.key + '/adminSocketInfo').set({
            roomID: this.adminRoom,
            adminSocketID: this.adminSocketID,
            adminName: this.$store.state.userData.name
          })
        }
        if(user.userID && user.userSocketID){
          this.setUsersInMonitoreo(user, data.key)
        }
      })
      this.adminAddedListener = db.on('child_added',async data => {
/* 				console.log('Data added: ', data) */
        const user = data.val()
/*         console.log("user: ", user) */
        if( !user.toPanel ){ return null }
        // Modificación temporal para efectos de prueba, se verifica que si el id del usuario es el de un superadmin (1, 2 o 3)
        // Si el usuario de la app es superadmin sólo lo ve ese mismo superadmin en el panel
        // A no ser que el superadmin le pase el usuario desde la opción en el panel
        // if(
        //   (user.userID === 1 || user.userID === 2 || user.userID === 3) &&
        //   user.userID !== this.$store.state.userData.id){
        //   return null
        // }

        if(!user.adminSocketInfo ||
          (user.adminSocketInfo &&
          (user.adminSocketInfo.adminSocketID !== this.adminSocketID) &&
          (user.adminSocketInfo.roomID === this.adminRoom)
        )){
          db.child(data.key + '/adminSocketInfo').set({
            roomID: this.adminRoom,
            adminSocketID: this.adminSocketID,
            adminName: this.$store.state.userData.name
          })
        }
        if(user.userID && user.userSocketID){
          this.setUsersInMonitoreo(user, data.key)
        }
      })
    },
    setUserName(socketID){
      const found = this.$store.state.usersInMonitoreo.find((item) => {return item.socketID === socketID})
      return found ? found.userName:''
    },
    setUsersInMonitoreo(user, deviceID){
      const index = this.$store.state.usersInMonitoreo.findIndex((item) => {return item.socketID === user.userSocketID})
      let usersInMonitoreo = this.$store.state.usersInMonitoreo
      if(index != -1){
        usersInMonitoreo[index] = {
          socketID: user.userSocketID,
          userID: user.userID,
          userName: user.userName,
          deviceID,
          muted: false
        }
      }
      else{
        usersInMonitoreo.push({
          socketID: user.userSocketID,
          userID: user.userID,
          userName: user.userName,
          deviceID,
          muted: false
        })
      }
      this.$store.commit('setUsersInMonitoreo', usersInMonitoreo)
    },
		async join(roomID){
	    const self = this
			console.log('joins')
      if(!self.monitoreo.localStream || (self.monitoreo.localStream && !self.monitoreo.localStream.active) ){
        self.monitoreo.localStream = await self.getUserMedia()
      }
	    this.socket.emit('join', roomID, async function (socketIds) {
		    for (var i in socketIds) {
		      var socketId = socketIds[i];
		      self.createPC(socketId, true);
		    }
	    })
		},
		createPC(socketId, isOffer) {
			const self = this
      var configuration = {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]}
      var pc = new RTCPeerConnection(configuration)
      this.pcPeers.push({socketId, pc})
      console.log(this.pcPeers)

		  pc.onicecandidate = function (event) {
		    if (event.candidate) {
		      self.socket.emit('exchange', {'to': socketId, 'candidate': event.candidate, admin: true });
		    }
		  };
		  function createOffer() {
        console.log("createOffer: ", isOffer)
		    pc.createOffer(function(desc) {
		      pc.setLocalDescription(desc, function () {
		        self.socket.emit('exchange', {'to': socketId, 'sdp': pc.localDescription, admin: true });
		      },(error) => console.log(error,'error 2'));
		    },(error) => console.log(error,'error 3'));
		  }
		  pc.onnegotiationneeded = function () {
		    console.log('onnegotiationneeded');
		    if (isOffer) {
		      createOffer();
		    }
		  }
		  pc.onsignalingstatechange = function(event) {
		    console.log('onsignalingstatechange', event);
		  };

		  pc.onaddstream = function (event) {
		    self.remotos.push({ socketID: socketId, url: event.stream, muted: true })
        self.$store.commit('setMonitoreoGrillaCounter', self.remotos.length)
		  };
		  pc.addStream(self.monitoreo.localStream);
		  return pc;
		},
    exchange(data) {
      console.log("exchange: ", data)
			if(data.admin){ return null }
      var fromId = data.from
      var pc
      const self = this
      const found = this.pcPeers.find((item) => {return item.socketId === fromId})
      if (found) {
        pc = found.pc;
      }
      else {
        pc = this.createPC(fromId, false);
      }

      if (data.sdp) {
        console.log("PC: ", pc)
        pc.setRemoteDescription(new RTCSessionDescription(data.sdp),() => {
          pc.createAnswer(function(desc) {
            pc.setLocalDescription(desc,function () {
              console.log("PC: ", pc)
              if(pc){
                self.socket.emit('exchange', {'to': fromId, 'sdp': pc.localDescription, admin: true });
              }
            },(error) => console.log(error,'error 4'));
          },(error) => console.log(error,'error 5'));
        },(error) => console.log(error,'error 6'));
      }
      else {
        data.candidate !== null && data.candidate && pc.addIceCandidate(new RTCIceCandidate(data.candidate));
      }
    },
		leave(socketID) {
      const indexpc = this.pcPeers.findIndex((item) => {return item.socketId === socketID})
		  var pc = this.pcPeers[indexpc].pc
		  pc.close()
		  this.pcPeers.splice(indexpc, 1)		  
		  const index = this.remotos.findIndex((item) => item.socketID === socketID)
		  this.remotos.splice(index, 1)
      const indexUsers = this.$store.state.usersInMonitoreo.findIndex((item) => {return item.socketID === socketID})
      let usersInMonitoreo = this.$store.state.usersInMonitoreo
      usersInMonitoreo.splice(indexUsers, 1)
      this.$store.commit('setMonitoreoGrillaCounter', this.remotos.length)
      this.$store.commit('setUsersInMonitoreo', usersInMonitoreo)
		},
    getUserMedia(){
      return new Promise(function(resolve, reject){
        let setTimeoutMedia = setTimeout(function(){
            reject();
        },40000)
        navigator.getUserMedia = navigator.getUserMedia || navigator.mozGetUserMedia || navigator.webkitGetUserMedia || navigator.msGetUserMedia;
        navigator.getUserMedia ({
          video: true,
          audio: false
        },
          function(localMediaStream) {
            clearTimeout(setTimeoutMedia);
            resolve(localMediaStream);
        },
          function(err) {
            console.log("Ocurrió el siguiente error: " + err);
        });
      });
    }
	}
}
</script>