<template>
  <div v-if="alertData">
    <CModal
      title="Finalizar Alerta"
      color="primary"
      class="modal-alerta small-modal"
      style="max-width: 200px"
      :show.sync="showModalEnd"
    >
      <template #header>
        <p class="mb-0 text-center w-100">Finalizar Alerta</p>
        <CButton
          class="mx-2 p-0"
          @click="closeModal()"
        >
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow class="d-flex justify-content-center align-items-center">
        <CCol lg="12" class="c-bg-success c-py-3">
          <p class="mb-0 text-primary">Estás cerrando la alerta #{{alertData.alert_id}}. Para finalizar debes indicar el estado de cierre.</p>
        </CCol>
        <CCol lg="12" class="c-bg-success c-py-3">
          <CForm>
<!--                 <select v-model="closeAlertData.type">
              <option :value="0">Cerrada en conformidad</option>
              <option :value="1">Cerrada por inactividad</option>
              <option :value="2">Cerrada por nula comunicación</option>
            </select> -->
            <v-select class="select-cierre-tipo my-3" placeholder="Selecciona un estado de cierre" :options="tiposCierre" label="name" :reduce="data => data.id" v-model="closeAlertData.type"></v-select>
            <CTextarea
              class="end-alert-textarea"
              placeholder="Escribe un detalle del cierre"
              v-model='closeAlertData.info'
            >
            </CTextarea>
          </CForm>
        </CCol>
        <CCol lg="12" class="c-bg-success c-py-3">
          <CButton @click="endAlert" color="primary" block>Terminar Alerta</CButton>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="endAlert" color="primary" size="lg">Terminar Alerta</CButton>
        <CButton @click="closeModal" color="primary" size="lg">Salir</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import {closeAlert, setAlertInfo} from '../../api/alerts'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'ModalEndAlert',
  props: ['showModal', 'alertData', 'closeModal'],
  components: {'v-select': vSelect},
  data () {
    return {
    	showModalEnd: false,
      closeAlertData: {
        info: null,
        alert_id: null,
        type: null
      },
      cuadrante: 0,
      tiposCierre: [
        {id: 0, name: 'Cerrada en conformidad'},
        {id: 1, name: 'Cerrada por inactividad'},
        {id: 2, name: 'Cerrada por nula comunicación'}
      ]
    }
  },
  watch: {
    showModal: function(show){
      this.showModalEnd = show
    }
  },
  async mounted(){
    const user = await localStorage.getItem('userData')
    this.admin = JSON.parse(user)
    this.cuadrante = this.admin.info.cuadrante_id
  },
  methods: {
    endAlert(){
      const self = this
      this.closeAlertData.alert_id = this.alertData.alert_id
      closeAlert(this.closeAlertData)
        .then(async function(resp){
          await firebase.database(self.$store.state.primaryDB).ref('/Iduam/Alertas/' + self.cuadrante + '/' + self.alertData.id_usuario).remove()
          await firebase.database(self.$store.state.primaryDB).ref('/Iduam/ButtonAlertas/' + self.cuadrante + '/' + self.alertData.id_usuario).remove()
          self.closeModal(false)
        })
        .catch(function(error){
          console.log("error: ", error)
        })
    }
  }
}
</script>

<style type="text/css" media="screen">
  .select-cierre-tipo .vs__search::placeholder,
  .select-cierre-tipo .vs__dropdown-toggle,
  .select-cierre-tipo .vs__dropdown-menu,
  .select-cierre-tipo .vs__open-indicator{
    color: #d0282e;
    border-color: #d0282e;
  }
</style>