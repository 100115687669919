<template>
	<div>
		<E600 :medicion="medicion" :medicionChecking="medicionChecking"/>
	</div>
</template>

<script>
import E600 from './E600'

export default {
  name: 'Remote',
	components: {E600},
	props: ['medicionSmartband', 'medicion', 'medicionChecking'],
  data(){
    return {
      deviceStatus: 9
    }
  },
  mounted() {
		console.log(this.medicionSmartband)
	},
  methods: {
  }
}
</script>