<template>
  <div class="border-primary mt-2 px-0 rounded">
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
      <div>
        <p class="mb-0 ">Exámenes</p>
      </div>
      <CButton
        color="primary"
        size="sm"
        class="ml-2 my-1"
        @click="toggleIduamCard(2)"
      >
        {{showExamenesBlock ? 'Ocultar':'Mostrar'}} Exámenes
      </CButton>
    </div>
    <div v-show="showExamenesBlock">
      <CRow class="p-3 m-0 w-100 justify-content-center" v-if="monitorActivoUser">
        <CCol col="12" xl="12">
          <CRow class="w-100 justify-content-center m-0">
            <CCol col="3" xl="3" class="px-0">
              <p class="mb-0">Tipo Examen</p>
              <v-select
                :clearable="false"
                :searchable="false"
                class="my-1 w-100"
                placeholder="Seleccione tipo examen"
                :options="tipoExamenes"
                label="name"
                :reduce="data => data.id"
                v-model="examen.tipo">
              </v-select>
            </CCol>
            <CCol col="9" xl="9" >
              <p class="mb-0">Código Examen</p>
              <v-select
                ref="examenselect"
                class="my-1 w-100 custom-v-select-iduam"
                placeholder="Seleccione"
                label="nombre"
                :options="codigoExamenOptions"
                @search="getExamenes"
                @input="selectedExamen">
                <template slot="selected-option" slot-scope="option">
                  {{ option.tipo_examen }}{{ option.sub_grupo }}{{ option.prestacion }} - {{ option.nombre }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.tipo_examen }}{{ option.sub_grupo }}{{ option.prestacion }} - {{ option.nombre }}
                </template>
              </v-select>
            </CCol>
          </CRow>
          <CRow class="w-100 justify-content-center m-0">
            <CCol col="12" xl="12" class="px-0">
                <p class="mb-0 mt-2">Especificación</p>
                <CTextarea
                  placeholder="Especificación"
                  v-model='examen.especificacion'
                  :is-valid="() => {return examen.especificacion && examen.especificacion !== ''}"
                />
            </CCol>
          </CRow>
          <CRow class="w-100 justify-content-center m-0">
            <CCol class="mb-4 mt-0 px-0" col="12" xl="12">
              <CButton class="w-100" size="sm" @click="agregarExamen()" color="primary">
                Agregar Examen
              </CButton> 
            </CCol>
          </CRow>
          <CRow class="w-100 justify-content-center m-0">
            <CCol class="mb-4 mt-0 px-0" col="12" xl="12">
              <CDataTable
                size="sm"
                class="px-3 text-center"
                style="font-size: 12px"
                hover
                striped
                :items="examenes"
                :fields="fields"
                index-column
              >
                <template #action="data">
                  <CButtonClose @click="removeExamen(data.index)"/>
                </template>
                <template #tipo="data">
                  <td>{{filterExamenType(data.item.tipo)}}</td>
                </template>
                <template #examen="data">
                  <td>{{ data.item.examen_obj.tipo_examen }}{{ data.item.examen_obj.sub_grupo }}{{ data.item.examen_obj.prestacion }} - {{ data.item.examen_obj.nombre }}</td>
                </template>
                <template #no-items-view>
                  Al ingresar un examen verás la información aquí
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CCol>
        <CCol class="d-flex" col="12" xl="12">
          <CButton class="w-100" size="sm" @click="ingresaExamen()" color="primary">
            <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../../assets/imgicons/ICONOS SOSRecurso 19@2x.png')"/>
            Ingresar Solicitud de Examen
          </CButton>     
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {getExamenesByQuery, createExamen} from '../../../api/examenes'
import moment from 'moment'

export default {
  name: 'Examenes',
  props: ['atencionID','monitorActivoUser', 'showExamenesBlock', 'toggleIduamCard'],
  components: {'v-select': vSelect},
  data(){
    return {
      fields: [
        { key: 'action', label: '' },
        { key: 'tipo', label: 'Tipo' },
        { key: 'examen', label: 'Examen' },
        { key: 'especificacion', label: 'Especificación' }
      ],
      examenForm: {
        valid_until: moment().add(1, 'M').toISOString(),
        indications: ''
      },
      tipoExamenes: [
        {id: '03', name: 'Laboratorio'},
        {id: '04', name: 'Radiología'},
        {id: '80', name: 'Procedimientos'},
      ],
      examen: {
        tipo: '03',
        examen_id: null,
        examen_obj: null,
        especificacion: ''
      },
      examenes: [],
      toasts: [],
      minDate: moment().toISOString(),
      codigoExamenOptions: []
    }
  },
  methods: {
    filterExamenType(type){
      const found = this.tipoExamenes.find((item) => { return item.id == type })
      return found.name
    },
    async getExamenes(search, loading){
      if(search.length > 4){
        loading(true)
        const examenesQuery = await getExamenesByQuery({search: search, tipo: this.examen.tipo})
        this.codigoExamenOptions = examenesQuery.examenes
        loading(false)
      }
    },
    selectedExamen(examen){
      this.examen = Object.assign({}, this.examen, { examen_id: examen.id, examen_obj: examen })
    },
    agregarExamen(){
      if(this.examen.examen_id && this.examen.especificacion && this.examen.especificacion !== ''){
        this.examenes.push(this.examen)
        this.examen = {
          tipo: '03',
          examen_id: null,
          examen_obj: null,
          especificacion: ''
        }
        this.codigoExamenOptions = []
        this.$refs.examenselect.clearSelection()
      }
      else{        
        this.toasts.push({
          title: 'Error',
          message: 'Por favor verifica que hayas ingresado todos los campos para el examen a agregar.'
        })
      }
    },
    removeExamen(index){
      this.examenes.splice(index, 1)
    },
    validateExamenForm(){
      if( this.examenes.length <= 0 ){
        this.toasts.push({
          title: 'Error',
          message: 'Debe ingresar al menos 1 examen a realizar.'
        })
        return false
      }
      return true
    },
    async ingresaExamen(){
      if( this.validateExamenForm() ){
        const data = {
          examenes: this.examenes,
          atencion_id: this.atencionID,
          user_id: this.monitorActivoUser.id
        }
        console.log(data)
        const examen = await createExamen(data)
        if(examen.code === 200){
          this.toasts.push({
            title: 'Examen creada correctamente',
            message: 'Se ha creado correctamente el examen, hemos notificado al paciente.'
          })
          this.examenes = []
        }
        else{
          this.toasts.push({
            title: 'Error',
            message: 'Hubo un error al crear la examen, por favor intenta nuevamente.'
          })
        }
      }
    }
  }
}
</script>