<template>
	<div v-if="monitores" class="panel-lateral" :class="$store.state.monitorPanelShow ? 'opened':'closed'" :style="'height: ' + containerHeight + 'px; margin-top: ' + headerHeight + 'px;'">
      <div class="inner-panel" v-show="$store.state.monitorPanelShow">
        <CTabs variant="pills" class="custom-monitoreo-tabs">
          <CTab title="Personales" active>
            <template #title>
              <img height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
              Usuarios
            </template>
            <CInput
              v-show="personalesAll.length > 0"
              class="mx-2 mt-2 mb-0"
              placeholder="Buscar por usuario"
              @input="searchPersonales"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
          


            <div v-if="personales.length > 0" class="monitor-container px-3" v-for="(monitor, index) in personales">
              <div class="mb-2 name-user-monitoreo" v-show="index === 0 || (index > 0 && personales[index - 1].id_usuario !== monitor.id_usuario)">
                <div class="icon-monitoreo-user"><font-awesome-icon icon="user"/></div> {{monitor.nombre}}
              </div>
              <div class="row px-1">
                <div class="col-sm-12 d-flex justify-content-start align-items-center" style="color: #DB131A">
                  <div style="position: relative;">
                    <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
                    <div class="active-badge-indicator-monitor"></div>
                  </div>
                  <div>
                    <p class="mb-0">Nombre del Dispositivo</p>
                    <p class="mb-0">{{monitor.deviceData ? monitor.deviceData.device_name : 'Sin Datos'}}</p>
                  </div>
                </div>
                <div class="col-sm-12 d-flex justify-content-center align-items-center" style="color: grey">
                  <div class="mt-3 buttons-container-monitoreo" v-if="monitor.userActivo">
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #42AB0F;"
                      @click="resetMonitor(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="sync" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #095FC1;"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #DB131A;"
                      @click="toogleAlarm(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="volume-up" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="m-1"
                      style="width: 30px; height: 30px;background-color: #8a93a2;"
                      @click="setMapPosition(monitor)"
                    >
                      <font-awesome-icon icon="search-location" />
                    </CButton>
                    <CButton
                      :disabled="monitor.remoteReady"
                      color="primary"
                      size="sm"
                      class="ml-2 my-1"
                      style="flex: 1;"
                      @click="initMonitoreo(monitor)"
                    >
                      <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                    </CButton>
                  </div>
                  <div v-else>
                    <CButton
                      color="secondary"
                      size="sm"
                      class="my-1"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="personales.length <= 0" class="text-center my-5">
              No hay dispositivos activos o en espera
            </div>
          </CTab>
          <CTab title="Monitores">
            <template #title>
              <img height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png')"/>
              Mediciones
            </template>
            <CInput
              v-show="monitoresAll.length > 0"
              class="mx-2 mt-2 mb-0"
              placeholder="Buscar por usuario"
              @input="searchMonitores"
            >
              <template #prepend-content><CIcon name="cil-user"/></template>
            </CInput>
            <!-- <div v-if="testsInProgress"> -->
              
			       <TestInProgressCard style="margin-top:8px;" v-for="(test, index) in tests" :key="index" :testInfo="test" />
 <!-- </div> -->

<!--             <div v-if="monitores.length > 0" class="monitor-container" v-for="(monitor, index) in monitores">
              <div class="mb-2 name-user-monitoreo" v-show="index === 0 || (index > 0 && monitores[index - 1].id_usuario !== monitor.id_usuario)">
                <div class="icon-monitoreo-user"><font-awesome-icon icon="user"/></div> {{monitor.nombre}}
              </div>
              <div class="row px-1">
                <div class="col-sm-12 d-flex justify-content-start align-items-center" style="color: #DB131A">
                  <div style="position: relative;">
                    <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20" :src="require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png')"/>
                    <div class="active-badge-indicator-monitor"></div>
                  </div>
                  <div>
                    <p class="mb-0">Nombre del Dispositivo</p>
                    <p class="mb-0">{{monitor.deviceData ? monitor.deviceData.device_name : 'Sin Datos'}}</p>
                  </div>
                </div>
                <div class="col-sm-12 d-flex justify-content-center align-items-center" style="color: grey">
                  <div class="mt-3 buttons-container-monitoreo" v-if="monitor.userActivo">
                    <CButton
                      color="success"
                      size="sm"
                      class="mr-2 my-1"
                      style="width: 30px; height: 30px;background-color: #42AB0F;"
                      @click="resetMonitor(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="sync" />
                    </CButton>
                    <CButton
                      color="success"
                      size="sm"
                      class="mx-2 my-1"
                      style="width: 30px; height: 30px;background-color: #095FC1;"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                    <CButton
                      color="danger"
                      size="sm"
                      class="mx-2 my-1"
                      style="width: 30px; height: 30px;background-color: #DB131A;"
                      @click="toogleAlarm(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="volume-up" />
                    </CButton>
                    <CButton
                      :disabled="monitor.remoteReady"
                      color="primary"
                      size="sm"
                      class="ml-2 my-1"
                      style="flex: 1;"
                      @click="initMonitoreo(monitor)"
                    >
                      <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Monitorear
                    </CButton>
                  </div>
                  <div v-else>
                    <CButton
                      color="secondary"
                      size="sm"
                      class="my-1"
                      @click="checkOnline(monitor.deviceID)"
                    >
                      <font-awesome-icon icon="link" />
                    </CButton>
                  </div>
                </div>
              </div>
            </div> -->
<!--             <div v-if="monitores.length <= 0" class="text-center my-5">
              No hay monitores activos
            </div> -->
          </CTab>
        </CTabs>
      </div>
      <button type="button" class="btn btn-pill btn-primary custom-btn"
        @click="commitPanelShow()">
        <font-awesome-icon :icon="$store.state.monitorPanelShow ? 'chevron-right':'chevron-left'"/>
      </button>
    </div>
</template>

<script>
	import * as firebase from 'firebase/app'
	import 'firebase/database'
  import {createVideoCallRoom,initVideoCallWithDevice} from '../../api/atenciones'
import TestInProgressCard from '../widgets/TestInProgressCard.vue'

	export default {
	  name: 'LateralBarUsers',
    components: {
      TestInProgressCard
    },
    computed: {
      tests() {
        return this.$store.state.testsInProgress}
    },
    
	  data(){
	    return {
	      monitores: [],
	      personales: [],
	      monitoresAll: [],
	      personalesAll: [],
      	cuadrante: this.$store.state.userData.info.cuadrante_id,
	      headerHeight: 56,
	      containerHeight: 700,
	    }
	  },
	  mounted(){
	    this.headerHeight = document.getElementsByTagName('header')[0].clientHeight
	    this.containerHeight = document.getElementsByTagName('main')[0].clientHeight
	    this.setFirebaseUserListeners()
			setTimeout(() => {
				this.$store.state.monitorPanelShow && this.commitPanelShow()
			}, 1000)
	  },
	  methods: {
	  	async initMonitoreo(monitor){
        this.$store.commit("setVideoCallData", {
          status: true,
          alertID: null,
          geoUserAlert: null,
          fullView: true,
          deviceID: monitor.deviceID,
          calledUserID: monitor.id_usuario,
          socketUrl: null,
          socketRoom: null,
          videocallID: null,
          hideVideo: false,
          silent: true
        })
	  	},
	    commitPanelShow(){
	      this.$store.commit('setPanelShow', !this.$store.state.monitorPanelShow)
	    },
	    toogleAlarm(monitor){
	      const self = this
	      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/startSound').set(!this.userAlarm ? true:null)
	      this.userAlarm = !this.userAlarm
	    },
	    checkOnline(idUser){
	      const self = this
	      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + idUser + '/remoteChecking').set(false)
	      setTimeout(function() {
	        firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + idUser + '/remoteChecking').once('value',function(data){
	            if(data.val() === false){
	              setTimeout(function() {
	                firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante + '/' + idUser).remove()
	              }, 2000);
	            }
	        });
	      }, 3000);
	    },
	    setMapPosition(monitor){
	      const center = {
	        center: {
	          lat: monitor.latitud,
	          lng: monitor.longitud
	        },
	        zoom: 18
	      }
	      this.$store.commit('setMapCenter', center)
	    },
	    resetMonitor(monitor){
	      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/resetMonitoreo').set(true)
	    },
	    searchPersonales(search){
	      this.personales = this.personalesAll.filter(function(item){
	        return item.nombre.toLowerCase().includes(search) || item.nombre.includes(search)
	      })
	    },
	    searchMonitores(search){
	      this.monitores = this.monitoresAll.filter(function(item){
	        return item.nombre.toLowerCase().includes(search) || item.nombre.includes(search)
	      })
	    },
	    setFirebaseUserListeners(){
	      const self = this
	      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_added',function(data){
	        let monitor = data.val()
	        monitor.key = data.key
					console.log('monitos: ', monitor)
	        if(monitor.deviceData && monitor.deviceData.is_monitor){
	          self.monitores.push(monitor)
	          self.monitores.sort((a, b) => a.id_usuario - b.id_usuario)
	        }
	        else{
	          self.personales.push(monitor)
	          self.personales.sort((a, b) => a.id_usuario - b.id_usuario)
	        }
	        self.monitoresAll = self.monitores
	        self.personalesAll = self.personales
	      })
	      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_changed',function(data){
	        const found = self.monitores.findIndex(function(item, index){
	          return item.key === data.key
	        })
	        if(found !== -1){
	          let monitor = data.val()
	          monitor.key = data.key
	          self.monitores[found] = monitor
	          self.monitores.sort((a, b) => a.id_usuario - b.id_usuario)
	        }
	        else{
	          const found = self.personales.findIndex(function(item, index){
	            return item.key === data.key
	          })
	          if(found !== -1){
	            let monitor = data.val()
	            monitor.key = data.key
	            self.personales[found] = monitor
	            self.personales.sort((a, b) => a.id_usuario - b.id_usuario)
	          }
	        }
	        self.monitoresAll = self.monitores
	        self.personalesAll = self.personales
	      })
	      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/').on('child_removed',function(data){
	        const removed = data.val()
	        self.monitores = self.monitores.filter(function(item,index){
	          return item.deviceID !== removed.deviceID
	        })
	        self.personales = self.personales.filter(function(item,index){
	          return item.deviceID !== removed.deviceID
	        })
	        self.monitoresAll = self.monitores
	        self.personalesAll = self.personales
	      })
	    },
	  }
	}
</script>