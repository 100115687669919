import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import './permissions'
import "babel-core/register"
import "babel-polyfill"
import * as VueGoogleMaps from 'vue2-google-maps'
import * as firebase from 'firebase/app'
import 'firebase/database'
import VueEvents from 'vue-events'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCamera, faUser, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faSync, faVolumeUp, faLink, faExclamation,
  faTrashAlt, faUserEdit, faMapMarkedAlt, faPhone, faPlus, faMinus, faListOl, faLightbulb, faPalette, faInfoCircle,
  faAlignLeft, faMobileAlt, faShare, faExpand, faFemale, faBuilding, faHome, faSearchLocation, faBlind, faNotesMedical,
  faRandom, faVolumeMute, faEdit, faVideo, faVideoSlash, faExclamationTriangle, faDesktop, faSlash, faHistory, faStop, faCircle,
	faEye, faHeart,faHeartbeat, faMicrophone, faCog, faMicrophoneSlash, faSearch,
 } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import GmapCluster from "vue2-google-maps/dist/components/cluster"
import store from './helpers/store.js'

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import wysiwyg from "vue-wysiwyg";

Vue.component('datetime', Datetime);

Vue.component('GmapCluster', GmapCluster);

Vue.use(wysiwyg, {
  hideModules: { "image": true },
}); 

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueEvents)
Vue.use(require('vue-moment'))
library.add(
  faCamera, faUser, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faSync, faVolumeUp, faLink, faExclamation,
  faTrashAlt, faUserEdit, faMapMarkedAlt, faPhone, faPlus, faMinus, faListOl, faLightbulb, faPalette, faInfoCircle,
  faAlignLeft, faMobileAlt, faShare, faExpand, faFemale, faBuilding, faHome, faSearchLocation, faBlind, faNotesMedical,
  faRandom, faVolumeMute, faEdit, faVideo, faVideoSlash, faExclamationTriangle, faDesktop, faSlash, faHistory, faStop, faCircle,
	faEye, faHeart,faHeartbeat, faMicrophone, faCog, faMicrophoneSlash, faSearch, 
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const firebaseConfig = {
  apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
  authDomain: "iduam-eebce.firebaseapp.com",
  databaseURL: "https://iduam-eebce.firebaseio.com",
  projectId: "iduam-eebce",
  storageBucket: "iduam-eebce.appspot.com",
  messagingSenderId: "574012982731",
  appId: "1:574012982731:web:230b225497bd9b2e8c624e",
  measurementId: "G-JT1JLJW9SP",
};
firebase.initializeApp(firebaseConfig);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAVx5gJCh9MATmWOFIXOONY-YCc9R3yPlg',
  },
  installComponents: true
})

import { CChartLine } from '@coreui/vue-chartjs'
Vue.component('CChartLine', CChartLine)

new Vue({
  el: '#app',
  router,
  icons,
  store,
  template: '<App/>',
  components: {
    App
  },
})
