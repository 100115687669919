<template>
			<div class="info-content">
                <CRow> 
                    <CCol style="padding: 0 !important;" col="12" xl="12">
                    <div class="user-image-container">
							<CImg
								class="mb-1 user-image"
								v-if="this.testInfo.userData && this.testInfo.userData.info && this.testInfo.userData.info.avatar"
								:src="this.testInfo.userData.info.avatar"
							/>
							<div class="mb-1 user-image icon" v-else><font-awesome-icon icon="user"/></div>
							<div class="user-name">{{this.testInfo.userData.name}}</div>
						</div>
                    </CCol>
                </CRow>
				<CRow>
					<CCol col="12" xl="12" class="d-flex flex-row align-items-stretch">
						<div class="p-1 d-flex flex-column w-100">
							<div class="d-flex flex-column justify-content-between align-items-stretch" style="height: 100%;">
								<CContainer class="h-100">
									<CRow class="h-100">
										<CCol col="4" xl="4" class="py-0 px-0">
											<div class="test-content w-100 h-100">
												<CImg
													width="50"
													class="test-icon-container"
													:src="require('@/assets/imgs/saturacion.png')"
												/>
												<div class="px-1 text-center test-content-value">
													{{bloodOxygen}}
													<div class="test-content-unit big-text">%</div>
												</div>
												<div class="p-1 text-center test-content-value-reference" :class="bloodOxygenReference.status">
													{{bloodOxygenReference.text}}
													<font-awesome-icon icon="info-circle" v-c-tooltip="{content: bloodOxygenReference.tooltipContent, html: true, appendToBody: true, placement: 'left'}" />
												</div>
											</div>
										</CCol>
										<CCol col="4" xl="4" class="py-0 px-1">
											<div class="test-content w-100 h-100">
												<CImg
													width="50"
													class="test-icon-container"
													:src="require('@/assets/imgs/heart-rate.png')"
												/>
												<div class="px-1 text-center test-content-value">
													{{bloodPressure}}
													<div class="test-content-unit">mmHg</div>
												</div>
												<div class="p-1 text-center test-content-value-reference" :class="bloodPressureReference.status">
													{{bloodPressureReference.text}}
													<font-awesome-icon icon="info-circle" v-c-tooltip="{content: bloodPressureReference.tooltipContent, html: true, appendToBody: true, placement: 'bottom'}" />
												</div>
											</div>
										</CCol>
										<CCol col="4" xl="4" class="py-0 px-0">
											<div class="test-content w-100 h-100">
												<CImg
													width="50"
													class="test-icon-container"
													:src="require('@/assets/imgs/bp-icon.png')"
												/>
												<div class="px-1 text-center test-content-value">
													{{heartRate}}
													<div class="test-content-unit">min</div>
												</div>
												<div class="p-1 text-center test-content-value-reference" :class="heartRateReference.status">
													{{heartRateReference.text}}
													<font-awesome-icon icon="info-circle" v-c-tooltip="{content: heartRateReference.tooltipContent, html: true, appendToBody: true, placement: 'right'}" />
												</div>
											</div>
										</CCol>
									</CRow>
								</CContainer>
								<CButtonGroup class="mt-1">
									<CButton
										color="transparent"
										size="sm"
										variant="ghost"
										class="w-100 p-0 test-content-button"
										@click="startVideocall"
										v-c-tooltip="{
											content: this.testInfo.medicionData && this.testInfo.medicionData.inProgress ? 'Por favor espera que termine la medición para no interrumpirla.':'Llama al usuario para iniciar una videoollamada por la aplicación.',
											html: true, appendToBody: true, placement: 'left'}"
									>
										<div class="test-content-button-inner" :style="progressStyle">
											{{this.testInfo.medicionData && this.testInfo.medicionData.inProgress ? 'Medición en Progreso':'Videollamada'}}
										</div>
									</CButton>
									<CButton
										color="danger"
										size="sm"
										style="font-weight: bold"
										class="py-0"
										v-c-tooltip="{content: 'Esto eliminará la medición del panel, si quieres mantenerla para abrirla luego simplemente haz click en el marcador del mapa.', html: true, appendToBody: true, placement: 'right'}"
										@click="removeTestFromPanel">
										X
									</CButton>
								</CButtonGroup>
							</div>
						</div>
					</CCol>
				</CRow>
			</div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'

export default {
	name: 'TestInProgressCard',
	props: {
		testInfo: {
      type: Object,
      required: true
		},
	},
	computed: {
		location(){
			return this.testInfo.location
		},
		progressStyle(){
			if(!this.testInfo.medicionData){ return {} }
			if(!this.testInfo.medicionData.inProgress){ return {background: 'green'} }
			const style = {background: 'linear-gradient(90deg, green ' + this.testInfo.medicionData.progress + '%, transparent 0%)'}
			return style
		},
		bloodOxygen(){
			if(!this.testInfo.medicionData || !this.testInfo.medicionData.bloodOxygen){ return '--' }
			return this.testInfo.medicionData.bloodOxygen
		},
		bloodOxygenReference(){
			if(!this.testInfo.medicionData || !this.testInfo.medicionData.bloodOxygen){ return { text: '--', status: '', tooltipContent: 'Sin información' } }
			if(this.testInfo.medicionData.bloodOxygen < 97){
				return {
					text: 'Alerta',
					status: 'warning',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Saturación de Oxígeno</b><br/>La medición está por debajo del rango normal (Sobre 95%)</div>'
				}
			}
			if(this.testInfo.medicionData.bloodOxygen < 92){
				return {
					text: 'Crítico',
					status: 'danger',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Saturación de Oxígeno</b><br/>La medición está muy por debajo del rango normal (Sobre 95%)</div>'
				}
			}
			return {
				text: 'Normal',
				status: 'normal',
				tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Saturación de Oxígeno</b><br/>La medición dentro del rango normal (Sobre 95%)</div>'
			}
		},
		bloodPressure(){
			if(!this.testInfo.medicionData || !this.testInfo.medicionData.bloodPressure){ return '--' }
			return this.testInfo.medicionData.bloodPressure.sistolic + '/' + this.testInfo.medicionData.bloodPressure.diastolic
		},
		bloodPressureReference(){
			if(!this.testInfo.userData || !this.testInfo.userData.references){ return { text: '--', status: '', tooltipContent: 'Sin información' } }
			if(!this.testInfo.medicionData || !this.testInfo.medicionData.bloodPressure){ return { text: '--', status: '', tooltipContent: 'Sin información' } }

			if(
				this.testInfo.medicionData.bloodPressure.diastolic > this.testInfo.userData.references.max_diastolica*1.2 ||
				this.testInfo.medicionData.bloodPressure.diastolic < this.testInfo.userData.references.min_diastolica*0.8 ||
				this.testInfo.medicionData.bloodPressure.sistolic > this.testInfo.userData.references.max_sistolica*1.2 ||
				this.testInfo.medicionData.bloodPressure.sistolic < this.testInfo.userData.references.min_sistolica*0.8
			){
				return {
					text: 'Crítico',
					status: 'danger',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Presión Arterial</b><br/>La medición está muy alejada de los rangos de referencia del usuario:<br/>' +
													'Mínimo: <b>' + this.testInfo.userData.references.min_diastolica + '/' + this.testInfo.userData.references.min_sistolica +
													'</b> - Máximo: <b>' + this.testInfo.userData.references.max_diastolica + '/' + this.testInfo.userData.references.max_sistolica +
													'</b></div>'
				}
			}
			if(
				this.testInfo.medicionData.bloodPressure.diastolic > this.testInfo.userData.references.max_diastolica*1.05 ||
				this.testInfo.medicionData.bloodPressure.diastolic < this.testInfo.userData.references.min_diastolica*0.95 ||
				this.testInfo.medicionData.bloodPressure.sistolic > this.testInfo.userData.references.max_sistolica*1.05 ||
				this.testInfo.medicionData.bloodPressure.sistolic < this.testInfo.userData.references.min_sistolica*0.95
			){
				return {
					text: 'Alerta',
					status: 'warning',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Presión Arterial</b><br/>La medición está fuera de los rangos de referencia del usuario:<br/>' +
													'Mínimo: <b>' + this.testInfo.userData.references.min_diastolica + '/' + this.testInfo.userData.references.min_sistolica +
													'</b> - Máximo: <b>' + this.testInfo.userData.references.max_diastolica + '/' + this.testInfo.userData.references.max_sistolica +
													'</b></div>'
				}
			}
			return {
					text: 'Normal',
					status: 'normal',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Presión Arterial</b><br/>La medición está dentro de los rangos de referencia del usuario:<br/>' +
													'Mínimo: <b>' + this.testInfo.userData.references.min_diastolica + '/' + this.testInfo.userData.references.min_sistolica +
													'</b> - Máximo: <b>' + this.testInfo.userData.references.max_diastolica + '/' + this.testInfo.userData.references.max_sistolica +
													'</b></div>'
				}
		},
		heartRate(){
			if(!this.testInfo.medicionData || !this.testInfo.medicionData.heartRate){ return '--' }
			return this.testInfo.medicionData.heartRate
		},
		heartRateReference(){
			if(!this.testInfo.userData || !this.testInfo.userData.references){ return { text: '--', status: '', tooltipContent: 'Sin información' } }
			if(!this.testInfo.medicionData || !this.testInfo.medicionData.heartRate){ return { text: '--', status: '', tooltipContent: 'Sin información' } }
			if(
				this.testInfo.medicionData.heartRate > this.testInfo.userData.references.max_ritmo*1.3 ||
				this.testInfo.medicionData.heartRate < this.testInfo.userData.references.min_ritmo*0.7
			){
				return {
					text: 'Crítico',
					status: 'danger',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Ritmo Cardíaco</b><br/>La medición está muy alejada de los rangos de referencia del usuario:<br/>' + 'Mínimo: <b>' + this.testInfo.userData.references.min_ritmo + '</b> - Máximo: <b>' + this.testInfo.userData.references.max_ritmo + '</b></div>'
				}
			}
			if(
				this.testInfo.medicionData.heartRate > this.testInfo.userData.references.max_ritmo*1.1 ||
				this.testInfo.medicionData.heartRate < this.testInfo.userData.references.min_ritmo*0.9
			){
				return {
					text: 'Alerta',
					status: 'warning',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Ritmo Cardíaco</b><br/>La medición está fuera de los rangos de referencia del usuario:<br/>' + 'Mínimo: <b>' + this.testInfo.userData.references.min_ritmo + '</b> - Máximo: <b>' + this.testInfo.userData.references.max_ritmo + '</b></div>'
				}
			}
			return {
					text: 'Normal',
					status: 'normal',
					tooltipContent: '<div class="tooltip-content-test-in-progress"><b>Ritmo Cardíaco</b><br/>La medición está dentro de los rangos de referencia del usuario:<br/>' + 'Mínimo: <b>' + this.testInfo.userData.references.min_ritmo + '</b> - Máximo: <b>' + this.testInfo.userData.references.max_ritmo + '</b></div>'
				}
		},
	},
  data: () => {
    return {
			showInfo: false
    }
  },
	mounted(){
	},
	methods: {
		startVideocall(){
      this.$store.commit("setVideoCallData", {
        status: true,
        alertID: null,
        geoUserAlert: null,
        fullView: true,
        deviceID: this.testInfo.test_id,
        calledUserID: this.testInfo.userData.id,
        socketUrl: null,
        socketRoom: null,
        videocallID: null,
        hideVideo: false,
        silent: true
      })
		},
		removeTestFromPanel(){
			firebase.database(this.$store.state.primaryDB).ref('/IduamTests/' + this.$store.state.userData.info.cuadrante_id + '/testInProgress/' + this.testInfo.test_id).remove()
		},
		onClick(){
			console.log('onClick')
			this.showInfo = !this.showInfo
		},
		onHover(){
			console.log('onHover')
		}
	}
}
</script>

<style scoped>
	.info-content{
		height: 100px;
		width: 270px;
        margin: 0 auto;
        height: 100%;
	}
	.user-image-container{
        padding-top: 8px;
		background: #eaeaea;
		position: relative;
        display: flex;
        align-items: center;
        border-radius: 12px;
	}
	.user-image-container .user-image{
		object-fit: cover;
		width: 100px;
		height: 70px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
	.user-image-container .user-image.icon{
		font-size: 25px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #0064ed;
	}
	.user-image-container .user-name{
    z-index: 99;
    width: 100%;
		padding: 2px 5px;
		font-size: 12px;
		font-weight: bold;
	}
	.test-header{
		font-size: 10px;
		font-weight: bold;
		text-align: center;
		background-color: #0064ed;
		color: white;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.test-content{
		background-color: #0064ed;
		border-radius: 5px;
		overflow: hidden;
		min-height: 50px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.test-content-image{
		background-color: #0064ed;
	}
	.test-icon-container{
		position: absolute;
		opacity: 0.08;
		z-index: 1;
	}
	.test-content-value{
		display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
		font-weight: bold;
		font-size: 12px;
		color: white;
		z-index: 2;
		position: relative;
	}
	.test-content-value-reference{
		border-top: 1px solid rgba(255, 255, 255, 0.5);
		color: white;
		font-weight: bold;
		font-size: 11px;
	}
	.test-content-value-reference-title{
		font-size: 10px;
		font-weight: bold;
		color: white;
	}
	.test-content-unit{
		position: absolute;
		bottom: 0;
		right: 5px;
		font-weight: bold;
		opacity: 0.5;
		z-index: 1;
	}
	.test-content-unit.big-text{font-size: 15px;}
	.test-content-button{
		border: 1px solid green;
		border-radius: 5px;
		background: rgb(172, 172, 172);
		font-weight: bold;
		color: white;
	}
	.test-content-button-inner{
		padding: 2px;
	}
	.warning{ background-color: rgba(255, 166, 0, 1); }
	.danger{ background-color: rgb(206, 2, 2); }
	.normal{ background-color: rgba(0, 128, 0, 1);}
	.tooltip-old-inner{ background-color: rgba(255, 166, 0, 1); }
</style>
<style>
	/* .tooltip-content-test-in-progress{
		background-color: red;
		min-width: 400px;
	} */
</style>