<template>
  <div class="px-4 py-2" style="min-width: 40%">
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <div class="border-primary mt-2 px-0 rounded">
      <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
        <div>
          <p class="mb-0 ">Medición en línea <b v-if="medicionChecking">({{medicion.progress}}%)</b></p>
          <small class="mb-0 " v-if="monitorActivoUser && medicionSmartband">
            Smartband conectada: <b>{{medicionSmartband.name}}</b>
          </small>
        </div>
        <CButton
          color="primary"
          size="sm"
          class="ml-2 my-1"
          @click="toggleIduamCard(0)"
        >
          {{showMedicionBlock ? 'Ocultar':'Mostrar'}} Medición
        </CButton>
      </div>
      <div v-show="showMedicionBlock">
        <div class="d-flex justify-content-center align-items-center p-2" v-if="medicion.loadingReport">
          <CSpinner
            style="width:20px;height:20px;"
            color="primary"
          />
          <p class="my-0 mx-1">Cargando Reporte ECG</p>
        </div>
				<div style="position: relative" v-else-if="medicion.errorReport">
					<CAlert color="primary" class="d-flex align-items-center" closeButton :show.sync="medicion.errorReport"
						style="position: absolute;z-index: 999;">
						<svg class="flex-shrink-0 me-2" width="24" height="24" viewBox="0 0 512 512">
							<rect width="32" height="176" x="240" y="176" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect><rect width="32" height="32" x="240" y="384" fill="var(--ci-primary-color, currentColor)" class="ci-primary"></rect><path fill="var(--ci-primary-color, currentColor)" d="M274.014,16H237.986L16,445.174V496H496V445.174ZM464,464H48V452.959L256,50.826,464,452.959Z" class="ci-primary"></path>
						</svg>
						<div>
							<p class="my-0 mx-1 font-weight-bold">Lo sentimos, no fue posible cargar el reporte.</p>
							<p class="my-0 mx-1" style="font-size:12px;">Esto puede deberse a que no se haya obtenido la cantidad necesaria de datos en el electrocardiograma, posiblemente porque el usuario no tocó el electrodo de la smartband.</p>
						</div>
					</CAlert>
				</div>
        <div v-if="medicionChecking" style="height: 7px;" class="d-flex justify-content-between align-items-center bg-primary text-white">
          <div :style="'height: 7px;background-color: #4caf50;' + 'width:' + medicion.progress + '%;'" class="d-flex justify-content-between align-items-center text-white">
          </div>
        </div>
        <CRow class="p-3 w-100 justify-content-center">
          <CCol class="d-flex" col="12" xl="12" v-if="monitorActivoUser">
            <CRow class="p-3 w-100 justify-content-center" v-if="medicionSmartband">
							<MedicionRemota :medicionSmartband="medicionSmartband" :medicionChecking="medicionChecking" :medicion="medicion" />
              <CCol class="d-flex justify-content-center text-center" col="12" xl="12" v-if="monitorActivoUser && calledRemoteConnected">
                <CButton size="sm" @click="startMedicion(monitorActivoUser.id)" color="primary" v-if="!medicionChecking">
                  <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 19@2x.png')"/>
                  Solicitar Medición en Línea
                </CButton>
                <CButton size="sm" @click="stopMedicion(monitorActivoUser.id)" color="primary" v-if="medicionChecking">
                  <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 19@2x.png')"/>
                  Cancelar medición en curso
                </CButton>
              </CCol>
            </CRow>
            <p class="text-center" v-else>No hemos recibido información sobre la Smartband conectada del paciente, por favor solicita que la conecte antes de realizar una medición remota.</p>              
          </CCol>
        </CRow>
      </div>
    </div>
    <div class="border-primary mt-2 px-0 rounded">
      <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
        <div>
          <p class="mb-0 ">Recetas Médicas</p>
        </div>
        <CButton
          color="primary"
          size="sm"
          class="ml-2 my-1"
          @click="toggleIduamCard(1)"
        >
          {{showRecetaBlock ? 'Ocultar':'Mostrar'}} Recetas
        </CButton>
      </div>
      <div v-show="showRecetaBlock">
        <CRow class="p-3 m-0 w-100 justify-content-center" v-if="monitorActivoUser">
          <CCol col="12" xl="12">
            <CRow class="w-100 justify-content-center m-0">
              <CCol col="7" xl="7" class="px-0">
                <p class="mb-0">Escriba para buscar fármaco</p>
                <p class="mb-0 text-muted"><small>Puedes escribirlo y seleccionarlo directamente si no lo encuentras</small></p>
                <div style="position: relative;">
                  <v-select
                    ref="farmacoselect"
                    taggable
                    class="my-1 w-100"
                    placeholder="Seleccione fármaco a recetar"
                    :options="farmacosOptions"
                    label="descripcion"
                    @search="getFarmacos"
                    @input="selectedFarmaco">
                    </v-select>
                  </div>
              </CCol>
              <CCol col="5" xl="5" >
                <p class="mb-0">Indicación</p>
                <p class="mb-0 text-muted"><small>Agrega una breve indicación para este fármaco</small></p>
                <CInput
                  class="my-1 w-100"
                  placeholder="Ej: 1 comprimido cada 3 hrs. por 4 días"
                  v-model="farmaco.indicacion"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <CRow class="w-100 justify-content-center m-0 px-3">
          <CCol class="mb-4 mt-0 px-0" col="12" xl="12">
            <CButton class="w-100" size="sm" @click="agregarFarmaco()" color="primary">
              Agregar Fármaco
            </CButton> 
          </CCol>
        </CRow>
        <CRow class="w-100 justify-content-center m-0">
          <CCol class="mb-4 mt-0 px-0" col="12" xl="12">
            <CDataTable
              size="sm"
              class="px-3 text-center"
              style="font-size: 12px"
              hover
              striped
              :items="farmacos"
              :fields="fields"
              index-column
            >
              <template #action="data">
                <CButtonClose @click="removeFarmaco(data.index)"/>
              </template>
              <template #no-items-view>
                Al ingresar un fármaco verás la información aquí
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <CRow class="w-100 justify-content-center m-0 mb-2">
          <CCol class="d-flex align-items-center border-top border-bottom py-2" col="12" xl="12" >
            <div>
              <p class="mb-0">Válida hasta</p>
              <p class="mb-0 text-muted"><small>Fecha referencial hasta cuando es válida esta receta</small></p>
            </div>
            <datetime 
              width="100%"
              class="my-2 mx-4 datepicker-iduam"
              type="date"
              :min-datetime="minDate"
              v-model="recetaForm.valid_until"></datetime>
          </CCol>          
          <CCol col="12" xl="12" >
            <p class="mb-0 mt-2">Ingrese indicaciones/observaciones generales para el paciente</p>
            <p class="mb-0 text-muted"><small>Puedes ingresar el diagnóstico, observaciones o lo que necesites.</small></p>
            <CTextarea
              placeholder="Indicaciones (Diágnostico, observaciones, etc.)"
              v-model='recetaForm.indications'
              :is-valid="() => {return recetaForm.indications && recetaForm.indications !== ''}"
            /> 
          </CCol>
          <CCol class="d-flex" col="12" xl="12">
            <CButton class="w-100" size="sm" @click="ingresaReceta()" color="primary">
              <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 19@2x.png')"/>
              Ingresar Receta
            </CButton>     
          </CCol>
        </CRow>
      </div>
    </div>

    <Examenes
      :atencionID="atencionID"
      :monitorActivoUser="monitorActivoUser"
      :toggleIduamCard="toggleIduamCard"
      :showExamenesBlock="showExamenesBlock"/>

    <NewNoteForm2
      :atencionID="atencionID"
			:type="1"
			:userID="monitorActivoUser.id"
			:deviceID="deviceID"
      :toggleIduamCard="toggleIduamCard"
      :showMessageBlock="showMessageBlock"/>
      
    <Antecedentes
      :goPicInPicCallback="goPicInPicCallback"
      :atencionID="atencionID"
			:userID="monitorActivoUser.id"
      :toggleIduamCard="toggleIduamCard"
      :showAntecedentesBlock="showAntecedentesBlock"/>
  </div>

</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {getFarmacosByQuery, saveReceta} from '../../api/recetas'
import moment from 'moment'
import * as firebase from 'firebase/app'
import 'firebase/database'
import MedicionRemota from './MedicionRemota'

import Examenes from './IduamConsulta/Examenes'
import Antecedentes from './IduamConsulta/Antecedentes'
import NewNoteForm2 from '../videocall/NewNoteForm2'

export default {
  name: 'IduamConsulta', 
  props: ['atencionID','monitorActivoUser', 'calledRemoteConnected', 'deviceID', 'goPicInPicCallback'], 
  components: {'v-select': vSelect, Examenes, MedicionRemota, NewNoteForm2, Antecedentes},
  data(){
    return {
      showMedicionBlock: true,
      showRecetaBlock: false,
      showExamenesBlock: false,
      showMessageBlock: false,
      showAntecedentesBlock: false,
      medicion: {},
      medicionChecking: false,
      medicionSmartband: null,
      fields: [
        { key: 'action', label: '' },
        { key: 'farmaco', label: 'Fármaco' },
        { key: 'indicacion', label: 'Indicación' }
      ],
      recetaForm: {
        valid_until: moment().add(1, 'M').toISOString(),
        indications: ''
      },
      tipoExamenes: {

      },
      farmaco: {
        farmaco_id: null,
        farmaco: '',
        indicacion: ''
      },
      farmacos: [],
      toasts: [],
      minDate: moment().toISOString(),
      farmacosOptions: [],
    }
  },
	mounted(){
		if(this.monitorActivoUser){
			this.checkSmartband(this.monitorActivoUser.id)
		}
	},
  methods: {
    startMedicion(userID){
      const self = this
      this.medicion = {}
      this.medicionChecking = true
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).set({
        requestMedicion: true
      })
    },
    stopMedicion(userID, close = false){
      const self = this
      this.medicion = {}
      this.medicionChecking = false
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).set({
        stopMedicion: close
      })
      if(close){
        setTimeout(function() {
          firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).remove()
        }, 1000);
      }
    },
    async getFarmacos(search, loading){
      if(search.length > 4){
        loading(true)
        let include_ids = this.farmacos.filter((item) => {return item.farmaco_id}).map((item) => {return item.farmaco_id})
        const farmacosQuery = await getFarmacosByQuery({descripcion: search, include: include_ids})
        this.farmacosOptions = farmacosQuery.farmacos
        loading(false)
      }
    },
    agregarFarmaco(){
      if(this.farmaco.farmaco && this.farmaco.indicacion && this.farmaco.indicacion !== ''){
        this.farmacos.push(this.farmaco)
        this.farmaco = {
          farmaco_id: null,
          farmaco: '',
          indicacion: ''
        }
        this.farmacosOptions = []
        this.$refs.farmacoselect.clearSelection()
      }
      else{        
        this.toasts.push({
          title: 'Error',
          message: 'Por favor verifica que hayas ingresado todos los campos para el fármaco a agregar.'
        })
      }
    },
    selectedFarmaco(farmaco){
      this.farmaco = Object.assign({}, this.farmaco,
        {
          farmaco_id: farmaco.id ? farmaco.id : null,
          farmaco: farmaco.descripcion ? farmaco.descripcion : farmaco
        }
      )
    },
    removeFarmaco(index){
      this.farmacos.splice(index, 1)
    },
    toggleIduamCard(index){
      this.showMedicionBlock = index === 0 ? !this.showMedicionBlock : false
      this.showRecetaBlock = index === 1 ? !this.showRecetaBlock : false
      this.showExamenesBlock = index === 2 ? !this.showExamenesBlock : false
      this.showMessageBlock = index === 3 ? !this.showMessageBlock : false
      this.showAntecedentesBlock = index === 4 ? !this.showAntecedentesBlock : false
    },
    validateRecetaForm(){
      const validFarmacos = this.farmacos.every((item) => {
        return item.indicacion && item.indicacion !== ''
      })
      if( !validFarmacos ){
        this.toasts.push({
          title: 'Error',
          message: 'Por favor verifica que hayas seleccionado todos los fármacos y agregado una breve indicación a cada uno.'
        })
        return false
      }
      if(!this.recetaForm.indications || this.recetaForm.indications === ''){
        this.toasts.push({
          title: 'Error',
          message: 'Por favor agrega una indicacion general para el paciente.'
        })
        return false
      }
      return true
    },
    async ingresaReceta(){
      if( this.validateRecetaForm() ){
        const data = {
          valid_until: this.recetaForm.valid_until,
          indications: this.recetaForm.indications,
          farmacos: this.farmacos,
          atencion_id: this.atencionID,
          user_id: this.monitorActivoUser.id
        }
        const receta = await saveReceta(data)
        if(receta.code === 200){
          this.toasts.push({
            title: 'Receta creada correctamente',
            message: 'Se ha creado correctamente la receta, hemos notificado al paciente.'
          })
          this.recetaForm = {valid_until: moment().add(1, 'M').toISOString(),indications: ''}
          this.farmacos = []
        }
        else{
          this.toasts.push({
            title: 'Error',
            message: 'Hubo un error al crear la receta, por favor intenta nuevamente.'
          })
        }
      }
    },
    async checkSmartband(userID){
      const self = this
      // await firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).remove()
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).on('child_added',function(data){
        if(data.key === "smartband"){
          self.medicionSmartband = data.val()
        }
        if(data.key === "endTest" && data.val()){
          self.medicionChecking = false
        }
        if(data.key === "temperature" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { temperature: data.val() })
        }
        if(data.key === "presion" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { presion: data.val() })
        }
        if(data.key === "heart" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { heart: data.val() })
        }
        if(data.key === "oxigen" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { oxigen: data.val() })
        }
        if(data.key === "ecg" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { ecg: data.val() })
        }
        if(data.key === "progress" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { progress: data.val() })
        }
        if(data.key === "report"){
					if(data.val()){
          	self.$store.commit('setReportECGData', data.val())
					}
					else{
						self.medicion = Object.assign({}, self.medicion, { errorReport: true })
						setTimeout(() => {
							self.medicion = Object.assign({}, self.medicion, { errorReport: null })
						}, 10000)
					}
        }
        if(data.key === "loadingReport"){
          self.medicion = Object.assign({}, self.medicion, { loadingReport: data.val() })
        }
      })    
      firebase.database(this.$store.state.primaryDB).ref('Iduam/Mediciones/' + userID).on('child_changed',function(data){
        if(data.key === "smartband"){
          self.medicionSmartband = data.val()
        }
        if(data.key === "endTest" && data.val()){
          self.medicionChecking = false
        }
        if(data.key === "temperature" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { temperature: data.val() })
        }
        if(data.key === "presion" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { presion: data.val() })
        }
        if(data.key === "heart" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { heart: data.val() })
        }
        if(data.key === "oxigen" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { oxigen: data.val() })
        }
        if(data.key === "ecg" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { ecg: data.val() })
        }
        if(data.key === "progress" && data.val()){
          self.medicion = Object.assign({}, self.medicion, { progress: data.val() })
        }
        if(data.key === "report"){
					if(data.val()){
          	self.$store.commit('setReportECGData', data.val())
					}
					else{
						self.medicion = Object.assign({}, self.medicion, { errorReport: true })
						setTimeout(() => {
							self.medicion = Object.assign({}, self.medicion, { errorReport: null })
						}, 10000)
					}
        }
        if(data.key === "loadingReport"){
          self.medicion = Object.assign({}, self.medicion, { loadingReport: data.val() })
        }
      })
    }
  }
}
</script>