<template>
  <div class="text-center">
    <div class="mt-2 d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
        <div>
          <p class="mb-0 ">Agregar Nota</p>
        </div>
        <CButton
          color="primary"
          size="sm"
          class="ml-2 my-1"
          @click="toggleIduamCard(3)"
        >
          {{showMessageBlock ? 'Ocultar':'Mostrar'}} Formulario
        </CButton>
    </div>
    <CRow class="py-4 px-3" :gutters="false" v-show="showMessageBlock">
      <CCol col="12">
        <h6 class="mb-3">Agrega una nota a esta llamada</h6>
      </CCol>
      <CCol col="12">
        <CInput
          placeholder="Título"
          v-model='nota.title'
        />
        <CTextarea
          class="notas-tracking-textarea"
          placeholder="Descripción"
          v-model='nota.description'
        />
        <CAlert v-for="(toast,index) in toasts" :color="toast.color" closeButton :show.sync="currentAlertCounter">
          {{toast.message}}
        </CAlert>
      </CCol>
      <CCol col="12">
        <CButton
          :disabled="savingNote"
          color="success"
          size="sm"
          class="w-100 success d-flex justify-content-around align-items-center py-2"
          style="flex: 1;"
          @click="saveNote()"
        >
          <CSpinner color="white" size="sm" v-if="savingNote"/>
          Guardar Nota
        </CButton>
      </CCol>
      <CCol col="4" xl="4">
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {createNote} from '../../api/notes'

export default {
  name: 'NewNoteForm',
  props: ['userID', 'atencionID', 'trackingID', 'deviceID', 'toggleIduamCard', 'showMessageBlock'],
  data(){
    return {
      expanded: false,
      toasts: [],
      nota: {
        title: '',
        description: '',
        type: 1
      },
      savingNote: false,
      currentAlertCounter: 4,
      saveLSNote: null
    }
  },
  methods: {    
    saveNote(){
      const self = this
      this.currentAlertCounter = 4
      this.toasts = []
      if(!this.nota || (this.nota && (this.nota.title == '' || this.nota.description == '') ) ){
        this.toasts.push({
          title: 'Error',
          message: 'Por favor ingresa título y descripción.',
          color: 'danger'
        })
        return
      }
      this.savingNote = true
      const data = {
        user_id: this.userID,
        device_id: this.deviceID,
        atencion_id: this.atencionID,
        tracking_id: this.trackingID,
        title: this.nota.title,
        description: this.nota.description
      }
      createNote(data)
        .then(function(resp){
          self.savingNote = false
          self.nota = {
            title: '',
            description: '',
            type: 1
          }
          self.toasts.push({
            title: 'Nota guardada',
            message: 'La nota se ha guardado correctamente.',
            color: 'success'
          })

          let notes = JSON.parse(localStorage.getItem('notes'))
          localStorage.setItem('notes', JSON.stringify(notes.filter(obj => obj.atencion_id !== self.atencionID)))
        })
        .catch(function(error){
          self.savingNote = false
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al guardar la información, por favor intenta nuevamente.',
            color: 'danger'
          })
        })
    },
  },
   mounted() {
    //respaldo nota en LS
    this.saveLSNote = setInterval(() => {
      let notes = JSON.parse(localStorage.getItem('notes'))

        let note = {
          user_id: this.userID,
          atencion_id: this.atencionID,
          title: this.nota.title,
          description: this.nota.description
        }

      if ((!notes || !notes.length) && (note.title || note.description)) {
        localStorage.setItem('notes', JSON.stringify([note]))
        notes = [note]
      } 

      const index = notes.findIndex(obj => obj.atencion_id === this.atencionID);
      if (index === -1 && this.nota.title && this.nota.description) {
        notes = [...notes, note]
      } else if (this.nota.title && this.nota.description) {
        notes[index].title = this.nota.title;
        notes[index].description = this.nota.description;
      localStorage.setItem('notes', JSON.stringify(notes))
      }
    }, 60000);

    this.$events.listen('clearIntervalNote', (eventData) => {
      console.log('clear');
        clearInterval(this.saveLSNote)
    })
  }, 
  watch: {
    'nota.title': function (){
      if (this.nota.title && this.nota.description) {
        this.$store.commit("setUnsavedNote", true)
    } else {
      this.$store.commit("setUnsavedNote", false)
    }
    },
    'nota.description': function (){
      if (this.nota.title && this.nota.description) {
        this.$store.commit("setUnsavedNote", true)
    } else {
      this.$store.commit("setUnsavedNote", false)
    }
    }
  }
}
</script>
<style scoped>
  .alert{padding: 5px;font-size: 11px;margin-bottom: 0;}
  .alert >>> button.close{padding: 2px 5px;}
</style>