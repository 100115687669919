import {endpoints} from './endpoints'
import request from '../helpers/request'

export function startAtencion(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.startAtencion,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function endAtencion(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.endAtencion,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function createVideoCallRoom(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createVideoCallRoom,
      method: 'post'
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}
export function initVideoCallWithDevice(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.initVideoCallWithDevice,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getUsersAttentions(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUsersAttentions,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getAttentions(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAttentions,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getAtencionDetail(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getAtencionDetail,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function checkUserInAttention(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.checkUserInAttention,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getLastUserMedicalRecord(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getLastUserMedicalRecord,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}


export function createMedicalRecord(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createMedicalRecord,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function createUserGuardian(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createUserGuardian,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}


export function getClinicalEvents(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getClinicalEvents,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getDiagnosis(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getDiagnosis,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function createUserClinicalEvent(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createUserClinicalEvent,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}