var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-draggable-resizable',{directives:[{name:"show",rawName:"v-show",value:(_vm.showReport),expression:"showReport"}],attrs:{"resizable":false,"x":0,"y":200,"class-name":"reportecg-dragable","z":9999999999,"parent":false}},[(_vm.reportData)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('p',{staticClass:"title-report mb-0 font-weight-bold text-center"},[_vm._v("Reporte Medición")]),_c('div',{staticClass:"my-2"},[_c('p',{staticClass:"my-1 font-weight-bold text-center"},[_vm._v(_vm._s(_vm.reportData.result))]),_c('p',{staticClass:"text-center mb-0"},[_vm._v(_vm._s(_vm.reportData.heart_rate))]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.reportData.atrial_fibrillation))]),_c('p',{staticClass:"mb-0 text-center",staticStyle:{"font-size":"12px"}},[_vm._v("Este reporte es generado por una plataforma de Inteligencia Artificial con los datos obtenidos de la medición del usuario.")]),_c('p',{staticClass:"mb-0 text-center font-weight-bold",staticStyle:{"font-size":"12px"}},[_vm._v("Esta información es referencial.")]),_c('p',{staticClass:"mb-0 text-center font-weight-bold",staticStyle:{"font-size":"12px"}},[_vm._v("Iduam no puede detectar signos de infarto.")]),_c('div',{staticClass:"mt-2",staticStyle:{"max-height":"400px","overflow-y":"scroll"}},[_c('p',{staticClass:"mb-0 text-center font-weight-bold",staticStyle:{"font-size":"12px"}},[_vm._v("Electrocardiograma completo")]),_vm._l((_vm.ecgData),function(ecgChunk,index){return _c('CChartLine',{key:index,staticStyle:{"height":"200px"},attrs:{"datasets":[
								{
									data: ecgChunk,
									borderColor: '#0064ed',
									pointRadius: 0,
									fill: false,
									label: 'mV'
								}
							],"options":_vm.chartOptions}})})],2),_c('CButton',{staticClass:"m-1 w-100",attrs:{"color":"primary","size":"sm"},on:{"click":function($event){return _vm.closeReporte()}}},[_vm._v(" Cerrar Reporte ")])],1)])]):_vm._e(),_c('p',{staticClass:"mb-0 text-center",staticStyle:{"font-size":"10px"}},[_vm._v("Puedes mover este cuadro donde quieras y seguir trabajando en la plataforma")])])
}
var staticRenderFns = []

export { render, staticRenderFns }