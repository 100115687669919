<template>
  <vue-draggable-resizable :resizable="false" :x="0" :y="200" class-name="videocalls-dragable" :z="9999999999" style="position:absolute; z-index: 123; background:#FFF; top:-100px;">
    <div class="videocall-pip-container">
      <div v-if="remoteCalledStream" class="videocall-pip-video-container d-flex justify-content-center align-items-center">
        <video ref="remoteCalledStream"
          autoplay
          :srcObject.prop="remoteCalledStream.stream"
          class="videocall-pip-called-video"></video>
        <div class="user-name-video" >Usuario Principal: <b>{{remoteCalledStream.userName}}</b></div>
      </div>
      <div v-else class="videocall-pip-video-container d-flex flex-column justify-content-center align-items-center p-4 text-center">
        <CSpinner
          style="width:2rem;height:2rem;"
          color="#3c4b64"
          grow
        />
        Esperando que el usuario principal se una a la sala
      </div>
      <VideoCallCarousel :remotes="remoteStreams" :localStream="localStream"/>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <CButton
        color="primary"
        class="bottom-btns-videocall-room first-border-radius d-flex justify-content-center align-items-center py-2 mx-0"
        variant="outline"
        style="font-size: 12px;"
        @click="goFullScreenCallback()"
      >
        <font-awesome-icon class="mr-2" icon="desktop" style="font-size:16px;"/> Vista Completa
      </CButton>
      <CButton
        color="primary"
        class="bottom-btns-videocall-room last-border-radius d-flex flex-grow-1 justify-content-center align-items-center py-2 mx-0"
        variant="outline"
        style="font-size: 12px;"
        @click="stopVideoCall()">
        <font-awesome-icon class="mr-2" icon="check" style="font-size:16px;"/>
        Terminar Monitoreo
      </CButton>
    </div>
  </vue-draggable-resizable>
</template>

<script>
  import VideoCallCarousel from './VideoCallCarousel'
  import VueDraggableResizable from 'vue-draggable-resizable'
  import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

  export default {
    name: 'PipView',
    components: {VueDraggableResizable, VideoCallCarousel},
    props: {
      remoteCalledStream: {
        required: true
      },
      remoteStreams: {
        required: true
      },
      localStream: {
        required: true
      },
      endVideoCallCallback: {
        required: true
      },
      goFullScreenCallback: {
        required: true
      }
    },
    data(){
      return {
      }
    },
    watch: {
      remoteCalledStream(data){
      console.log("remoteCalledStream: ", this.remoteCalledStream)
      }
    },
    mounted(){
      console.log("local: ", this.localStream)
      console.log("remoteCalledStream: ", this.remoteCalledStream)
    },
    methods: {
      stopVideoCall(){
        this.endVideoCallCallback()
      },
    }
  }
</script>
