<template>
	<div class="videocalllist-container" :class="expanded ? 'expanded':null">
		<div class="remotes-container" v-show="expanded">
			<div v-for="(remote, index) in remotes" class="videocall-self-container">
				<video autoplay :srcObject.prop="remote.stream" class="video-elements w-100"></video>
				<div class="user-name-video" >{{remote.userName}}</div>
		    <CButton class="button-list" color="dark" size="sm">
					<font-awesome-icon icon="volume-mute"/>
		    </CButton>
			</div>
		</div>
		<div class="d-flex align-items-center justify-content-between">
	    <CButton @click="expanded = !expanded" color="light" size="sm">
				<font-awesome-icon :icon="expanded ? 'chevron-up':'chevron-down'"/>
	    </CButton>
	    <small>Otros usuarios en sala: {{remotes.length}}</small>
		<div style="display:flex; padding: 8px;">
	    <CButton color="dark" size="sm">
				<font-awesome-icon icon="volume-mute"/>
	    </CButton>
		<CButton :color="mute ? 'dark':'red'" size="sm" style="margin-left:12px;" @click="toggleMute()">
			<font-awesome-icon icon="microphone-slash" style=" cursor:pointer;"/>
	    </CButton>
		<CButton color="dark" size="sm" style="margin-left:12px;" @click="toggleModalAudio()">
			<font-awesome-icon icon="cog" style=" cursor:pointer;"/>
	    </CButton>
		</div>
	  </div>
	</div>
</template>

<script>
import kurentoUtils from 'kurento-utils'

	export default {
	  name: 'VideoCallList',
	  props: ['remotes', 'localStream'],
	  data(){
	    return {
	    	expanded: false,
			mute: false
	    }
	  },
	  mounted(){
	  	console.log(this.remotes)
	  },
	  methods: {
		toggleModalAudio() {
			this.$store.commit('setModalAudioSettings', !this.$store.state.modalAudioSettings)
    	},
		toggleMute() {
			this.mute = !this.mute
      		kurentoUtils.WebRtcPeer.WebRtcPeerSendonly = this.mute;
    	},
		toggleMute() {
			const stream = this.localStream;
			this.mute = !this.mute
			stream.getAudioTracks()[0].enabled = this.mute
    	}
	  }
	}
</script>