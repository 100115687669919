<template>
	<div class="videocall-grid-container w-100 h-100">
		<VideoCallList :localStream="localStream" :remotes="remoteStreams" :usersInRoom="usersInRoomState" />
		<div v-if="localStream" class="videocall-self-container-local">
			<video autoplay :srcObject.prop="localStream" muted class="video-element-local"></video>
			<div class="user-name-video" >Tú</div>
		</div>
		<div v-if="remoteCalledStream" class="videocall-self-container full-container h-100">
			<video ref="remoteCalledStream"
				id="remote"
				autoplay
				:srcObject.prop="remoteCalledStream.stream"
				class="video-elements w-100 mh-100 h-100"></video>
			<CalledUserInfo v-if="userInfo" :userData="userInfo" />
		</div>
		<div v-else class="full-container h-100 d-flex justify-content-center align-items-center flex-column">
			<div class="d-flex justify-content-center align-items-center flex-column" v-if="!principalWasInRoom">
		    <CSpinner
		      style="width:4rem;height:4rem;"
		      color="#3c4b64"
		      grow
		    />
				Esperando que el usuario principal se una a la videollamada
			</div>
			<div class="text-center" v-else>
        <font-awesome-icon class="mb-4" icon="video-slash" style="font-size:50px;"/>
				<p class="m-0"><b>El usuario principal se ha desconectado</b></p>
				<p class="mb-2">Puedes intentar reconectar o llamarlo para establecer una conexión más estable</p>
 				<CRow>
   				<CCol col="6" class="text-center d-flex">
			      <CButton
			        color="primary"
			        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2"
			        variant="outline"
			        style="flex: 1;font-size: 12px;"
			        @click="reconnectVideoCall(true)"
			      >
			        Intentar conectar
			      </CButton>
			    </CCol>
   				<CCol col="6" class="text-center d-flex">
			      <CButton
			        color="primary"
			        class="bottom-btns-videocall-room d-flex justify-content-center align-items-center py-2"
			        variant="outline"
			        style="flex: 1;font-size: 12px;"
			        @click="reconnectVideoCall(true)"
			      >
			        Llamar al usuario
			      </CButton>
			    </CCol>
			  </CRow>
			</div>
		</div>
	</div>
</template>

<script>
	import VideoCallList from './VideoCallList'
	import CalledUserInfo from './CalledUserInfo'
  	import {initVideoCallWithDevice} from '../../api/atenciones'

	export default {
	  name: 'VideoCallGrid',
	  components: {VideoCallList, CalledUserInfo},
	  props: {
	  	localStream: {
      	required: true
	  	},
	  	remoteCalledStream: {
      	required: true
	  	},
	  	remoteStreams: {
	  		type: Array,
      	required: true
	  	},
	  	usersInRoom: {
	  		type: Object,
	  		required: true
	  	},
	  	socketRoom: {
	  		type: String,
	  		required: true
	  	}
	  },
		  data(){
			return {
				usersInRoomState: {},
				principalWasInRoom: false,
				userInfo: null,
			}
		  },
		  methods: {
			  async reconnectVideoCall(silent){
				  this.principalWasInRoom = false
				  const videoCallData = this.$store.state.videoCallData
			await initVideoCallWithDevice({
			  to_users: [ videoCallData.calledUserID ],
			  device_id: videoCallData.deviceID,
			  videocall_id: videoCallData.videocallID,
			  socket_room: videoCallData.socketRoom,
			  socket_url: videoCallData.socketUrl,
			  silent
			})
			},
	  		switchAudioOut() {
				const remoteVideo = document.getElementById('remote')
				remoteVideo.setSinkId(this.$store.state.audioOutputDevice)
			},
		  },

	  watch: {
	  	usersInRoom(data){
	  		this.usersInRoomState = data
	  		if(this.remoteCalledStream){
	  			this.userInfo = {...this.usersInRoomState[this.remoteCalledStream.socketID]}
	  		}
	  	},
	  	remoteCalledStream(data){
	  		if(data){
	  			this.principalWasInRoom = true
	  			this.userInfo = {...this.usersInRoomState[this.remoteCalledStream.socketID]}
	  		}
	  	},
		  '$store.state.audioOutputDevice': function() {
			this.switchAudioOut()
  		},
	  },
	  mounted(){
	  	this.usersInRoomState = this.usersInRoom
  		if(this.remoteCalledStream){
  			this.principalWasInRoom = true
  			this.userInfo = {...this.usersInRoomState[this.remoteCalledStream.socketID]}

  		}
	  },
	}
</script>

<style lang="scss">
/* .modal-audio {
	background-color: #fff;
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(50%, 50%);
	padding: 20px;
	width: 500px;
	border-radius: 15px;
	border: 1px solid black;
} */
</style>