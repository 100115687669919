<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="(toast, index) in toasts">
        <CToast
          :key="'toast' + index"
          :show="true"
          :header="toast.title"
        >
          {{toast.message}}
        </CToast>
      </template>
    </CToaster>
    <CModal
      title="Botón SOS"
      color="primary"
      class="modal-alerta"
      :show.sync="showModal"
    >
      <template #header>
        <p class="mb-0 text-center w-100">Búsqueda de Usuarios</p>
        <CButton
          class="mx-2 p-0"
          @click="closeModal()"
        >
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow>
        <CCol col="12" xl="12">
          <CRow>
            <CCol col="4" xl="4">
              <div class="d-flex w-100 flex-column h-100">
                <div class="d-flex justify-content-center align-items-center text-center mb-3 py-2 bg-primary text-white rounded-top">
                  <p class="mb-0">Búsqueda de usuario <small>(Haz click en un usuario para visualizar su información)</small></p>
                </div>
                <div class="d-flex w-100">
                  <CInput
                    class="mx-2 mt-2 mb-0 w-100"
                    placeholder="Ingresa tu búsqueda"
                    v-model='searchString'
                    @input="getData"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <v-select
                    class="mx-2 mt-2 mb-0"
                    style="min-width: 150px"
                    :clearable="false"
                    :searchable="false"
                    :options="['Todos', 'Inactivos', 'Activos']"
                    v-model="userstate"
                    @input="getData"></v-select>
                </div>
		            <div
		              class="mx-2 mt-2 mb-0">
                	<CListGroup>
								    <CListGroupItem v-for="(user, index) in userList" href="#" @click="selectUser(user)">
								      {{ user.name }} <small>({{user.email}})</small>
								    </CListGroupItem>
	                </CListGroup>
		            </div>
              </div>
            </CCol>
            <CCol col="8" xl="8">
              <CRow  v-if="!selectedUser">
                <CCol col="12" xl="12">
                  <div style="text-align: center;min-height: 50vh;flex: 1;align-items: center;justify-content: center;display: flex;flex-direction: column;">
                    Haz click en un usuario del listado para visualizar su información
                  </div>
                </CCol>
              </CRow>
              <CRow  v-else>
                <CCol col="12" xl="12">
                  <div class="border-primary px-0 rounded">
                    <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
                      <p class="mb-0 ">Información del usuario</p>
                    </div>
                    <CRow class="p-3">
                      <CCol class="d-flex" col="12" xl="12">
                        <div class="mr-4">
                          <img
                            v-if="selectedUser.info.avatar"
                            :src="selectedUser.info.avatar"
                            class="c-mb-2 avatar-alerta-user"
                          />
                          <div class="icon-alerta-user" v-else><font-awesome-icon icon="user"/></div>
                        </div>
                        <div>
                          <p class="mb-0 text-primary font-weight-bold">{{selectedUser.name}}</p>
                          <p class="mb-0 text-primary d-flex align-items-center">
                            <CImg
                              class="mr-2"
                              height="12"
                              :src="require('../../assets/imgicons/ICONOS SOSRecurso 22@2x.png')"
                            />
                            {{selectedUser.email}}
                          </p>
                          <p class="mb-0 text-primary d-flex align-items-center">
                            <CImg
                              class="mr-2"
                              height="12"
                              :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"
                            />{{selectedUser.info.telefono}}
                              <CButton
                                :disabled="$store.state.toCallData ? true:false"
                                color="primary"
                                class="ml-2 my-1"
                                @click="call(selectedUser.info.telefono, selectedUser.name)"
                                size="sm"
                              >
                                <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                Llamar
                              </CButton>
                          </p>
                          <p class="mb-0 text-primary d-flex align-items-center">
                            <CImg
                              class="mr-2"
                              height="12"
                              :src="require('../../assets/imgicons/ICONOS SOSRecurso 21@2x.png')"
                            />{{selectedUser.info.direccion}}
                          </p>
                        </div>
                   		</CCol>
                    </CRow>
            				<CRow class="px-3 monitores-container-in-modal search-user-modal">
                    	<CCol col="12" xl="12">
			                  <div
			                    v-for="monitor in selectedUser.dispositivos"
			                    class="col-sm-12 border-bottom d-flex justify-content-between align-items-center py-3"
			                    style="color: #0064ed">
                          <div class="w-100">
  			                    <div class="d-flex justify-content-start align-items-center">
  			                      <div style="position: relative;width: 40px;text-align: center">
  			                        <img style="margin-right: 20px;" class="primary-color-image-overlay" height="20"
  			                          :src="monitor.is_monitor ? require('../../assets/imgicons/ICONOS SOSRecurso 14@2x.png') : require('../../assets/imgicons/ICONOS SOSRecurso 13@2x.png')"/>
  			                        <div class="active-badge-indicator-monitor"></div>
  			                      </div>
  			                      <div>
  			                        <p class="mb-0 text-primary">Nombre del Dispositivo</p>
                                <p class="mb-0 text-primary">{{monitor.device_name}}</p>
                                <!-- <p class="mb-0"><small>ID: {{monitor.device_id}}</small></p> -->
  			                      </div>
                            </div>
                            <div class="d-flex justify-content-start align-items-center mt-3" v-if="!awakingDevice && errorMonitor && errorMonitor.device_id == monitor.device_id">
                              <div>
                                <p class="mb-0 text-primary d-flex align-items-center"><small>Lo sentimos no pudimos levantar el dispositivo seleccionado</small></p>
                                <p class="mb-0 text-primary d-flex align-items-center"><small>Si lo deseas puedes realizar alguna de estas funciones:</small></p>
                                <div class="d-flex w-100 h-100">
                                  <CButton
                                    :disabled="$store.state.toCallData ? true:false"
                                    color="primary"
                                    class="ml-2 my-1"
                                    @click="call(selectedUser.info.telefono, selectedUser.name)"
                                    size="sm"
                                  >
                                    <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                    Llamar al usuario
                                  </CButton>
                                  <CButton
                                    :disabled="$store.state.toCallData ? true:false"
                                    color="primary"
                                    class="ml-2 my-1"
                                    @click="sendUserSMS(selectedUser.info.telefono, selectedUser.name)"
                                    size="sm"
                                  >
                                    <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                    Enviar SMS
                                  </CButton>
                                  <CButton
                                    :disabled="$store.state.toCallData ? true:false"
                                    color="primary"
                                    class="ml-2 my-1"
                                    @click="notificar(monitor.fbToken)"
                                    size="sm"
                                  >
                                    <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                    Enviar Notificación
                                  </CButton>
                                </div>
                                <div class="d-flex w-100 h-100 mt-2 border-top pt-2">
                                  <CButton
                                    :disabled="$store.state.toCallData ? true:false"
                                    color="primary"
                                    class="ml-2 my-1"
                                    @click="deleteDevice(monitor.id)"
                                    size="sm"
                                  >
                                    <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                    Eliminar completamente
                                  </CButton>
                                  <p class="mb-0 text-primary d-flex align-items-center ml-1" style="max-width: 280px;line-height: 12px;"><small>Hazlo sólo si estás seguro de que el dispositivo ya no está siendo usado por un usuario</small></p>
                                </div>
                              </div>
  			                    </div>
                          </div>
			                    <div class="d-flex justify-content-end" style="min-width: 350px">
                            <div class="d-flex justify-content-center align-items-center text-center" v-if="awakingDevice && awakingDevice.device_id == monitor.device_id">
                              <CSpinner
                                color="primary"
                                size="sm"
                              />
                              <div>
                                <p class="mb-0 ml-2 text-primary d-flex align-items-center text-left">
                                  <small>Intentando reactivar dispositivo</small>
                                </p>
                                <p class="mb-0 ml-2 text-primary d-flex align-items-center text-left">
                                  <small>Tiempo máximo de espera: {{timer}} segundos</small>
                                </p>
<!--                                 <CButton
                                  color="primary"
                                  size="sm"
                                  class="ml-2 my-1 d-flex align-items-center"
                                  @click="cancelAwake()"
                                >
                                  Cancelar
                                </CButton> -->
                              </div>
                            </div>
			                      <CButton
			                        color="primary"
			                        size="sm"
			                        class="ml-2 my-1 d-flex align-items-center"
                      				:disabled="loadingAwake"
			                        @click="setMonitorear(monitor)"
			                      >
			                        <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 10@2x.png')"/> Despertar
			                      </CButton>
<!--                             <CButton
                              :disabled="$store.state.toCallData ? true:false"
                              color="primary"
                              class="ml-2 my-1"
                              @click="sendDailyCheck(monitor.fbToken)"
                              size="sm"
                            >
                              <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                              Enviar Notificación
                            </CButton> -->
                            <CButton
                              :disabled="$store.state.toCallData ? true:false"
                              color="primary"
                              class="ml-2 my-1"
                              @click="checkUserTracking(monitor)"
                              size="sm"
                            >
                              <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 6@2x.png')"/>
                              Consultar Geolocalización
                            </CButton>
			                    </div>
			                  </div>
                   		</CCol>
                    </CRow>
                    <CRow>
                      <CCol col="12" xl="12">
                        <div style="border-top-color: #0064ed!important;" class="w-100 px-3 py-2 border-top d-flex justify-content-end">
                        <CButton @click="closeModal()" color="success">
                          <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 19@2x.png')"/>
                          Cerrar
                        </CButton>
                      </div>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="closeModal" color="primary" size="lg">Salir</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import {searchUsers, sendNotification, deleteDevice, sendDailyCheckNotification} from '../../api/users'
import {sendNotificationMedicionIduam} from '../../api/mediciones'
import {startMonitoreo} from '../../api/user'
import {sendSMS} from '../../api/calls'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'ModalSearchUsers',
  props: ['showModal', 'closeModal'],
  components: {'v-select': vSelect},
  data () {
    return {
    	socket: null,
    	callNumber: '',
      loadingInfo: true,
      loadingAwake: false,
      modalInfo: null,
      selectedUser: null,
      searchString: '',
      userList: [],
      toasts: [],
      sendToMonitoreo: null,
      cuadrante: this.$store.state.userData.info.cuadrante_id,
      userstate: 'Inactivos',
      errorMonitor: null,
      awakingDevice: null,
      timer: 60,
      awakeTimer: null
    }
  },
  mounted(){
  	this.getData()
  },
  methods: {
    sendDailyCheck(deviceFBToken){
      const self = this
      sendDailyCheckNotification({deviceFBToken})
        .then(function(resp){
          self.toasts.push({
            title: 'Notificación enviada',
            message: 'Se ha enviado la notificación al dispositivo, si el usuario la abre se activará la aplicación normalmente.'
          })
        })
        .catch(function(error){
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al enviar la notificación de inactividad, por favor inténtalo nuevamente.'
          })
        })
    },
    checkUserTracking(monitor){
      console.log("checkUserTracking: ", monitor)
      const self = this
      const path = 'Iduam/tracking/' + this.cuadrante + '/' + monitor.user_id + '/' + monitor.device_id
      firebase.database(this.$store.state.trackingDB).ref(path).on("value", function(data){
        if( !data.val() ){
          self.toasts.push({
            title: 'No se puede seguir ubicación',
            message: 'El usuario no tiene ningún trackeo disponible con este dispositivo.'
          })
        }
        self.$store.commit("setSoloTrackingUser", {
          nombre: self.selectedUser.name,
          id_usuario: monitor.user_id,
          deviceID: monitor.device_id,
          deviceData: monitor
        })
        self.$store.commit("setContentInfoUser", self.selectedUser)
      })
    },
    sendNotificationMedicion(fbToken){
      const self = this
      console.log(fbToken)
      sendNotificationMedicionIduam({fbToken})
        .then(function(resp){
          self.toasts.push({
            title: 'Notificación enviada',
            message: 'Se ha enviado la notificación al dispositivo, si el usuario la abre se activará la aplicación normalmente.'
          })
        })
        .catch(function(error){
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al enviar la notificación de inactividad, por favor inténtalo nuevamente.'
          })
        })
    },
    call(phoneToCall, name){
      const self = this
      const callData = {
        phoneToCall,
        name
      }
      this.$store.commit("setCallData", callData)
    },
    getActivos(){
      const self = this
      return new Promise(function(resolve){
        firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.cuadrante).orderByKey().once('value',function(data){
          resolve( data.val() ? Object.keys(data.val()) : [] )
          return
        })
        resolve([])
      })
    },
    async getData(){
      const self = this
      const activos = await this.getActivos()
      searchUsers({string: this.searchString, state: this.userstate, activos})
        .then(function(resp){
        	self.userList = resp.users
        })
        .catch(function(error){
          console.log(error)
        })
    },
    notificar(deviceFBToken){
      const self = this
      sendNotification({deviceFBToken})
        .then(function(resp){
          self.toasts.push({
            title: 'Notificación enviada',
            message: 'Se ha enviado la notificación al dispositivo, si el usuario la abre se activará la aplicación normalmente.'
          })
        })
        .catch(function(error){
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al enviar la notificación de inactividad, por favor inténtalo nuevamente.'
          })
        })
    },
    sendUserSMS(toPhone, user){
      const self = this
      toPhone = '+' + toPhone
      sendSMS({toPhone, user})
        .then(function(resp){
          self.toasts.push({
            title: 'SMS enviado',
            message: 'Se ha enviado el sms al teléfono del usuario.'
          })
        })
        .catch(function(error){
          console.log(error)
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al enviar el SMS, por favor inténtalo nuevamente.'
          })
        })
    },
    deleteDevice(deviceID){
      const self = this
      deleteDevice({id: deviceID})
        .then(function(resp){
          self.toasts.push({
            title: 'Dispositivo desactivado',
            message: 'Se ha desactivado este dispositivo. Ya no te aparecerá en este listado. Si el usuario ingresa desde este dispositivo se activará automáticamente.'
          })
        })
        .catch(function(error){
          self.toasts.push({
            title: 'Error',
            message: 'Hubo un error al desactivar el dispositivo, por favor inténtalo nuevamente.'
          })
        })
    },
    formatPosition(position){
      const geo = {
        lat: position.latitude,
        lng: position.longitude
      }
      return geo
    },
    selectUser(user){
    	console.log(user)
    	this.selectedUser = user
    },
    cancelAwake(){
      this.loadingAwake = false
      clearTimeout(self.awakeTimer)
      this.awakeTimer = null
      this.timer = 60
      this.awakingDevice = null
    },
    awakePhone(monitorID){
    	const self = this
    	this.loadingAwake = true
      this.timer = 60
      this.awakeTimer = setInterval(function () {
        if(self.timer > 0){
          self.timer--
        }
      }, 1000);
    	return new Promise(async function(resolve,reject){
    		await firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).remove()
      	await startMonitoreo(monitorID)
        let auxSetTimeout = setTimeout(() => {
          firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).off('child_added');
          clearTimeout(self.awakeTimer)
          resolve(false);
        }, 60000);

        firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).on('child_added',async function(data){
          if(data.key == 'userActivo' && data.val() === true){
          	firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).once('value',function(data){
          		self.sendToMonitoreo = data.val()
	          });
            clearTimeout(auxSetTimeout);
            clearTimeout(self.awakeTimer)
            firebase.database(self.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + self.$store.state.userData.info.cuadrante_id + '/' + monitorID).off('child_added');
            resolve(true);
          }
        });
      });
    },
    async setMonitorear(monitor){
      this.errorMonitor = null
      this.awakingDevice = monitor
    	const awake = await this.awakePhone(monitor.device_id)
      this.awakingDevice = null
      if(this.sendToMonitoreo && awake){
      	this.$events.fire('startMonitoreoFromUserList', this.sendToMonitoreo)
      	this.closeModal()
      }
      else{
	      this.toasts.push({
	        title: 'Error',
	        message: 'No pudimos levantar el monitoreo en el dispositivo seleccionado en el tiempo establecido.'
	      })
        this.errorMonitor = monitor
      }
    	this.loadingAwake = false
    }
  }
}
</script>

<style type="text/css" media="screen">
  .select-cierre-tipo .vs__search::placeholder,
  .select-cierre-tipo .vs__dropdown-toggle,
  .select-cierre-tipo .vs__dropdown-menu,
  .select-cierre-tipo .vs__open-indicator{
    color: #d0282e;
    border-color: #d0282e;
  }
</style>