<template>
	<div class="sosclick-app-container m-0 p-0" v-if="ready || inLogin">
    <ReportECG v-if="!inLogin" />
    <Calls v-if="!inLogin" />

  	<router-view></router-view>
		
  	<DatabaseListeners v-if="!inLogin" :showAlert="showAlert" :showCloseAlert="(alert) => prepareShowEndAlert(alert)" />
  	<ModalEndAlert v-if="!inLogin" :showModal="showEndAlert" :alertData="alertData" :closeModal="(show) => closeEndAlert(show)" />
    <!-- <Monitoreo v-if="showMonitoreo" :showCloseAlert="(alert) => prepareShowEndAlert(alert)"/> -->
    <LateralBarUsers v-if="!inLogin && showMonitoreo" />
    <VideoCallRoom  v-if="!inLogin && $store.state.videoCallData.status" />
<!--     <VideoCallRoom   /> -->
    <ModalSearchUsers v-if="$store.state.showUserModalSearch" :showModal="$store.state.showUserModalSearch" :closeModal="() => closeSearchUserModal()"/>
    <SoloTracking v-if="!inLogin" />
    <UploadFile v-if="!inLogin" />
    <MonitoreoGrid v-if="!inLogin" />

    <CModal
      title="Notas Incompletas"
      color="primary"
      :show.sync="$store.state.openModalUnsavedNotes"
      style="z-index: 9999999999999 !important; position: absolute;"
      :closeOnBackdrop="false"
    >
    <div v-if="$store.state.openModalUnsavedNotes">
      <RestoreUnsavedNote />
    </div>
    <div slot="footer">

    </div>
    </CModal>
  </div>
  <div class="sosclick-app-container m-0 p-0 d-flex flex-column align-items-center justify-content-center" style="height: 100vh" v-else>
    <img src="./assets/imgs/logo-iduam.png" width="200">
    <div class="d-flex align-items-center justify-content-center">
      <CSpinner
        color="primary"
        style="width:20px;height:20px;"
      />
      <h5 class="m-0 ml-2">Cargando datos de cuadrante</h5>
    </div>
  </div>
</template>

<script>
import DatabaseListeners from './views/notifications/DatabaseListeners'
import ModalEndAlert from './views/notifications/ModalEndAlert'
import Monitoreo from './views/widgets/Monitoreo'
import MonitoreoGrid from './views/widgets/MonitoreoGrid'
import Calls from './views/widgets/Calls'
import SoloTracking from './views/widgets/Tracking/SoloTracking'
import ModalSearchUsers from './views/widgets/modalSearchUsers'
import ReportECG from './views/widgets/ReportECG'
import UploadFile from './views/widgets/Components/UploadFile'
import LateralBarUsers from './views/widgets/LateralBarUsers'
import VideoCallRoom from './views/videocall/VideoCallRoom'
import RestoreUnsavedNote from './views/widgets/Components/RestoreUnsavedNote.vue'

import {getUser, getFBAuthToken} from './api/user.js'
	import { endAtencion } from './api/atenciones'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

export default {
  name: 'App',
  components: {VueDraggableResizable,DatabaseListeners, ModalEndAlert, Monitoreo, MonitoreoGrid, Calls, ModalSearchUsers, ReportECG, SoloTracking, UploadFile, LateralBarUsers, VideoCallRoom, RestoreUnsavedNote},
  data () {
    return {
      showAlert: null,
      showEndAlert: false,
      alertData: null,
      showMonitoreo: false,
      showModalSearchUsers: false,
      ready: false,
      inLogin: true,
    }
  },
  mounted(){
    this.checkMonitoreoHeight()

    //finaliza videollamada si no se cerró bien
    const videocallInProgress = localStorage.getItem('videocallInProgress')
    if (videocallInProgress) {
      endAtencion({atencion_id: videocallInProgress})
      localStorage.removeItem('videocallInProgress')
    }
  },
  watch:{
    $route (to, from){
      if(to.name !== "Login"){
        this.inLogin = false
        if(!this.$store.state.userData || from.name == "Login"){ this.commitUser() }
      }
      else{
        this.inLogin = true
      }
    }
  },
  methods: {
    async commitUser(){
      const self = this
      await getUser()
      this.ready = await this.initializeFirebaseDBS()
    },
    async loginInFB(){
      return new Promise(async (resolve, reject) => {
        const fbToken = await getFBAuthToken()
        const token = fbToken.customToken
        if (token) {
          Promise.all([this.checkLoginPrimaryDB(token), this.checkLoginMonitoreoGrid(token), this.checkLoginTrackingBD(token)])
            .then(values => {
              console.log("USER: ", values)
              resolve(true)
            })
        }
      })
    },
    checkLoginPrimaryDB(token){
      return new Promise(async (resolve, reject) => {
        let loggedPrimaryDB = this.$store.state.primaryDB.auth()
          .onAuthStateChanged(async (user) => {
            if(!user){
              await this.$store.state.primaryDB.auth().signInWithCustomToken(token)
            }
            loggedPrimaryDB()
            resolve(user)
          })
      })
    },
    checkLoginMonitoreoGrid(token){
      return new Promise(async (resolve, reject) => {
        let loggedMonitoreoGrid = this.$store.state.monitoreoDB.auth()
          .onAuthStateChanged(async (user) => {
            if(!user){
              await this.$store.state.monitoreoDB.auth().signInWithCustomToken(token)
            }
            loggedMonitoreoGrid()
            resolve(user)
          })
      })
    },
    checkLoginTrackingBD(token){
      return new Promise(async (resolve, reject) => {
        let loggedTrackingBD = this.$store.state.trackingDB.auth()
          .onAuthStateChanged(async (user) => {
            if(!user){
              await this.$store.state.trackingDB.auth().signInWithCustomToken(token)
            }
            loggedTrackingBD()
            resolve(user)
          })
      })
    },
    initializeFirebaseDBS(){
      return new Promise(async (resolve, reject) => {
        const firebaseConfigPrimaryDB = {
          apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
          authDomain: "iduam-eebce.firebaseapp.com",
          databaseURL: this.$store.state.userData.info.cuadrante.primary_fdb_url,
          projectId: "iduam-eebce",
          storageBucket: "iduam-eebce.appspot.com",
          messagingSenderId: "574012982731",
          appId: "1:574012982731:web:230b225497bd9b2e8c624e",
          measurementId: "G-JT1JLJW9SP"
        };
        const firebaseConfigMonitoreo = {
          apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
          authDomain: "iduam-eebce.firebaseapp.com",
          databaseURL: this.$store.state.userData.info.cuadrante.monitoreo_fdb_url,
          projectId: "iduam-eebce",
          storageBucket: "iduam-eebce.appspot.com",
          messagingSenderId: "574012982731",
          appId: "1:574012982731:web:230b225497bd9b2e8c624e",
          measurementId: "G-JT1JLJW9SP"
        };
        const firebaseConfigTracking = {
          apiKey: "AIzaSyBSglZK8-pgReHnPzQfTcbdz7CyRQCly44",
          authDomain: "iduam-eebce.firebaseapp.com",
          databaseURL: this.$store.state.userData.info.cuadrante.tracking_fdb_url,
          projectId: "iduam-eebce",
          storageBucket: "iduam-eebce.appspot.com",
          messagingSenderId: "574012982731",
          appId: "1:574012982731:web:230b225497bd9b2e8c624e",
          measurementId: "G-JT1JLJW9SP"
        };
        const primaryDB = await firebase.initializeApp(firebaseConfigPrimaryDB, 'primaryDB')
        const monitoreoDB = await firebase.initializeApp(firebaseConfigMonitoreo, 'monitoreoGrid')
        const trackingDB = await firebase.initializeApp(firebaseConfigTracking, 'trackingDB')

        this.$store.commit('setPrimaryDB', primaryDB)
        this.$store.commit('setMonitoreoDB', monitoreoDB)
        this.$store.commit('setTrackingDB', trackingDB)

        await this.loginInFB()
        resolve(true)
      })
    },
    checkMonitoreoHeight(){
      const self = this
      const height = document.getElementsByTagName('main')[0]
      if(height){
        this.showMonitoreo = true
      }
      else{
        setTimeout(function() {
          self.checkMonitoreoHeight()
        }, 500);
      }
    },
    prepareShowEndAlert(alert){
      this.alertData = alert
      this.showAlert = false
      this.showEndAlert = true
    },
    closeEndAlert(show = true){
      this.showEndAlert = false
      this.showAlert = show ? this.alertData : null
      this.alertData = null
    },
    closeSearchUserModal(){
      this.$store.commit('setShowUserModalSearch', false)
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
