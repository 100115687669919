import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getFarmacosByQuery(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getFarmacosByQuery,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function saveReceta(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.saveReceta,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getRecetasList(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getRecetasList,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function sendRecetaByMail(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.sendRecetaByMail,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}